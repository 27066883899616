import { useNode } from '@craftjs/core';
import { ContentState, EditorState } from 'draft-js';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  TemplateContent,
  TemplateType,
  getNextTemplate,
  getTextTemplateById
} from '../../../../AdBuilder/data';
import SaveWarningModal from '../../../../AdBuilder/components/elements/common/SaveWarningModal';
import SaveTemplateNameModal from './SaveTemplateNameModal';
import { getUniqueId } from '../../../../helper/craftJs';
import { createTextTemplateThunk } from '../../../../AdJobBuilder/redux/thunk';
import { useFunnel } from '../../../../redux/builderSlice';
import { replaceJobPlaceHoldersWithValues } from '../../../../AdJobBuilder/helper/AdJobHelper';

const SwitchTemplate: React.FC<{
  direction: 'NEXT' | 'PREV';
  makeModalOpen?: boolean;
  onChange?: Function;
  template?: { text: string; id: number };
  userTemplate: TemplateContent[];
  content: TemplateType;
}> = ({
  children,
  direction,
  makeModalOpen,
  template,
  content,
  userTemplate,
  onChange = () => {}
}) => {
  const [saveWarningModalVisible, setSaveWarningModalVisible] = useState(false);
  const [nameModalVisible, setNameModalVisible] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setSaveWarningModalVisible(!!makeModalOpen);
  }, [makeModalOpen]);

  const {
    props,
    actions: { setProp }
  } = useNode(node => ({
    props: node.data.props
  }));

  const toggleSaveWarningModal = () => {
    setSaveWarningModalVisible(true);
    onChange();
  };

  const setNewEditorState = (templateData: TemplateContent) => {
    setProp((props: any) => {
      const newState = EditorState.createWithContent(
        ContentState.createFromText(templateData?.text)
      );
      props.editorState = newState;
      props.isEdited = false;
      props.templateId = templateData?.id;
    });
  };
  const funnel = useFunnel();
  const handleMovetoNext = () => {
    let templateData: {
      text: string;
      id: number;
    };
    if (!template) {
      const temp = {
        ...content,
        data: [...content.data, { content: userTemplate, category: 'Customer' }]
      };

      templateData = getNextTemplate({
        currentId: props.templateId,
        direction,
        // @ts-ignore
        templateData: temp
      });
    } else {
      templateData = template;
    }

    if (templateData) {
      const templateParsedData = replaceJobPlaceHoldersWithValues(templateData, funnel);
      setNewEditorState({ ...templateData, text: templateParsedData.text });
    }
  };

  return (
    <div>
      <div
        onClick={() => {
          const temp = {
            ...content,
            data: [...content.data, { content: userTemplate, category: 'Customer' }]
          };

          const template = getTextTemplateById(props.templateId, temp);
          const templateParsedData = replaceJobPlaceHoldersWithValues(template, funnel);

          if (props.isEdited) {
            toggleSaveWarningModal();
          } else {
            handleMovetoNext();
          }
        }}
      >
        {children}
      </div>
      <SaveWarningModal
        visible={saveWarningModalVisible}
        setVisible={setSaveWarningModalVisible}
        onCancel={toggleSaveWarningModal}
        onSave={() => {
          setNameModalVisible(true);
          toggleSaveWarningModal();
        }}
        onMoveToNext={() => {
          toggleSaveWarningModal();
          handleMovetoNext();
        }}
      />
      <SaveTemplateNameModal
        visible={nameModalVisible}
        setVisible={setNameModalVisible}
        onSubmit={({ name }) => {
          setNameModalVisible(false);
          const id = getUniqueId();
          dispatch(
            createTextTemplateThunk({
              type: props.elementType,
              title: name,
              text: props.editorState.getCurrentContent().getPlainText()
            })
          );
          setProp((state: any) => {
            state.templateId = id;
          });
          handleMovetoNext();
        }}
      />
    </div>
  );
};

export default SwitchTemplate;

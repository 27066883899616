import { setAgencyOSCurrentlyEditingTheme } from '../../AgencyOSBuilder/redux/agencyOSBuilderSlice';
import { setCurrentlyEditingTheme } from '../../redux/builderSlice';
import { useIsAgencyOSBuilderPath } from '../useIsAgencyOSBuilderPath';

export const setCurrentlyEditingThemeData = () => {
  const isAgencyOSBuilderPath = useIsAgencyOSBuilderPath();
  if (isAgencyOSBuilderPath) {
    return setAgencyOSCurrentlyEditingTheme;
  } else {
    return setCurrentlyEditingTheme;
  }
};

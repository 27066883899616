import React, { CSSProperties } from 'react';
import { Typography, Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
interface Props {
  title: string;
  classNames?: string;
  secondaryText?: string;
  rightContent?: any;
  innerWrapperClasses?: string;
  infoPopoverText?: string;
  style?: CSSProperties
}

const { Title } = Typography;

const BuilderSettingsTogglePro = (props: Props) => {
  return (
    <div style={props.style} className={`builder-settings-pro-title px-4 py-3 ${props.classNames}`}>
      <div className={`d-flex align-items-center ${props.innerWrapperClasses}`}>
        <Title className="settings-title mb-0" level={4}>
          {props.title}
        </Title>

        {props.infoPopoverText && (
          <Popover
            className="default-popover"
            content={<div className="default-popover__content">{props.infoPopoverText}</div>}
          >
            <QuestionCircleOutlined />
          </Popover>
        )}

        {props.secondaryText && <Typography className="pl-1">{props.secondaryText}</Typography>}
      </div>
      <div className={`d-flex align-items-center ${props.innerWrapperClasses}`}>
        {props.rightContent && props.rightContent}
      </div>
    </div>
  );
};

export default BuilderSettingsTogglePro;

import { defaultColorTheme } from '../config/builderSettingsColor';
import { BuilderPageDataType, FunnelThemeType } from '../interfaces/builderSliceTypes';

export const defaultCustomJS = `<script>\n\n\n\n</script>`;

export const defaultCraftState =
  'ewogICJST09UIjogxAwgICJ0eXBlxA4gInJlc29sdmVkTmFtxBIiQm9keSIgfSzGKGlzQ2FudmFzIjogdHJ1ZccWcHJvcMQTe8gnZGlzcGxhec1ExxtjdXN0b23MLWhpZGRlbiI6IGZhbHPIU25vZGXEU1siY0lqMDdPVE9YdiIsICJXRkJZYkdQQXd0xA5mTlZyeHZCUmh3Il3HOWxpbmtlZE7HP3t9CiDmAL3MSP8A9+QAs0xvZ2/1APfsAKXpAPjFDyAgImJhY2tncm91bmRDb2xvcsRJ0BPGOyAgImFsaWfkAPYiY2VudGXLGXVybMQXL2Rhc2hib2FyZC9mdW5uZWwtYnVpbGRlci9kZWZhdWx0LWxvZ28ucG5nyjtpc0PlAV/IfuUArcUd9wGa5QDn+QGacGFyZW50xCjlAi7/AbDmAbD/AYjtAcL/AYjEeU1haW5Db250YWluZXL/AojqAZAgImRhdGEtY3nEQ3Jvb3QtY9JE7wELzmr/ART/ARTwAsR2MlVVeUxsaGJE/wKoIO0C1P8BIOQAhUZvb+QCPf8CquwBGv8CpMQT+AEjx23/ARz/ARz/AjDxARDrATz/ARDEeUFkZP4CL/cBFmlkxD4xNjYyOTY0OTgyMzgx+QEHzWT/AQ3EMOwEtP8BE/8BEyB9Cn0K';

export const thankyouPageType = 'THANK_YOU';

export const defaultFunnelTheme: FunnelThemeType = {
  ...defaultColorTheme,
  fontFamily: 'Open Sans',
  name: '',
  id: 0,
  isCustom: false
};

export const getRichTextEditorRawState = (question = 'Frage') => {
  return {
    blocks: [
      {
        key: '9nmpt',
        text: question,
        type: 'center',
        depth: 0,
        inlineStyleRanges: [
          { offset: 0, length: question.length, style: 'CUSTOM_FONT_SIZE_2.5em' }
        ],
        entityRanges: [],
        data: {}
      }
    ],
    entityMap: {}
  };
};

export const defaultBuilderPages: BuilderPageDataType[] = [
  {
    name: 'Leere Seite',
    craftState: defaultCraftState,
    id: -1,
    pageOrder: 0,
    facebookPixelEvent: '',
    tikTokPixelEvent: '',
    linkedInEvent: '',
    googleTagTrigger: '',
    redirectURL: '',
    customJs: ''
  },
  {
    name: 'Dankesseite',
    craftState: defaultCraftState,
    id: -2,
    pageOrder: -1,
    type: thankyouPageType,
    facebookPixelEvent: '',
    tikTokPixelEvent: '',
    linkedInEvent: '',
    googleTagTrigger: '',
    customJs: '',
    redirectURL: ''
  }
];

import { Funnel } from '../../interfaces/builderSliceTypes';
import { JobAttributesIdentifier } from './AdJobHelper';

export function validateFunnelJobPresets(funnel: Funnel) {
  return Object.values(JobAttributesIdentifier).some(key => {
    if (key === JobAttributesIdentifier.EXPERIENCE) {
      return false;
    }
    const value = funnel[key];
    if (Array.isArray(value)) {
      return !value.length;
    } else if (typeof value === 'object' && value !== null) {
      return !value.place;
    } else {
      return !value;
    }
  });
}

export function formatSalary(value: string | number): string {
  const numericValue = typeof value === 'string' ? parseFloat(value) : value;
  if (isNaN(numericValue)) {
    return '';
  }
  return numericValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}

import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Form, Input, Button, Alert } from 'antd';
import { useMutation } from '@apollo/react-hooks';

import { MailOutlined, LockFilled } from '@ant-design/icons';
import LoginValidationRules from '../../Login/utils/LoginValidationRules';
import { EmailLoginAndRegistrationMessages } from '../../config/messages';
import { populateLocalStorageWithAuth } from '../../Login/container/Login';
import { forceLowerCase } from '../../Connect/utils/domainInput';

const LOGIN = gql`
  mutation login($input: LoginProperties!) {
    login(input: $input) {
      expirationDate
      token
      activated
      lastLogin
      isAdmin
      alternativeEmailEnabled
      alternativeEmail
      provider
      connectedToGoogle
      isEmployee
      userInfo {
        coachId
        firstName
        lastName
        email
      }
    }
  }
`;

const LoginForm = ({ onLogin }: { onLogin: Function }) => {
  const [infoMessage, setInfoMessage] = useState<{
    description: string;
    message: string;
    type: string;
  }>({
    type: 'error',
    description: '',
    message: ''
  });
  const [login, { loading: loadingLogin }] = useMutation(LOGIN);
  const onFinish = (values: any) => {
    handleLogin(values.email, values.password);
  };

  function handleLogin(email: string, password: string) {
    login({
      variables: { input: { email, password } }
    })
      .then(res => {
        populateLocalStorageWithAuth({
          ...res.data.login
        });
        onLogin();
      })
      .catch(e => {
        setInfoMessage({
          type: 'error',
          message: EmailLoginAndRegistrationMessages.login.error.message,
          description: EmailLoginAndRegistrationMessages.login.error.description
        });
      });
  }

  return (
    <>
      {infoMessage.message && (
        <Alert
          message={infoMessage.message}
          description={infoMessage.description}
          type="error"
          showIcon
        />
      )}
      <Form className="form--wrapper" initialValues={{ remember: true }} onFinish={onFinish}>
        <Form.Item name="email" rules={LoginValidationRules.email}>
          <Input
            size="large"
            prefix={<MailOutlined />}
            placeholder="E-Mail"
            onInput={forceLowerCase}
          />
        </Form.Item>
        <Form.Item name="password" rules={LoginValidationRules.password}>
          <Input size="large" prefix={<LockFilled />} type="password" placeholder="Passwort" />
        </Form.Item>
        <Form.Item>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            className="login-form-button"
            block
            disabled={loadingLogin}
            loading={loadingLogin}
          >
            Login
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default LoginForm;

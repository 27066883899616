import React from 'react';
import { Select, Typography, Space } from 'antd';

interface SelectListData {
  name: string;
  timeKey: string;
  length: number;
}

interface ScheduledEmailSelectList {
  selectListData: SelectListData[];
  handleChange: (v: number, k: string) => void;
  selectedTime: { [key: string]: any };
}

export default function ScheduledSelectList({
  selectListData,
  handleChange,
  selectedTime
}: ScheduledEmailSelectList) {
  return (
    <>
      <Space size={10}>
        {selectListData.map(({ name, timeKey, length }) => (
          <>
            <Typography.Text strong>{name}</Typography.Text>
            <Select
              style={{ width: '60px' }}
              onChange={value => {
                handleChange(value, timeKey);
              }}
              value={selectedTime[timeKey]}
              options={Array.from({ length }, (_, index) => ({
                value: index,
                label: index
              }))}
            />
          </>
        ))}
      </Space>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import { Table, Tag, Space, Button, Dropdown, Menu, message } from 'antd';
import moment from 'moment';
import gql from 'graphql-tag';
import { TablePaginationConfig } from 'antd/es/table/interface';
import EventModal from './Event/EventModal';
import {
  BookingActionEventInterface,
  BookingInterface,
  BookingStatus,
  LeadQualifierInterface,
  TrackingInterface
} from '../container/Events';
import {
  MoreOutlined,
  CloseOutlined,
  ContactsOutlined,
  DeleteOutlined,
  LoadingOutlined,
  LikeOutlined,
  DislikeOutlined
} from '@ant-design/icons';
import ActionModal from './Event/ActionModal';
import { useMutation } from '@apollo/react-hooks';
import { AgencyDashboarMessages } from '../../config/messages';
import DefaultSmallLoader from '../../SharedUI/components/DefaultSmallLoader';
import DefaultEmpty from '../../SharedUI/components/DefaultEmpty';
import dateFormats from '../../config/dateFormats';
import ActionEventIcon from './Event/ActionEventIcon';
import { getTextFromHtml } from '../../Builder/helper/sharedFunctions';
function instanceOfTracking(object: any): object is TrackingInterface {
  return 'source' in object;
}

const CHANGE_BOOKING_STATUS = gql`
  mutation($status: ChangeBookingStatus!, $note: BookingNoteData!) {
    changeBookingStatus(input: $status) {
      name
      bookingStatus {
        status
        id
      }
    }

    createBookingNote(input: $note) {
      id
      coachId
      note
      createdAt
    }
  }
`;

export interface ActionInterface {
  event: BookingInterface | TrackingInterface;
  action: 'APPROVED' | 'DECLINE';
  value: any;
  color?: string;
}
interface FlagStatus {
  APPROVED: BookingStatus;
  DECLINE: BookingStatus;
}
export const EventTable = ({
  loading,
  data,
  pagination,
  deleteBookingLoading,
  deleteTrackingLoading,
  handleDelete,
  leadQualifier,
  funnels,
  availableStatus
}: {
  loading: boolean;
  data: any;
  pagination: TablePaginationConfig;
  needlessEvent?: any;
  funnels: any[];
  deleteBookingLoading: boolean;
  deleteTrackingLoading: boolean;
  handleDelete: (id: number, version: string, type: string, callBack?: Function) => Promise<any>;
  handleModal: (value: number | undefined) => void;
  leadQualifier: LeadQualifierInterface[];
  expandedEvent: number | undefined;
  availableStatus: BookingStatus[];
}) => {
  const [flagStatus, setFlagStatus] = useState<FlagStatus>({} as FlagStatus);

  useEffect(() => {
    const temp: FlagStatus = {} as FlagStatus;
    availableStatus.forEach(single => {
      if (single.status === 'APPROVED') {
        temp.APPROVED = single;
      } else if (single.status === 'DECLINE') {
        temp.DECLINE = single;
      }
    });
    setFlagStatus(temp);
  }, [availableStatus]);

  const [changeBookingStatus] = useMutation(CHANGE_BOOKING_STATUS);

  const [toBeDeleted, setToBeDeleted] = useState<number | undefined>();
  const [action, setAction] = useState<ActionInterface | undefined>();

  const [bookings, setBookings] = useState<BookingInterface[]>([]);
  useEffect(() => {
    setBookings(data);
  }, [data]);

  const funnelTitleById = funnels.reduce((acc, { title, id }) => {
    acc[id] = title;
    return acc;
  }, {});

  const handleLocalDelete = (eventId: number) => {
    const updatedBooking = bookings.filter(single => single.id !== eventId);
    setBookings(updatedBooking);
  };

  const handleUpdateBookingEvents = (
    eventId: number,
    bookingEvents: BookingActionEventInterface
  ) => {
    const updatedBooking = bookings.map(single => {
      if (single.id === eventId) {
        single.bookingActionEvent = bookingEvents;
      }
      return single;
    });
    setBookings(updatedBooking);
  };
  const columns = [
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      width: '250px',
      render: (text: any, record: any) => {
        return (
          <div className="d-flex">
            <div className="table-column-icon">
              {record.bookingActionEvent && <ActionEventIcon record={record?.bookingActionEvent} />}
            </div>
            <div>
              <strong>{record.name || 'Unvollständig'}</strong>
              <div>{record.email || 'Der Funnel wurde verlassen'}</div>
            </div>
          </div>
        );
      }
    },
    {
      title: 'FUNNEL',
      dataIndex: 'funnelId',
      key: 'funnelId',
      width: '300px',
      render: (funnelId: string, record: any) => {
        const maxLength = 50;
        const title = funnelTitleById[funnelId];

        if (!title) return '';

        if (title.length > maxLength) return title.substring(0, maxLength) + '...';

        return title;
      }
    },
    {
      title: 'DATUM',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '150px',
      render: (text: any, record: any) => moment(text).format(dateFormats.dateReadableMonth)
    },
    {
      title: 'STATUS',
      dataIndex: 'createdAt',
      key: 'createdAt',
      maxWidth: '200px',
      render: (text: any, event: any) => {
        return (
          event?.bookingStatus?.status && (
            <div className="table-column-status">
              <Tag color={event?.bookingStatus?.color || 'default'}>
                {getTextFromHtml(event?.bookingStatus?.status)}
              </Tag>
              <div className="table-column-status__date">
                Geändert am {moment(text).format(dateFormats.dateReadableMonth)}
              </div>
            </div>
          )
        );
      }
    },
    {
      title: '',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: '50px',
      render: (text: any, event: any) => {
        if (!['NEW', 'PENDING'].includes(event?.bookingStatus?.status)) return;

        return (
          <Space className="action-button__wrapper">
            <Button
              type="primary"
              shape="circle"
              icon={<LikeOutlined />}
              size={'large'}
              className="btn-success"
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setAction({
                  event,
                  action: 'APPROVED',
                  value: flagStatus.APPROVED.value,
                  color: flagStatus.APPROVED.color
                });
              }}
            />
            <Button
              type="primary"
              shape="circle"
              icon={<DislikeOutlined />}
              size={'large'}
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setAction({
                  event,
                  action: 'DECLINE',
                  value: flagStatus.DECLINE.value,
                  color: flagStatus.DECLINE.color
                });
              }}
              danger
            />
          </Space>
        );
      }
    },
    {
      title: '',
      dataIndex: 'accept-reject',
      key: 'accept-reject',
      width: '50px',
      // align: 'right',
      render: (text: any, event: any, idx: number) => {
        return toBeDeleted === idx ? (
          deleteBookingLoading || deleteTrackingLoading ? (
            <LoadingOutlined />
          ) : (
            <Space size="small">
              <Button
                danger
                type="link"
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleDelete(
                    event?.id,
                    event.version,
                    instanceOfTracking(event) ? 'tracking' : 'booking',
                    handleLocalDelete
                  ).then(() => {
                    setToBeDeleted(undefined);
                  });
                }}
              >
                <DeleteOutlined />
                Löschen
              </Button>
              <Button type="link">
                <CloseOutlined
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    setToBeDeleted(undefined);
                  }}
                />
              </Button>
            </Space>
          )
        ) : (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item
                  className="no-select"
                  key="4"
                  style={{ color: 'red' }}
                  onClick={e => {
                    e.domEvent.stopPropagation();
                    e.domEvent.preventDefault();
                    setToBeDeleted(idx);
                  }}
                >
                  <DeleteOutlined /> Löschen
                </Menu.Item>
              </Menu>
            }
          >
            <div className="funnel-overview__card__action no-select">
              <MoreOutlined
                style={{ fontSize: 20 }}
                onClick={e => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
              />
            </div>
          </Dropdown>
        );
      }
    }
  ];

  const [selectedEvent, setSelectedEvent] = useState<
    | {
        event: BookingInterface | TrackingInterface;
        index: any;
      }
    | undefined
  >();

  let eventType =
    selectedEvent && instanceOfTracking(selectedEvent?.event) ? 'tracking' : 'booking';

  const handleUpdateBookingStatus = (e: any) => {
    changeBookingStatus({
      variables: {
        status: { value: action?.action, id: action?.event.id },
        note: { bookingId: action?.event.id, note: e.note }
      }
    }).then(({ data }) => {
      // @ts-ignore
      changeBookingStatusLocally(action?.event.id, action);

      setAction(undefined);
      message.success(AgencyDashboarMessages.changeStatusSuccess);
    });
  };

  const changeBookingStatusLocally = (id: number, action: ActionInterface) => {
    const newBooking = bookings.map(single => {
      if (single.id === id) {
        single.bookingStatus = {
          status: action.value,
          value: action.action,
          color: action.color || 'default'
        };
      }
      return single;
    });
    setBookings(newBooking);
  };

  return (
    <>
      <Table
        locale={{
          emptyText: (
            <DefaultEmpty
              icon={<ContactsOutlined />}
              title="Keine Bewerbungen vorhanden"
              description="Hier werden deine Bewerbungen aufgelistet, sobald sich jemand in deinen Funnel eingetragen hat."
            />
          )
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              setSelectedEvent({ event: record, index: rowIndex });
            }
          };
        }}
        columns={columns}
        dataSource={bookings}
        loading={
          loading ? { indicator: <DefaultSmallLoader loading={true} fontSize={30} /> } : false
        }
        // @ts-ignore
        pagination={pagination?.total < pagination?.pageSize ? false : pagination}
        scroll={{ scrollToFirstRowOnChange: true }}
      />
      <EventModal
        eventType={eventType}
        leadQualifier={leadQualifier}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        changeBookingStatusLocally={changeBookingStatusLocally}
        deleteLoading={deleteTrackingLoading || deleteBookingLoading}
        handleDelete={handleDelete}
        handleUpdateBookingEvents={handleUpdateBookingEvents}
      />
      <ActionModal
        isOpen={!!action}
        handleClose={() => {
          setAction(undefined);
        }}
        handleSubmit={handleUpdateBookingStatus}
      />
    </>
  );
};

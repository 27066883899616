import { RenderResult } from '@testing-library/react';
import { EDIT_CONTENT_TYPE } from '../context/ResourceEditContext';

export type ElementTypes = Element | Node | Document | Window;

export type RenderedResult = RenderResult['getByTestId'];

export type QueryByTextType = (
  text: string | RegExp,
  options?: { selector?: string }
) => HTMLElement | null;

export enum DomainType {
  FUNNEL = 'FUNNEL',
  AGENCY_DASHBOARD = 'AGENCY_DASHBOARD'
}

export enum CONNECT_CONTENT_TYPE_NOT_EDITABLE {
  MAKE = 'MAKE',
  ZAPIER = 'ZAPIER',
  GOOGLE_CALENDAR = 'GOOGLE_CALENDAR'
}

type connectCardTypes = CONNECT_CONTENT_TYPE_NOT_EDITABLE | EDIT_CONTENT_TYPE;
type CardDetails = { title: string; category: string };
type CardMapping = { [K in connectCardTypes]: CardDetails };

export const connectCardTitlesAndCategories: CardMapping = {
  [EDIT_CONTENT_TYPE.MY_DOMAINS]: { title: 'Eigene Domains', category: 'Hosting' },
  [CONNECT_CONTENT_TYPE_NOT_EDITABLE.GOOGLE_CALENDAR]: { title: 'Google Calendar', category: 'Kalender-Synchronisation' },
  [EDIT_CONTENT_TYPE.FACEBOOK_PIXELS]: { title: 'Facebook Pixel', category: 'Tracking' },
  [EDIT_CONTENT_TYPE.FACEBOOK_CONVERSATION_API]: { title: 'Facebook Conversions API', category: 'Tracking' },
  [EDIT_CONTENT_TYPE.GOOGLE_TAG]: { title: 'Google Tag Manager', category: 'Tracking' },
  [EDIT_CONTENT_TYPE.TIKTOK_PIXELS]: { title: 'TikTok Pixel', category: 'Tracking' },
  [EDIT_CONTENT_TYPE.LINKEDIN_TAG]: { title: 'LinekedIn Insight Tag', category: 'Tracking' },
  [EDIT_CONTENT_TYPE.WEBHOOK]: { title: 'Webhook', category: 'Automation' },
  [CONNECT_CONTENT_TYPE_NOT_EDITABLE.ZAPIER]: { title: 'Zapier', category: 'Automation' },
  [CONNECT_CONTENT_TYPE_NOT_EDITABLE.MAKE]: { title: 'Make', category: 'Automation' },
  [EDIT_CONTENT_TYPE.TRUST_PILOT]: { title: 'TrustPilot', category: 'Bewertungsportal' },
  [EDIT_CONTENT_TYPE.PROVEN_EXPERT]: {
    title: 'ProvenExpert', category: 'Bewertungsportal',
  }
}
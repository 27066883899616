import setEmailTemplatesGeneralSetting from '../graphql/setEmailTemplatesGeneralSetting';
import setFunnelMessagingTemplates from '../graphql/setFunnelMessagingTemplates';
import updateEmailTemplate from '../graphql/updateEmailTemplate';
import {
  BuilderState,
  CHANNEL_TYPE,
  EmailType,
  EmailTemplateType
} from '../interfaces/builderSliceTypes';
import { getEmailTemplateSanitizedHtml } from './craftJs';

import {
  getHashFromObject,
  getOtherEmailAddressesToSendForInternalConfirmation
} from './sharedFunctions';

export const saveChannelTemplatesTabContent = async ({ state }: { state: BuilderState }) => {
  const emailTemplatesGeneralSettings = {
    emailSenderName: state.channelTemplates.sender,
    brandingEnabled: state.channelTemplates.brandingEnabled
  };
  const tabContent =
    state.channelTemplates.templates[+state.channelTemplates.activeTab].emailTemplate;
  const { channelEnabled, sendingChannel } = state.channelTemplates.templates[
    +state.channelTemplates.activeTab
  ];
  const { emailSenderName, brandingEnabled, ...rest } = tabContent;
  const currentEmailTemplateActiveTabContent = {
    ...rest,
    enabled: channelEnabled && sendingChannel === CHANNEL_TYPE.EMAIL,
    html: getEmailTemplateSanitizedHtml({ tabContent })
  };

  const otherEmailAddressesToSend = getOtherEmailAddressesToSendForInternalConfirmation({
    emailTemplates: state.channelTemplates.templates.map(({ emailTemplate }) => emailTemplate)
  });

  const currentEmailTemplatesGeneralSettingChecksum = await getHashFromObject(
    emailTemplatesGeneralSettings
  );
  const currentEmailTemplateActiveTabContentChecksum = await getHashFromObject(
    currentEmailTemplateActiveTabContent
  );
  const currentOtherEmailAddressesToSendChecksum = await getHashFromObject({
    otherEmailAddressesToSend
  });

  const shouldUpdateEmailTemplatesGeneralSetting = !!(
    state.checksums.emailTemplatesGeneralSettings !== currentEmailTemplatesGeneralSettingChecksum ||
    !currentEmailTemplatesGeneralSettingChecksum
  );
  const shouldUpdateEmailTemplateActiveTabContent = !!(
    state.checksums.emailTemplateActiveTabContent !==
      currentEmailTemplateActiveTabContentChecksum || !currentEmailTemplateActiveTabContentChecksum
  );
  const shouldUpdateOtherEmailAddressesToSend = !!(
    state.checksums.otherEmailAddressesToSend !== currentOtherEmailAddressesToSendChecksum ||
    !currentOtherEmailAddressesToSendChecksum
  );

  if (shouldUpdateEmailTemplatesGeneralSetting)
    await setEmailTemplatesGeneralSetting(emailTemplatesGeneralSettings);
  if (shouldUpdateEmailTemplateActiveTabContent)
    await updateEmailTemplate(currentEmailTemplateActiveTabContent);
  if (
    shouldUpdateOtherEmailAddressesToSend &&
    tabContent.type !== EmailType.INTERNAL_CONFIRMATION
  ) {
    const internalConfirmationEmailTemplateTabContent = state.channelTemplates.templates.find(
      ({ emailTemplate }) => emailTemplate.type === EmailType.INTERNAL_CONFIRMATION
    );
    if (internalConfirmationEmailTemplateTabContent) {
      const {
        emailSenderName,
        brandingEnabled,
        ...rest
      } = internalConfirmationEmailTemplateTabContent.emailTemplate;
      const formattedInternalConfirmationEmailTemplateTabContent = {
        ...rest,
        enabled: channelEnabled && sendingChannel === CHANNEL_TYPE.EMAIL,
        html: getEmailTemplateSanitizedHtml({
          tabContent: internalConfirmationEmailTemplateTabContent.emailTemplate
        })
      };
      await updateEmailTemplate(formattedInternalConfirmationEmailTemplateTabContent);
    }
  }
  /////// Messaging ///////
  const messageGeneralSetting = {
    companyName: state.channelTemplates.senderName,
    contactNumber: state.channelTemplates.messageNumber,
    contactPersonCallNumber: state.channelTemplates.phoneNumber
  };

  const messageData = state.channelTemplates.templates
    .filter(({ messagingTemplate }) => messagingTemplate.id !== undefined)
    .map(({ messagingTemplate, channelEnabled, sendingChannel }) => {
      return {
        id: messagingTemplate.id,
        timeInMinutesTillEvent: messagingTemplate.timeInMinutesTillEvent,
        scheduledTime: messagingTemplate.scheduledTime,
        enabled: channelEnabled && sendingChannel === CHANNEL_TYPE.MESSAGE,
        ...messageGeneralSetting
      };
    });

  const currentMessagingTemplatesGeneralSettingChecksum = await getHashFromObject(
    messageGeneralSetting
  );

  const currentMessagingTemplatesChecksum = await getHashFromObject(messageData);

  const shouldUpdateMessagingTemplates =
    !!(
      state.checksums.messagingTemplatesGeneralSettings !==
        currentMessagingTemplatesGeneralSettingChecksum ||
      !currentMessagingTemplatesGeneralSettingChecksum
    ) ||
    !!(
      state.checksums.messagingTemplates !== currentMessagingTemplatesChecksum ||
      !currentMessagingTemplatesChecksum
    );

  if (shouldUpdateMessagingTemplates) await setFunnelMessagingTemplates(messageData);

  return {
    currentEmailTemplatesGeneralSettingChecksum,
    currentEmailTemplateActiveTabContentChecksum,
    currentOtherEmailAddressesToSendChecksum,
    currentMessagingTemplatesGeneralSettingChecksum,
    currentMessagingTemplatesChecksum
  };
};

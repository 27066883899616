import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, Row, message } from 'antd';
import Draft, { ContentBlock, convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import { useEditor, useNode } from '@craftjs/core';
// @ts-expect-error
import createStyles from 'draft-js-custom-styles';
import { ActionsController } from '../../../sharedUI/ActionsController';
import useOutsideAlerter from '../../../../hooks/useOutsideAlerter';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../../../config/craftElements';
import { CraftElementBaseProps, sleep } from '../../../../helper/craftJs';
import { UserInteractingComponentsMessages } from '../../../../../config/messages';
import { BUILDER_VALIDATION_ERRORS } from '../../../../container/BuilderValidator';
import { trackInteraction } from '../../../../DebugTracking/utils/helper';
import { TRACKINGS } from '../../../../DebugTracking/container/DebugTracker';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks';
import { selectGlobalSettingsFlags } from '../../../../../UI/redux/uiSlice';
import { getValueFromKeyValueArrayOfObjects } from '../../../../../helper/array';
import useCustomDebounce from '../../../../../hooks/useCustomDebounce';
import TextSuggestionSidebar from '../components/TextSuggestionSidebar';
import { textTypesEnum } from '../../../../AdBuilder/interfaces/TextTypeInterface';
import { TemplateContent, useTextTemplateData } from '../../../../AdBuilder/data';
import RichTextSetting from '../../RichTextSetting';
import { deleteJobTextTemplateThunk } from '../../../../AdJobBuilder/redux/thunk';
import { useDispatch } from 'react-redux';
import { useUserContentTemplate } from '../../../../AdBuilder/redux/adBuilderSlice';
import DefaultEmptyViewForSettingsPanel from '../../../sharedUI/DefaultEmptyViewForSettingsPanel';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { SAVEABLE_ELEMENTS } from '../../../../AdJobBuilder/config/craftElements';
import {
  addBuilderValidationError,
  removeBuilderValidationError
} from '../../../../redux/builderSlice';

const { Editor, RichUtils } = Draft;
const defaultRawState = `{"blocks":[{"key":"aa9lq","text":"🚀 Consectetur adipiscing elit.","type":"center","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}},{"key":"ffe5a","text":"Lorem ipsum dolor sit amet, consectetur adipiscing","type":"center","depth":0,"inlineStyleRanges":[{"offset":0,"length":56,"style":"CUSTOM_FONT_SIZE_2.5em"}],"entityRanges":[],"data":{}},{"key":"9ot8f","text":"Vivamus magna justo, lacinia eget consectetur sed, convallis at tellus. Cras ultricies ligula sed magna dictum porta.","type":"center","depth":0,"inlineStyleRanges":[{"offset":0,"length":117,"style":"unstyled"}],"entityRanges":[],"data":{}}],"entityMap":{}}`;

let richTextMissmatchErrorIsSent = false;

interface Props extends CraftElementBaseProps {
  editorState?: any;
  rawState?: any;
  isLeadQualifier?: boolean;
  required?: any;
  elementType?: textTypesEnum;
  showArrow?: boolean;
  saveable?: boolean;
  label?: string;
  settings?: {
    shouldShowSettings: boolean;
  };
  alignmentDisabled?: boolean;
  saveableElements?: SAVEABLE_ELEMENTS;
}
const RichTextComponent = ({
  editorState,
  rawState,
  isLeadQualifier = true,
  required,
  elementType,
  label
}: Props) => {
  const globalSettingsFlags = useAppSelector(selectGlobalSettingsFlags);
  const [isEditable, setIsEditable] = useState(true);
  const dispatch = useAppDispatch();
  const { query, actions } = useEditor();
  const { nodeId, currentNode } = useNode(node => ({
    dragged: node.events.dragged,
    hasSelectedNode: node.events.selected,
    nodeId: node.id,
    currentNode: node
  }));
  const { styles, exporter } = createStyles(['font-size', 'color'], 'CUSTOM_');
  const editorRef = useRef(null);
  const wrapperRef = useRef();
  const isEmptyEditor = !editorState?.getCurrentContent().hasText();
  const makeInvalid = required && isEmptyEditor && !rawState;

  const debouncedTrackInteraction = useCustomDebounce((interaction: any) => {
    trackInteraction(interaction);
  }, 300);

  const editorStateRef = useRef(editorState);

  const isDomTextDifferentToEditorState = () => {
    const editorStateText = editorStateRef?.current
      ?.getCurrentContent()
      ?.getPlainText('\u0001')
      .replace(/[\s\x00-\x1F\x7F]/g, '');
    // @ts-ignore
    const domText = editorRef?.current?.editor?.textContent.replace(/[\s\x00-\x1F\x7F]/g, '');

    return {
      editorStateText,
      domText,
      isDifferent: editorStateText !== domText
    };
  };

  useEffect(() => {
    editorStateRef.current = editorState;
  }, [editorState]);

  useEffect(() => {
    const interval = setInterval(() => {
      const { isDifferent } = isDomTextDifferentToEditorState();
      if (isDifferent) {
        sleep(300).then(() => {
          const {
            isDifferent: isDifferent2,
            domText,
            editorStateText
          } = isDomTextDifferentToEditorState();

          if (isDifferent2 && !richTextMissmatchErrorIsSent) {
            trackInteraction({
              type: 'FUNCTION_CALL',
              customEventName: TRACKINGS.RICHTEXT_CONTENT_MISSMATCH,
              additionalData: {
                nodeId,
                domText,
                editorStateText,
                editorState: convertToRaw(editorStateRef?.current?.getCurrentContent()),
                consoleLogs: window.meetovoCapturedMessages
              },
              onSuccess: () => {
                richTextMissmatchErrorIsSent = true;
              }
            });
            window.meetovoCapturedMessages = [];

            const richTextComponentShouldShowMissmatchWarning = getValueFromKeyValueArrayOfObjects(
              globalSettingsFlags,
              'richTextComponentShouldShowMissmatchWarning'
            );

            if (
              richTextComponentShouldShowMissmatchWarning &&
              !window.richTextMissmatchErrorIsShowingUp
            ) {
              message.warning(
                'Der Inhalt in diesem Textfeld konnte nicht gespeichert werden. Bitte speichere und aktualisiere die Seite und melde dich anschließend im Support-Chat.',
                10
              );

              window.richTextMissmatchErrorIsShowingUp = true;
            }
          }
        });
      }
    }, 4000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (!query.node(nodeId).get()) return;

    actions.history.ignore().setProp(nodeId, (props: any) => {
      props.styles = styles;
      props.exporter = exporter;
    });
  }, []);

  useEffect(() => {
    if (!query.node(nodeId).get()) return;

    if (rawState) {
      editorState = EditorState.createWithContent(convertFromRaw(rawState));
      actions.history.ignore().setProp(nodeId, (props: any) => {
        props.editorState = EditorState.createWithContent(convertFromRaw(rawState));
        props.rawState = null;
      });
    } else if (!editorState) {
      editorState = EditorState.createWithContent(convertFromRaw(JSON.parse(defaultRawState)));
      actions.history.ignore().setProp(nodeId, (props: any) => {
        props.editorState = EditorState.createWithContent(
          convertFromRaw(JSON.parse(defaultRawState))
        );
        props.rawState = null;
      });
    }
  }, [rawState, editorState]);

  const focus = () => {
    setIsEditable(true);
    setDisableDrag(true);
  };
  const onChange = (newEditorState: EditorState) => {
    const oldContent = editorState.getCurrentContent().getPlainText('\u0001');
    const newContent = newEditorState.getCurrentContent().getPlainText('\u0001');
    if (oldContent !== newContent) {
      const trackAllRichTextInteractions = getValueFromKeyValueArrayOfObjects(
        globalSettingsFlags,
        'trackAllRichTextInteractions'
      );
      if (trackAllRichTextInteractions) {
        trackInteraction({
          type: 'FUNCTION_CALL',
          customEventName: TRACKINGS.ON_CHANGE_IN_RICH_TEXT_COMPONENT,
          additionalData: {
            nodeId,
            editorContent: newContent,
            editorState: convertToRaw(newEditorState?.getCurrentContent())
          }
        });
      }
      actions.history.throttle().setProp(nodeId, (props: any) => {
        props.editorState = newEditorState;
        props.isEdited = true;
      });
    } else {
      actions.history.ignore().setProp(nodeId, (props: any) => {
        props.editorState = newEditorState;
      });
    }
  };

  const [disableDrag, setDisableDrag] = useState(true);
  useOutsideAlerter(wrapperRef, () => {
    setDisableDrag(false);
  });

  useEffect(() => {
    if (required) {
      makeInvalid
        ? dispatch(addBuilderValidationError(BUILDER_VALIDATION_ERRORS.RICH_TEXT))
        : dispatch(removeBuilderValidationError(BUILDER_VALIDATION_ERRORS.RICH_TEXT));
    }
    return () => {
      dispatch(removeBuilderValidationError(BUILDER_VALIDATION_ERRORS.RICH_TEXT));
    };
  }, [makeInvalid, required]);

  // this is just to prevent layout transsiotn and empty text eleement on first render. Real state will be set inside effect
  editorState =
    !editorState && rawState
      ? EditorState.createWithContent(convertFromRaw(rawState))
      : editorState;

  if (!editorState) return null;

  return (
    <ActionsController
      className="rich-text__wrapper disable-builder"
      canDrag={disableDrag}
      label={
        CRAFT_ELEMENTS_LABEL[elementType ?? ''] ||
        CRAFT_ELEMENTS_LABEL[currentNode?.data?.displayName ?? '']
      }
    >
      <div
        className={`rich-text__inner ${makeInvalid && 'error-border'}`}
        data-testid="rich-text-test-id"
        // @ts-ignore
        ref={wrapperRef}
        onMouseDown={focus}
        style={{ height: '100%' }}
      >
        <Editor
          blockStyleFn={getBlockStyle}
          editorState={editorState}
          customStyleFn={customStyleFn}
          onChange={onChange}
          placeholder="Gib hier deinen Text ein…"
          ref={editorRef}
          spellCheck={true}
          readOnly={!isEditable}
          stripPastedStyles
        />
      </div>
      {makeInvalid && (
        <span className="error-message">{UserInteractingComponentsMessages.noEmptyQuestion}</span>
      )}
    </ActionsController>
  );
};
const customStyleFn = (style: any) => {
  const styleMap = style._map?._list?._tail?.array?.filter((single: any) => {
    return single?.[0].includes('CUSTOM_');
  });
  const styles = {};
  styleMap?.forEach((element: any) => {
    if (element?.[0]?.includes('CUSTOM_COLOR_')) {
      // @ts-ignore
      styles.color = element?.[0]?.split('CUSTOM_COLOR_')[1];
    } else if (element?.[0]?.includes('CUSTOM_FONT_SIZE_')) {
      // @ts-ignore
      styles.fontSize = element?.[0]?.split('CUSTOM_FONT_SIZE_')[1];
    }
  });
  return styles;
};

function getBlockStyle(block: ContentBlock) {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    case 'left':
      return 'align-left';
    case 'center':
      return 'align-center';
    case 'right':
      return 'align-right';
    default:
      return 'normal';
  }
}

export const RichTextSettings = () => {
  const {
    actions: { setProp },
    props,
    nodeId
  } = useNode(node => ({
    nodeId: node.id,
    props: node.data.props
  }));
  const { actions } = useEditor();

  const userTemplate: TemplateContent[] = useUserContentTemplate(props.elementType);

  const content = useTextTemplateData(props.elementType);
  const dispatch = useDispatch();
  const handleDelete = (id: number) => {
    dispatch(deleteJobTextTemplateThunk({ type: props.elementType, id }));
  };

  return (
    <>
      {props.showArrow ? (
        <Col span={24} className="builder__settings-sidebar__container">
          <TextSuggestionSidebar
            userTemplate={userTemplate}
            content={content}
            handleDelete={handleDelete}
          />
        </Col>
      ) : props?.canHide?.hideSwich ? (
        <Col span={24} className="builder__settings-sidebar__container" style={{ height: '100%' }}>
          <div className="empty-page-list--container">
            <EyeInvisibleOutlined
              className="mb-3"
              style={{ color: '#e0e0e0', fontSize: 60 }}
              onClick={() =>
                setProp((props: any) => {
                  props.canHide.hideSwich = !props?.canHide?.hideSwich;
                })
              }
            />
          </div>
        </Col>
      ) : props.settings.shouldShowSettings ? (
        <RichTextSetting />
      ) : (
        <Col span={24} className="builder__settings-sidebar__container" style={{ height: '100%' }}>
          <DefaultEmptyViewForSettingsPanel
            title="Einstellungen deaktiviert"
            description="Dieser Text wird durch Standardeinstellungen formatiert die du nicht ändern kannst."
          />
        </Col>
      )}
    </>
  );
};

export const defaultEditorState = EditorState.createWithContent(
  convertFromRaw(JSON.parse(defaultRawState))
);

RichTextComponent.craft = {
  name: CRAFT_ELEMENTS.RICH_TEXT,
  props: {
    isLeadQualifier: true,
    showArrow: false,
    settings: {
      shouldShowSettings: true
    },
    alignmentDisabled: false,
    textAi: true
  },
  related: {
    settings: RichTextSettings
  }
};

export default RichTextComponent;

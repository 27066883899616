import React from 'react';
import { useNavigate } from 'react-router';
import useAgencyDashboard from '../../hooks/useAgencyDashboard';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { Role } from '../utils/roleTypes';
import meetovoLogo from '../resources/meetovo-logo-text-white-p-500.png';

const Logo = ({
  iconOnly,
  redirectionNeeded = true,
  collapsed,
  isBuilderScreen
}: {
  iconOnly?: boolean;
  redirectionNeeded?: boolean;
  collapsed?: boolean;
  isBuilderScreen?: boolean;
}) => {
  const navigate = useNavigate();
  const { data } = useAgencyDashboard();
  const { role } = useCurrentUser();

  let logo =
    role === Role.AGENCY_CUSTOMER && data?.getAgencyDashboard.logoURL
      ? data?.getAgencyDashboard.logoURL
      : process.env.PUBLIC_URL + `/meetovo-${iconOnly ? 'icon-256' : 'logo'}.png`;

  if (isBuilderScreen) {
    logo = collapsed ? process.env.PUBLIC_URL + '/meetovo-icon-256.png' : meetovoLogo;
  }
  const reDirectLink = role === Role.AGENCY_CUSTOMER ? '/kontakte' : '/meine-funnels';

  const imageConfig = iconOnly
    ? {
        width: '45px',
        height: '45px'
      }
    : {};

  return (
    <div className={`ui__logo cursor-pointer ${iconOnly ? 'without-name' : ''}`}>
      <img
        style={imageConfig}
        src={logo}
        onClick={() => redirectionNeeded && navigate(reDirectLink)}
      />
    </div>
  );
};

export default Logo;

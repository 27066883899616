import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppSelector } from '../../../redux/hooks';
import { getCompleteWidgetBuilder, linkFunnelToWidget, saveWidgetBuilderStateThunk } from './thunk';
import {
  FunnelDetails,
  Widget,
  WidgetBuilderStateChecksums,
  WidgetBuilderStateType
} from '../interfaces/widgetSliceTypes';
import { message } from 'antd';
import { GeneralMessages } from '../../../config/messages';

export const INITIAL_STATE: WidgetBuilderStateType = {
  widget: {
    id: 0,
    craftState: '',
    title: '',
    uniqueIdentifier: ''
  },
  isWidgetSaved: true,
  shouldSaveWidget: true,
  checksums: {
    widget: null,
    WidgetTheme: null
  },
  loaders: {
    widgetBuilderStateFetch: true,
    widgetBuilderStateSave: false,
    linkFunnelToWidget: false
  },
  currentWidgetId: 0,
  linkedFunnelDetails: {}
};

//@ts-ignore
const widgetBuilderUiSlice = createSlice({
  name: 'widgetBuilderUI',
  initialState: INITIAL_STATE,
  reducers: {
    resetToInitialWidgetBuilderState: state => {
      return INITIAL_STATE;
    },
    setCraftState: (state, { payload }: PayloadAction<{ craftState: string }>) => {
      state.widget.craftState = payload.craftState;
    },
    setCurrentWidgetId: (state, { payload }: PayloadAction<{ id: number }>) => {
      state.currentWidgetId = payload.id;
    },
    editIsWidgetSaved: (state, { payload }: PayloadAction<boolean>) => {
      state.isWidgetSaved = payload;
    },
    setShouldSaveWidget: (state, { payload }: PayloadAction<boolean>) => {
      state.shouldSaveWidget = payload;
    },
    handleRemoveLinkedFunnel: (state, { payload }: PayloadAction<number>) => {
      state?.linkedFunnelDetails && delete state?.linkedFunnelDetails[payload as number];
    },
    setWidgetTitle: (state, { payload }: PayloadAction<string>) => {
      state.widget.title = payload;
      state.isWidgetSaved = false;
    },
    setWidgetUniqueIdentifier: (state, { payload }: PayloadAction<string>) => {
      state.widget.uniqueIdentifier = payload;
      state.isWidgetSaved = false;
    }
  },
  extraReducers: {
    //@ts-ignore
    [getCompleteWidgetBuilder.fulfilled]: (
      state: WidgetBuilderStateType,
      action: PayloadAction<{
        widget: Omit<Widget, 'funnels'> & { funnels: { [key: string]: any }[] };
        checksums: WidgetBuilderStateChecksums;
      }>
    ) => {
      const newObj = action.payload.widget.funnels.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: item }),
        {}
      );
      state.linkedFunnelDetails = { ...(state.linkedFunnelDetails || {}), ...newObj };
      state.widget = {
        id: action.payload.widget.id,
        craftState: action.payload.widget.craftState,
        title: action.payload.widget.title,
        uniqueIdentifier: action.payload.widget.uniqueIdentifier
      };
      state.checksums = action.payload.checksums;
      state.loaders.widgetBuilderStateFetch = false;
    },
    //@ts-ignore
    [getCompleteWidgetBuilder.pending]: (state: WidgetBuilderStateType) => {
      state.loaders.widgetBuilderStateFetch = true;
    },
    // @ts-ignore
    [getCompleteWidgetBuilder.rejected]: (state: WidgetBuilderStateType) => {
      state.loaders.widgetBuilderStateFetch = false;
      message.error(GeneralMessages.error);
    },
    //@ts-ignore
    [linkFunnelToWidget.fulfilled]: (
      state: WidgetBuilderStateType,
      action: PayloadAction<{
        funnelData: FunnelDetails;
      }>
    ) => {
      const { funnelData } = action.payload;

      state.linkedFunnelDetails = {
        ...(state.linkedFunnelDetails || {}),
        [funnelData.id as number]: funnelData
      };
      state.loaders.linkFunnelToWidget = false;
    },
    //@ts-ignore
    [linkFunnelToWidget.pending]: (state: WidgetBuilderStateType) => {
      state.loaders.linkFunnelToWidget = true;
    },
    // @ts-ignore
    [linkFunnelToWidget.rejected]: (state: WidgetBuilderStateType) => {
      state.loaders.linkFunnelToWidget = false;
      message.error(GeneralMessages.error);
    },
    // @ts-ignore
    [saveWidgetBuilderStateThunk.fulfilled]: (
      state: WidgetBuilderStateType,
      action: PayloadAction<{ checksums: WidgetBuilderStateChecksums; craftState: string }>
    ) => {
      if (!state.shouldSaveWidget) return;

      state.checksums = { ...state.checksums, ...action.payload.checksums };
      state.widget.craftState = action.payload.craftState;

      state.isWidgetSaved = true;
      state.loaders.widgetBuilderStateSave = false;
    },
    //@ts-ignore
    [saveWidgetBuilderStateThunk.pending]: (state: WidgetBuilderStateType) => {
      state.loaders.widgetBuilderStateSave = true;
    },
    // @ts-ignore
    [saveWidgetBuilderStateThunk.rejected]: (state: WidgetBuilderStateType) => {
      state.loaders.widgetBuilderStateSave = false;
    }
  }
});

export const {
  setCraftState,
  setCurrentWidgetId,
  editIsWidgetSaved,
  setShouldSaveWidget,
  resetToInitialWidgetBuilderState,
  setWidgetTitle,
  setWidgetUniqueIdentifier
} = widgetBuilderUiSlice.actions;

export const useWidget = (): Widget => {
  return useAppSelector(state => state.widgetBuilderUI.widget);
};

export const useCurrentWidgetId = (): any => {
  return useAppSelector((state: any) => state.widgetBuilderUI.currentWidgetId);
};

export const useWidgetBuilderLoading = (): boolean => {
  return useAppSelector(state => {
    return state.widgetBuilderUI.loaders.widgetBuilderStateFetch;
  });
};

export const useSaveWidgetBuilderLoading = (): boolean => {
  return useAppSelector(state => state.widgetBuilderUI.loaders.widgetBuilderStateSave);
};

export const useIsWidgetSaved = (): boolean => {
  return useAppSelector(state => state.widgetBuilderUI.isWidgetSaved);
};

export const useShouldSaveWidget = (): boolean => {
  return useAppSelector(state => state.widgetBuilderUI.shouldSaveWidget);
};

export const useLinkFunnelToWidgetLoading = (): boolean => {
  return useAppSelector(state => state.widgetBuilderUI.loaders.linkFunnelToWidget);
};

export const useLinkedFunnelDetails = (funnelId: number): FunnelDetails => {
  return useAppSelector(state => {
    return state.widgetBuilderUI?.linkedFunnelDetails
      ? state.widgetBuilderUI?.linkedFunnelDetails[funnelId]
      : {};
  });
};

export default widgetBuilderUiSlice.reducer;

import React, { useState } from 'react';
import { Modal, Form, Input, DatePicker, Select, Button, message } from 'antd';
import { sendFeedbackMutation } from '../utils/graphql';
import { formRules } from '../utils/formValidationRules';
import { Store } from 'antd/lib/form/interface';
import { getPageInformation } from '../utils/helper';
import { getCookie } from '../../../helper/cookies';

const { Option } = Select;

interface DebugTrackerFeedbackFormProps {
  visible: boolean;
  onClose: () => void;
}

const DebugTrackerFeedbackForm = ({ visible, onClose }: DebugTrackerFeedbackFormProps) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values: Store) => {
    try {
      setLoading(true);
      const { pathname, selectedPageId } = getPageInformation();
      const sessionId = getCookie('meetovo_builder_session_id');
      const fingerprint = getCookie('meetovo_builder_fingerprint');

      await sendFeedbackMutation({
        sessionId,
        fingerprint,
        selectedPageId,
        pathname,
        additionalData: values
      });
      message.success('Vielen Dank für deine Hilfe :) Wir werten deine Angaben umgehend aus.');
      form.resetFields();
      onClose();
    } catch (error) {
      message.error('Feedback wurde nicht übertragen. Bitte versuche es erneut.');
      console.error(error);
    }

    setLoading(false);
  };

  return (
    <Modal title="Speicherproblem melden" visible={visible} onCancel={onClose} footer={null}>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          label="Wann hast du das letzte Mal an diesem Funnel gearbeitet?"
          name="lastWorked"
          rules={formRules.required}
        >
          <DatePicker showTime />
        </Form.Item>

        <Form.Item
          label="Wann ist dir aufgefallen, dass Änderungen fehlen?"
          name="noticedMissingChanges"
          rules={formRules.required}
        >
          <DatePicker showTime />
        </Form.Item>

        <Form.Item
          label="Inwiefern sind die Änderungen verloren gegangen?"
          name="lostChanges"
          rules={formRules.required}
        >
          <Select>
            <Option value="Änderungen sind teilweise auf mehreren Seiten verloren">
              Änderungen sind teilweise auf mehreren Seiten verloren
            </Option>
            <Option value="Änderungen sind teilweise auf einer Seiten verloren">
              Änderungen sind teilweise auf einer Seite verloren
            </Option>
            <Option value="Alle Änderungen auf allen Seiten sind verloren">
              Alle Änderungen auf allen Seiten sind verloren
            </Option>
            <Option value="Änderungen sind nur auf bestimmten Seiten verloren">
              Änderungen sind nur auf bestimmten Seiten verloren
            </Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Ist der Bildschirm eingefroren als du das letzte Mal Änderungen in DIESEM Funnel gemacht hast?"
          name="frozenScreen"
          rules={formRules.required}
        >
          <Select>
            <Option value="Ja">Ja</Option>
            <Option value="Nein">Nein</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Welche Seiten sind betroffen?"
          name="affectedPages"
          rules={formRules.required}
        >
          <Input.TextArea rows={3} />
        </Form.Item>

        <Form.Item
          label="Welche Elemente sind betroffen?"
          name="affectedElements"
          rules={formRules.required}
        >
          <Input.TextArea rows={3} />
        </Form.Item>

        <Form.Item
          label="Was ist das Letzte, das du im Funnel bearbeitet hast bevor deine Änderungen verloren gegangen sind?"
          name="lastChangesTheUserRemembers"
          rules={formRules.required}
        >
          <Input.TextArea rows={3} />
        </Form.Item>

        <Form.Item
          label="Welche Informationen könnten uns noch helfen? Bitte so detailliert und eindeutig wie möglich beschreiben"
          name="additionalInfo"
          rules={formRules.notRequired}
        >
          <Input.TextArea rows={10} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Senden
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DebugTrackerFeedbackForm;

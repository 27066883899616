import React from 'react';
import { Button, Modal, Result } from 'antd';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../../../redux/hooks';
import { setGeneralSettingsTabView } from '../../../redux/builderSlice';
import { GeneralSettingsTabView } from '../../../interfaces/builderSliceTypes';

interface JobValidationModalProps {
  openJobValidationModal: boolean;
}

export default function JobValidationModal({ openJobValidationModal }: JobValidationModalProps) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  return (
    <Modal
      width={450}
      title={null}
      visible={openJobValidationModal}
      closeIcon={<></>}
      footer={null}
    >
      <img
        src="/dashboard/funnel-builder/job-attributes-mockup.jpg"
        className="decent-border w-100"
      />
      <Result
        status="error"
        title="Job-Informationen nicht hinterlegt"
        subTitle="Um fortzufahren, stelle sicher, dass alle Job-Informationen korrekt ausgefüllt sind."
        extra={[
          <Button
            type="primary"
            className="mt-2"
            onClick={() => {
              dispatch(setGeneralSettingsTabView(GeneralSettingsTabView.JOB_INFORMATION));
              navigate('/builder/grundeinstellungen');
            }}
          >
            Jetzt hinterlegen
          </Button>
        ]}
      />
    </Modal>
  );
}

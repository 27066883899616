import { AGENCY_OS_THEME_KEYS } from '../AgencyOSBuilder/interfaces/agencyOSBuilderSliceTypes';
import { BuilderBase, CardTypes } from '../config/builderUi';
import { BUILDER_VALIDATION_ERRORS } from '../container/BuilderValidator';

// ! Do not change the order of the EmnailTypes.
// ! It is used to identify which EmailTemplate is being edited
export enum EmailType {
  CONFIRMATION = 'CONFIRMATION',
  INTERNAL_CONFIRMATION = 'INTERNAL_CONFIRMATION',
  SCHEDULED = 'SCHEDULED',
  CANCELATION = 'CANCELATION',
  REMINDER = 'REMINDER',
  NOT_REACHED = 'NOT_REACHED'
}

export enum MessagingType {
  CONFIRMATION = 'CONFIRMATION',
  CANCELATION = 'CANCELATION',
  REMINDER = 'REMINDER',
  SCHEDULED = 'SCHEDULED',
  NOT_REACHED = 'NOT_REACHED'
}

export enum DraggingFrom {
  ICON = 'Icon',
  ACTION_CONTROLLER = 'action_controller'
}

export enum GeneralSettingsTabView {
  GENERAL_SETTINGS = 'GENERAL_SETTINGS',
  META_DATA = 'META_DATA',
  JOB_INFORMATION = 'JOB_INFORMATION'
}

export enum DesignSettingsTabView {
  COLORS = 'COLORS',
  WRITING = 'WRITING',
  PRESETS = 'PRESETS'
}

export type IndexedElement = {
  index: number;
};

export type DraggingElement = {
  name: string | null;
  nodeId?: string;
  draggingFrom?: DraggingFrom;
  parentNodeId?: string;
  nodeIndex?: number;
  displayName?: string;
};

export interface BuilderPageDataType {
  name: string;
  id: number;
  craftState: any;
  type?: string;
  pageOrder: number;
  facebookPixelEvent: string;
  tikTokPixelEvent: string;
  linkedInEvent: string;
  googleTagTrigger: string;
  customJs?: string;
  redirectURL?: string;
}

export enum FUNNEL_THEME_KEYS {
  HEADLINE_COLOR = 'headlineColor',
  TEXT_COLOR = 'textColor',
  ACCENT_COLOR = 'accentColor',
  BACKGROUND_COLOR = 'backgroundColor',
  CARD_BACKGROUND_COLOR = 'cardBackgroundColor',
  CARD_ICON_COLOR = 'cardIconColor',
  CARD_TEXT_COLOR = 'cardTextColor',
  ACCENT_COLOR_CONTRAST = 'accentColorContrast'
}

export type PossibleFunnelThemeKeys =
  | FUNNEL_THEME_KEYS.HEADLINE_COLOR
  | FUNNEL_THEME_KEYS.TEXT_COLOR
  | FUNNEL_THEME_KEYS.ACCENT_COLOR
  | FUNNEL_THEME_KEYS.BACKGROUND_COLOR
  | FUNNEL_THEME_KEYS.CARD_BACKGROUND_COLOR
  | FUNNEL_THEME_KEYS.CARD_ICON_COLOR
  | FUNNEL_THEME_KEYS.CARD_TEXT_COLOR
  | AGENCY_OS_THEME_KEYS.PRIMARY_COLOR
  | AGENCY_OS_THEME_KEYS.PRIMARY_CONTRAST_COLOR
  | AGENCY_OS_THEME_KEYS.TEXT_COLOR;

export interface FunnelThemeType {
  id: number;
  headlineColor: string;
  textColor: string;
  accentColor: string;
  backgroundColor: string;
  cardBackgroundColor: string;
  cardIconColor: string;
  cardTextColor: string;
  accentColorContrast: string;
  defaultGradient: string;
  fontFamily: string;
  isCustom: boolean;
  fontLink?: string;
  funnelId?: number;
  userId?: number;
  name: string;
}

export interface builderCommonElementsTypes {
  header: string;
  footer: string;
}
export interface MetaData {
  metaTitle?: string;
  description?: string;
  customMetaDataEnabled?: boolean;
  iconURL?: string;
  previewImageURL?: string;
}

export enum CHANNEL_DATA_INPUTS {
  SENDER = 'sender',
  BRANDING_ENABLED = 'brandingEnabled',
  SENDER_NAME = 'senderName',
  Message_NUMBER = 'messageNumber',
  PHONE_NUMBER = 'phoneNumber'
}

export enum CHANNEL_TYPE {
  EMAIL = 'email',
  MESSAGE = 'MESSAGE'
}

export interface EmailTemplateType {
  id: number;
  subject: string;
  html: any;
  type: EmailType;
  emailSenderName: string;
  enabled: boolean;
  brandingEnabled: boolean;
  otherEmailAddressesToSend?: string;
  scheduledTime?: number;
}

export interface MessagingAndEmailTempaltes {
  sendingChannel: CHANNEL_TYPE;
  emailTemplate: EmailTemplateType;
  messagingTemplate: MessagingTemplateTabType;
  channelEnabled: boolean;
}

export interface ChannelTemplatesType {
  sender: string;
  brandingEnabled: boolean;
  activeTab: string;
  templates: MessagingAndEmailTempaltes[];
  senderName?: string;
  messageNumber?: string;
  phoneNumber?: string;
}

export interface MessagingTemplateTabType {
  id: number;
  timeInMinutesTillEvent: number;
  companyName: string;
  messageNumber: string;
  callNumber: string;
  enabled: boolean;
  templateId: string;
  content: string;
  brandingEnabled: boolean;
  scheduledTime: number;
  type: string;
}

export interface GeneralSettings {
  title?: string;
  uniqueIdentifier?: string;
  brandingEnabled?: boolean;
  language?: string;
  cookieBanner?: boolean;
}

export interface JobAddressType {
  place?: string;
  zoom: number;
  lat: number;
  lng: number;
}

export interface Funnel {
  id: number;
  uniqueIdentifier: string;
  builderVersion: string;
  previewImage?: string;
  domain: { status: string; name: string; fullLink: string };
  isEditing: boolean;
  jobTitle: string;
  companyName: string;
  category: string[];
  employmentType: string[];
  baseSalary: number[];
  experience: number;
  education: string[];
  workplaceModel: string;
  jobAddress: JobAddressType;
  salaryPlan: string;
  experienceDuration: string;
}

export interface BuilderStateChecksums {
  builderPages: string | null;
  generalSettings: string | null;
  calendarSettings: string | null;
  funnelTheme: string | null;
  funnel: string | null;
  metaData: string | null;
  otherEmailAddressesToSend?: string | null;
  emailTemplatesGeneralSettings?: string | null;
  emailTemplateActiveTabContent?: string | null;
  messagingTemplatesGeneralSettings?: string | null;
  messagingTemplates?: string | null;
  funnelPresets: string | null;
}

export interface SaveCompleteBuilderThunkPayloadType {
  shouldOnlySaveEmailTemplates?: boolean;
  callbackOnSucces?(): void;
}

export interface ChannelStateChecksums {
  emailTemplatesGeneralSettings: string | null;
  emailTemplateActiveTabContent: string | null;
  otherEmailAddressesToSend: string | null;
  messagingTemplatesGeneralSettings: string | null;
  messagingTemplates: string | null;
}

export type SelectedBaseInfo = {
  selectedIndex: number;
  baseItems: BaseItem[];
};
export interface BuilderState {
  isEditingColorTheme: boolean;
  isMobileView: boolean;
  selectedCard: CardTypes | null;
  selectedElementTab: string;
  builderPages: BuilderPageDataType[];
  selectedPageId: number | null;
  commonElements: builderCommonElementsTypes;
  metaData: MetaData;
  generalSettings: GeneralSettings;
  funnelTheme: FunnelThemeType;
  currentlyEditingTheme: FunnelThemeType;
  draggingElement: DraggingElement;
  channelTemplates: ChannelTemplatesType;
  funnel: Funnel;
  loaders: Loaders;
  lastSaveSuccessful: boolean;
  oldLeadQualifier: OldLeadQualifierIncludingDeleteIds;
  shouldSaveFunnel: boolean;
  ui: {
    funnelIsEditingModalVisibility: boolean;
    completeFunnelSavingByUserInteractionDisabled: boolean;
    funnelSuggestionModalVisibility: boolean;
  };
  selectedBlockNodeIds: any[];
  checksums: BuilderStateChecksums;
  userColorThemes: FunnelThemeType[];
  funnelSuggestion: string;
  funnelPresets: FunnelPresetsType[];
  generalSettingsTabView: GeneralSettingsTabView;
  designSettingsTabView: DesignSettingsTabView;
  builderValidationErrors: BUILDER_VALIDATION_ERRORS[]
}

export interface BaseItem {
  index: number;
  onClickRoute: string;
  baseRoute: string;
  icon: any;
  label: string;
  tooltipTitle: string;
  isUserAllowed?: boolean;
  userIsNotAllowedMessage?: string;
  submenu?: any[];
  funnelPresets: FunnelPresetsType[];
}

export interface FunnelPresetsType {
  presetType: builderPresetElements;
  preset: { [key: string]: any };
  submenu?: any[];
}

export interface BaseItem {
  index: number;
  onClickRoute: string;
  baseRoute: string;
  icon: any;
  label: string;
  tooltipTitle: string;
  isUserAllowed?: boolean;
  userIsNotAllowedMessage?: string;
}

export interface Loaders {
  isFunnelSaved: boolean;
  mainFunnelLoader: boolean;
}

export interface OldChoice {
  id?: number;
  title?: string;
  craftJsNodeId: string;
  unit?: string;
}
export interface OldLeadQualifier {
  id?: number;
  question: string;
  type: LeadQualifierTypes;
  craftJsNodeId: string;
  choices: OldChoice[];
}

export interface OldLeadQualifierIncludingDeleteIds {
  leadQualifier: OldLeadQualifier[];
  leadQualifierIdsToDelete: number[];
  choiceIdsToDelete: number[];
}

export type LeadQualifierTypes = 'multiple' | 'radio' | 'range' | 'text';

export enum MetaDataSettingsFormFields {
  metaTitle = 'metaTitle',
  description = 'description',
  customMetaDataEnabled = 'customMetaDataEnabled',
  iconURL = 'iconURL',
  previewImageURL = 'previewImageURL'
}

export enum builderPresetElements {
  BUTTON = 'BUTTON',
  IMAGE = 'IMAGE',
  CAROUSEL = 'CAROUSEL',
  CHOICE = 'CHOICE'
}

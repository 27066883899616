export enum CRAFT_ELEMENTS {
  IMAGE = 'Image',
  RICH_TEXT = 'RichText',
  CRAFT_CTA = 'CraftCTA',
  CRAFT_OPTION_PICKER = 'CraftOptionPicker',
  COMPANY_CARD = 'CompanyCard',
  CRAFT_ADDRESS_PICKER = 'CraftAddressPicker',
  Craft_Range_Selector = 'CraftRangeSelector',
  Craft_Date_Picker = 'CraftDatePicker',
  BASIC_ALL_OPTION_PICKER = 'BasicAllOptionPicker'
}

export enum SAVEABLE_ELEMENTS {
  AD_TEXT_TEMPLATES = 'adTextTemplates',
  FUNNEL_SECTIONS = 'funnelSections'
}

export enum CRAFT_IDENTIFIER {
  SALARY = 'baseSalary',
  JOB_TITLE = 'jobTitle',
  JOB_TEASER = 'JOB_TEASER',
  MAIN_BODY = 'mainBody',
  JOB_ADDRESS = 'jobAddress',
  EmploymentType = 'employmentType',
  CATEGORY = 'category',
  DESCRIPTION = 'description',
  LOGO = 'logo',
  COMPANY_NAME = 'companyName',
  DIRECT_APPLY = 'directApply',
  QUALIFICATION = 'qualification',
  SKILLS = 'skills',
  RESPONSIBILITIES = 'responsibilities',
  EDUCATION = 'education',
  EXPERIENCE = 'experience',
  DATE_PICKER = 'datePicker',
  ADVANTAGES = 'ADVANTAGES',
  TASKS = 'TASKS',
  REQUIREMENTS = 'REQUIREMENTS',
  JOB_CTA = 'JOB_CTA',
  WORKPLACE_MODEL = 'workplaceModel'
}

import React from 'react';
import { Drawer, Skeleton } from 'antd';
import EventDetailContent, { PropsInterface } from './EventDetailContent';

function EventModal({ selectedEvent, setSelectedEvent, eventLoading,handleUpdateBookingEvents, ...rest }: PropsInterface) {
  return (
    <div className="events__event">
      <Drawer
        className="event-summary-modal"
        title="Zusammenfassung"
        visible={!!selectedEvent || eventLoading}
        onClose={() => setSelectedEvent(undefined)}
      >
        {eventLoading ? (
          <Skeleton active />
        ) : (
          selectedEvent && (
            <EventDetailContent
              {...rest}
              handleUpdateBookingEvents={handleUpdateBookingEvents}
              selectedEvent={selectedEvent}
              setSelectedEvent={setSelectedEvent}
            />
          )
        )}
      </Drawer>
    </div>
  );
}

export default EventModal;

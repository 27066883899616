import gql from 'graphql-tag';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';

export const GET_SINGLE_BOOKING_FILES = gql`
  query getSingleBooking($filter: SingleBookingInput!) {
    getSingleBooking(filter: $filter) {
      file {
        fileLink
        name
        expirationDate
      }
    }
  }
`;

export default function useSingleBookingFiles() {
  return useLazyQuery(GET_SINGLE_BOOKING_FILES);
}

export function getSingleBookingFiles(id: number) {
  return window.apolloClient.query({
    query: GET_SINGLE_BOOKING_FILES,
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        id
      }
    }
  });
}

export const GET_APPLICATION_SCHEDULE_MESSAGES = gql`
  query getApplicationMessages($input: ApplicationMessagesFilterQuery!) {
    getApplicationMessages(input: $input) {
      id
      createdAt
      processName
      executionTime
      isAlreadyScheduled
      payload
      updatedAt
      jobOptions
      retryCount
    }
  }
`;

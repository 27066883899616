import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch } from '../../redux/hooks';

import { BuilderBase, builderConfig } from '../config/builderUi';
import {
  resetToInitialBuilderState,
  setFunnelIsEditingModalVisibility,
  useFunnel,
  useFunnelIsEditingModalVisibility,
  useShouldSaveFunnel
} from '../redux/builderSlice';
import { getCompleteFunnel, saveCompleteBuilderDataThunk } from '../redux/thunk';
import { getAuthData } from '../../Login/container/Login';
import { getCompleteAdBuilder, saveAdBuilderStateThunk } from '../AdBuilder/redux/thunk';
import WarningModal from '../../SharedUI/components/WarningModal';
import { resetToInitialAdBuilderState, useShouldSaveAd } from '../AdBuilder/redux/adBuilderSlice';
import { returnUrlToMyFunnels } from '../../Funnel/redux/funnelSlice';
import { IconSidebarBase } from '../components/ui/IconSidebarBase';
import { getCurrentRoute } from '../helper/sharedFunctions';
import { getCompleteJobBuilder, saveJobBuilderStateThunk } from '../AdJobBuilder/redux/thunk';

const IconSidebar = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [token] = useState(getAuthData('token'));
  const funnel = useFunnel();
  const shouldSaveFunnel = useShouldSaveFunnel();
  const shouldSaveAd = useShouldSaveAd();
  const warningVisible = useFunnelIsEditingModalVisibility();
  const returnUrlToMyFunnelsLink = returnUrlToMyFunnels();
  const currentRoute = getCurrentRoute();

  const handleNavigate = async (item: BuilderBase) => {
    // !!! switching between ad builder and builder should be saved for safety reasens to make sure we should only route on succes
    if (currentRoute.includes('/builder')) {
      await dispatch(
        saveCompleteBuilderDataThunk({
          shouldOnlySaveEmailTemplates: !!currentRoute.includes('/builder/emails'),
          callbackOnSucces: () => {
            navigate(`${item?.onClickRoute}`, {
              state: { prevRoute: currentRoute }
            });
          }
        })
      );
      return;
    }

    if (currentRoute.includes('/google-job-builder')) {
      await dispatch(
        saveJobBuilderStateThunk({
          callbackOnSucces: () => {
            navigate(`${item?.onClickRoute}`, {
              state: { prevRoute: currentRoute }
            });
          }
        })
      );
      return;
    }

    if (currentRoute.includes('/ad-builder')) {
      await dispatch(
        saveAdBuilderStateThunk({
          callbackOnSucces: () => {
            navigate(`${item?.onClickRoute}`, {
              state: { prevRoute: currentRoute }
            });
          }
        })
      );

      return;
    }
  };

  useEffect(() => {
    (async () => {
      await dispatch(getCompleteFunnel());
      await dispatch(getCompleteJobBuilder());
      await dispatch(getCompleteAdBuilder());
    })();
  }, [token]);

  useEffect(() => {
    dispatch(setFunnelIsEditingModalVisibility(funnel?.isEditing));
  }, [funnel?.isEditing]);

  const handleLeaveFunnelOnIsEditing = () => {
    navigate(returnUrlToMyFunnelsLink);
    dispatch(resetToInitialBuilderState());
    dispatch(resetToInitialAdBuilderState());
  };
  return (
    <div className="sidebar-icons">
      {builderConfig.map((item, index) => {
        const isBuilderRouteAndCurrentRouteIsAdBuilder =
          currentRoute.includes('/builder') && item.onClickRoute.includes('/ad-builder');
        const isAdBuilderRouteAndCurrentRouteIsBuilder =
          currentRoute.includes('/ad-builder') && item.onClickRoute.includes('/builder');

        const isDisabled =
          (!shouldSaveFunnel || !shouldSaveAd) &&
          (isAdBuilderRouteAndCurrentRouteIsBuilder || isBuilderRouteAndCurrentRouteIsAdBuilder);

        return (
          <IconSidebarBase
            key={index}
            item={item}
            index={index}
            handleNavigate={handleNavigate}
            isDisabled={isDisabled}
          />
        );
      })}
      <WarningModal
        visible={warningVisible}
        toggleModal={() => dispatch(setFunnelIsEditingModalVisibility(!warningVisible))}
        preventToggleOnClickNo
        onYes={() => {
          dispatch(setFunnelIsEditingModalVisibility(false));
        }}
        yesButtonText={'Änderungen überschreiben'}
        onNo={handleLeaveFunnelOnIsEditing}
        noButtonText="Funnel verlassen"
        title="Jemand anderes bearbeitet diesen Funnel bereits"
        subTitle="Wenn du den Funnel jetzt bearbeitest, wirst du die Änderungen des anderen überschreiben."
        yesButtonProps={{ danger: true }}
        noButtonProps={{ type: 'primary' }}
      />
    </div>
  );
};

export default IconSidebar;

import React, { useEffect } from 'react';
import { Row, Col, Input, Switch, Form, Popover } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';
import BuilderSettingsTogglePro from '../sharedUI/BuilderSettingsTogglePro';
import { useAppDispatch } from '../../../redux/hooks';
import BuilderValidator from '../../container/BuilderValidator';
import { BuilderMetaDataSettingsMessages } from '../../../config/messages';
import ImageUploadV3, { CropperShape } from '../../../GeneralComponents/ImageUploadV3';
import { useBuilderMetaData } from '../../hooks/redux/useBuilderMetaDate';
import { editMetaDataSettings } from '../../hooks/redux/editMetaDataSettings';
import { MetaDataSettingsFormFields } from '../../interfaces/builderSliceTypes';
import { useSubscriptionInfo } from '../../../UI/redux/userSlice';

export const MetaDataSettingsFormRules = {
  title: [
    { required: true, message: 'Gib einen Titel.' },
    { max: 60, message: 'Der Wert darf nicht länger als 60 Zeichen sein.' }
  ],
  description: [{ required: true, message: 'Gib deinem Funnel eine Beschreibung.' }]
};

const MetaDataSettings = () => {
  const [form] = Form.useForm();
  const metaData = useBuilderMetaData();
  const editMetaData = editMetaDataSettings();
  const dispatch = useAppDispatch();
  const subscriptionInfo = useSubscriptionInfo();

  useEffect(() => {
    if (
      form.getFieldValue('metaTitle') !== metaData.metaTitle ||
      form.getFieldValue('description') !== metaData.description
    ) {
      form.setFieldsValue({
        metaTitle: metaData.metaTitle,
        description: metaData.description
      });
    }
  }, [metaData.metaTitle, metaData.description]);

  const changeMetaSettings = (key: MetaDataSettingsFormFields, value: string | boolean) => {
    dispatch(
      editMetaData({
        [key]: value
      })
    );
  };

  const BrandingSwitch = ({ disabled }: { disabled?: boolean }) => (
    <Switch
      size="small"
      disabled={disabled}
      checked={metaData.customMetaDataEnabled}
      onChange={checked => {
        changeMetaSettings(MetaDataSettingsFormFields.customMetaDataEnabled, checked);
      }}
    />
  );

  return (
    <BuilderValidator form={form}>
      <Form form={form} name="meta_data_form" layout="vertical" scrollToFirstError={true}>
        <Col span={24} className="builder__settings-sidebar__container meta-settings__container">
          <Row className="builder__settings-sidebar__row m-0">
            <Col span={24}>
              <BuilderSettingsTogglePro
                title="Aktiviert"
                classNames="border-top-0 pt-0 px-4 pb-3"
                rightContent={
                  subscriptionInfo?.brandingEnabled ? (
                    <Popover
                      className="default-popover"
                      content={
                        <div className="default-popover__content">
                          {BuilderMetaDataSettingsMessages.brandingEnabled}
                        </div>
                      }
                    >
                      <div>
                        <BrandingSwitch disabled={subscriptionInfo?.brandingEnabled} />
                      </div>
                    </Popover>
                  ) : (
                    <BrandingSwitch />
                  )
                }
              />
              {!metaData.customMetaDataEnabled && (
                <>
                  <p className="px-4 my-3">
                    Über die Meta-Daten kannst du den Titel sowie das Icon im Browsertab anpassen.
                    Wenn du deinen Funnel über Social-Media teilst, wird außerdem ein Bild sowie
                    eine Beschreibung geladen, diese kannst du hier ebenfalls anpassen.
                  </p>
                  <div className="px-4 mt-2 mb-4">
                    <img
                      src="/dashboard/funnel-builder/meta-settings-mini-mockup.jpg"
                      className="decent-border w-100"
                    />
                  </div>
                </>
              )}
            </Col>
            {metaData.customMetaDataEnabled && (
              <Row
                gutter={24}
                className={`disable-layer m-0 ${!metaData.customMetaDataEnabled &&
                  'disable-layer__disabled'}`}
              >
                <Col span={24} className="pt-4 px-4">
                  <Row>
                    <Col span={19}>
                      <BuilderSettingsTitle title="Titel" />
                      <Form.Item name="metaTitle" rules={MetaDataSettingsFormRules.title}>
                        <Input
                          className="mt-2"
                          value={metaData.metaTitle}
                          onChange={e => {
                            changeMetaSettings(
                              MetaDataSettingsFormFields.metaTitle,
                              e.target.value
                            );
                          }}
                          placeholder="Mein Recruiting Funnel"
                          maxLength={60}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4} offset="1">
                      <BuilderSettingsTitle title="Favicon" classNames="mb-2" />
                      <ImageUploadV3
                        previewImage={metaData.iconURL}
                        showRatioSelector={false}
                        onChange={url => {
                          changeMetaSettings(MetaDataSettingsFormFields.iconURL, url);
                        }}
                        minWidth={84}
                        minHeight={84}
                        quality={1}
                        className="meta-settings__favicon"
                        possibleAspectRatios={[]}
                        cropperIsNotMandatoryAfterSelectionFromLibrary={false}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col span={24} className="pt-1 px-4">
                  <BuilderSettingsTitle title="Beschreibung" />
                  <Form.Item name="description" rules={MetaDataSettingsFormRules.description}>
                    <TextArea
                      className="mt-2"
                      rows={4}
                      value={metaData.description}
                      onChange={e => {
                        changeMetaSettings(MetaDataSettingsFormFields.description, e.target.value);
                      }}
                      placeholder="Jetzt in 2 Min. deinen Traumjob finden."
                      maxLength={155}
                    />
                  </Form.Item>
                </Col>
                <Col span={24} className="pt-2 px-4">
                  <BuilderSettingsTitle title="Vorschaubild" />
                  <div className="mt-2">
                    <ImageUploadV3
                      previewImage={metaData.previewImageURL}
                      onChange={url => {
                        changeMetaSettings(MetaDataSettingsFormFields.previewImageURL, url);
                      }}
                      maxWidth={720}
                      minWidth={720}
                      minHeight={500}
                      uploadedImage
                      restrictPosition={false}
                      minZoom={1}
                      showRatioSelector={false}
                      shape={CropperShape.RECTANGLE}
                      cropperIsNotMandatoryAfterSelectionFromLibrary={false}
                    />
                  </div>
                </Col>
              </Row>
            )}
          </Row>
        </Col>
      </Form>
    </BuilderValidator>
  );
};

export default MetaDataSettings;

import { Tooltip } from 'antd';
import React from 'react';
import { tooltipText } from '../../helper/tooltipText';
import { BsFillGearFill } from 'react-icons/bs';
import { useEditor, useNode } from '@craftjs/core';
import { isBuilderInvalid } from '../../container/BuilderValidator';
import { handleSelectCraftElement } from '../../helper/craftJs';
import { trackInteraction } from '../../DebugTracking/utils/helper';
import useGeneralSettingsDataSave from '../../hooks/useGeneralSettingsDataSave';
import { TRACKINGS } from '../../DebugTracking/container/DebugTracker';

const SettingAction = () => {
  const { query, actions } = useEditor();
  const { handleGeneralSettingsDataSave } = useGeneralSettingsDataSave();
  const {
    name,
    openChildSettingsOnParentSettings,
    openParentSettingsOnChildSettings,
    nodeId
  } = useNode(node => {
    return {
      nodeId: node.id,

      name: node.data.custom.displayName || node.data.displayName,

      openChildSettingsOnParentSettings: node.data.props.openChildSettingsOnParentSettings,
      openParentSettingsOnChildSettings: node.data.props.openParentSettingsOnChildSettings
    };
  });

  const handleSwitchSettings = async (e: any) => {
    if (isBuilderInvalid()) return;

    handleSelectCraftElement({
      actions,
      nodeId,
      query,
      openChildSettingsOnParentSettings,
      openParentSettingsOnChildSettings
    });
    trackInteraction({
      type: 'FUNCTION_CALL',
      customEventName: TRACKINGS.HANDLE_SWITCH_SETTINGS_IN_RENDER_NODE,
      additionalData: { name, nodeId }
    });
    e.stopPropagation();
    await handleGeneralSettingsDataSave('2');
  };

  return (
    <Tooltip title={tooltipText.settings}>
      <li className="mr-2 cursor-pointer setting-icon" onClick={handleSwitchSettings}>
        <BsFillGearFill />
      </li>
    </Tooltip>
  );
};

export default SettingAction;

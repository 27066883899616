import React from 'react';
import { ActionsController } from '../../../components/sharedUI/ActionsController';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import { setGeneralSettingsTabView, useFunnel } from '../../../redux/builderSlice';
import { GeneralSettingsTabView } from '../../../../Builder/interfaces/builderSliceTypes';
import { useNavigate } from 'react-router';
import { useAppDispatch } from '../../../../redux/hooks';
import DefaultEmptyViewForSettingsPanel from '../../../components/sharedUI/DefaultEmptyViewForSettingsPanel';
import { SettingOutlined } from '@ant-design/icons';
import RenderContent from './renderContent';

export const BasicAllOptionPicker = ({ identifier, className, customValue , currentBuilderIdentifier }: any) => {
  const funnel = useFunnel();

  return (
    <ActionsController className={`${className} cursor-pointer`} data-testid="basicAllOptionPicker">
      {RenderContent({ funnel, identifier })}
    </ActionsController>
  );
};

const BasicAllOptionPickerSettings = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return (
    <DefaultEmptyViewForSettingsPanel
      iconProps={{ component: SettingOutlined }}
      title="Standardwerte definiert"
      description="Wechsle zu den Grundeinstellungen, um diese Anzupassen."
      buttonLabel="Grundeinstellungen"
      onButtonClick={() => {
        dispatch(setGeneralSettingsTabView(GeneralSettingsTabView.JOB_INFORMATION));
        navigate('/builder/grundeinstellungen');
      }}
    />
  );
};

BasicAllOptionPicker.craft = {
  name: CRAFT_ELEMENTS.BASIC_ALL_OPTION_PICKER,
  props: {},
  related: {
    settings: BasicAllOptionPickerSettings
  }
};

import { Col, Row, Slider } from 'antd';
import React, { PropsWithChildren } from 'react';
import BuilderSettingsTitle from '../../../sharedUI/BuilderSettingsTitle';
import { SliderProps, SliderValue } from 'antd/lib/slider';
import { JobAttributesIdentifier } from '../../../../AdJobBuilder/helper/AdJobHelper';

interface RangeSliderProps extends PropsWithChildren<SliderProps> {
  handleChange: (value: number[] | SliderValue, identifier: JobAttributesIdentifier) => void;
  identifier: JobAttributesIdentifier;
  title: string;
  value: any;
  range?: boolean;
}

export default function RangeSlider({
  handleChange,
  identifier,
  value,
  title,
  range,
  ...other
}: RangeSliderProps) {
  return (
    <Row className="builder__settings-sidebar__row">
      <Col span={24}>
        <BuilderSettingsTitle title={title} />
        <Slider
          range={range}
          onChange={value => {
            handleChange(value, identifier);
          }}
          value={value as SliderValue}
          {...other}
        />
      </Col>
    </Row>
  );
}

import { createAsyncThunk } from '@reduxjs/toolkit';
import { message } from 'antd';
import lz from 'lzutf8';
import {
  BuilderSettingsColorMessages,
  FunnelMessages,
  GeneralMessages
} from '../../config/messages';
import addFunnelPage from '../graphql/addFunnelPage';
import deleteFunnelPage from '../graphql/deleteFunnelPage';
import {
  getHashFromObject,
  getUrlQueryParamValue,
  shouldSaveOrShowMessage
} from '../helper/sharedFunctions';
import setPageTracking from '../graphql/setPageTracking';
import {
  BuilderPageDataType,
  BuilderState,
  BuilderStateChecksums,
  CHANNEL_TYPE,
  ChannelStateChecksums,
  FunnelThemeType,
  SaveCompleteBuilderThunkPayloadType,
  EmailTemplateType,
  MessagingTemplateTabType,
  MessagingAndEmailTempaltes
} from '../interfaces/builderSliceTypes';
import { fetchCompleteFunnel } from '../hooks/useGetCompleteFunnel';
import { CRAFT_ELEMENTS } from '../config/craftElements';
import {
  JSONtoCraft,
  appendCommonElementsToCraftState,
  craftToJSON,
  extractCommonElementsFromState,
  getEncodedBuilderPagesCalendarSettingsAndCommonElements,
  getOtherEmailAddressesToSendForInternalConfirmation,
  removeDuplicateNodeIdsFromAllPages,
  reportToSentryIfThereAreAnyDuplicateNodeIdsInAllPages
} from '../helper/craftJs';
import { addNewUserColorTheme, setMobileView, updateUserColorTheme } from './builderSlice';
import setPageCustomJsAPI from '../graphql/setPageCustomJS';
import updateFunnelPageAPI from '../graphql/updateFunnelPage';
import arrayMove from 'array-move';
import changeFunnelPageOrder from '../graphql/changeFunnelPageOrder';
import { trackInteraction } from '../DebugTracking/utils/helper';
import setPageNameAPI, { SetPageNameAPIInput } from '../graphql/setPageName';
import { setCalendarSettingsAPI } from '../graphql/setCalendarSettings';
import { AdBuilderStateType } from '../AdBuilder/redux/adBuilderSlice';
import setFunnelAndPagesAPI from '../graphql/setFunnelAndPages';
import { deleteUserFunnelThemeApi } from '../graphql/deleteUserFunnelTheme';
import { saveChannelTemplatesTabContent } from '../helper/saveEmailTemplateTabContent';
import duplicateFunnelPageAPI from '../graphql/duplicateFunnelPage';
import { handleUpdateFunnelInSlice } from '../../Funnel/redux/funnelSlice';
import { customHtmlToDraft } from '../../helper/draftHelper';
import { EditorState } from 'draft-js';
import { handleSaveTheme } from '../../Connect/utils/handleSaveTheme';
import { thankyouPageType } from '../helper/defaultValues';
import { orderedEmailTemplates } from '../helper/elementTemplate';
import getFunnelPresets from '../graphql/getFunnelPresets';
import setFunnelPresets from '../graphql/setFunnelPresets';
import { Funnel } from '../../types/funnel';
import { JobAttributesIdentifier } from '../AdJobBuilder/helper/AdJobHelper';
import { fetchMessageTemplates } from '../hooks/useGetMessageTemplates';

export const deleteFunnelPageThunk = createAsyncThunk(
  'delete-funnel-page',
  async (data: { id: number }, { getState, dispatch }) => {
    const { builderUI } = getState() as { builderUI: BuilderState };
    const shouldSave = shouldSaveOrShowMessage(builderUI.shouldSaveFunnel);

    if (!shouldSave) {
      throw GeneralMessages.error;
    }

    try {
      await deleteFunnelPage({ id: data.id });
      return data.id;
    } catch (error) {
      message.error(GeneralMessages.error);
      throw error;
    }
  }
);

export const addFunnelPageThunk = createAsyncThunk(
  'add-funnel-page',
  async (data: any, { rejectWithValue, getState }) => {
    try {
      const { query, ...newPageData } = data;
      const { builderUI } = getState() as { builderUI: BuilderState };

      const newState = appendCommonElementsToCraftState(
        builderUI.commonElements,
        newPageData.craftState
      );

      const shouldSave = shouldSaveOrShowMessage(builderUI.shouldSaveFunnel);
      if (shouldSave) {
        trackInteraction({
          type: 'REDUX_THUNK',
          customEventName: 'ADD_FUNNEL_PAGE_THUNK_IN_BUILDER_SLICE'
        });

        const res = await addFunnelPage({
          ...newPageData,
          craftState: newState
        });

        return res.data.createFunnelPage;
      } else {
        return rejectWithValue('Error');
      }
    } catch (err) {
      message.error(GeneralMessages.error);
      return rejectWithValue(err);
    }
  }
);

export const duplicatePageThunk = createAsyncThunk(
  'duplicate-funnel-page',
  async (data: { id: number }, { rejectWithValue, getState, dispatch }) => {
    try {
      const { id } = data;
      const { builderUI } = getState() as { builderUI: BuilderState };

      if (!shouldSaveOrShowMessage(builderUI.shouldSaveFunnel)) return;

      await dispatch(saveCompleteBuilderDataThunk());

      const { data: response } = await duplicateFunnelPageAPI(id);

      return { page: response.duplicateFunnelPage };
    } catch (err) {
      message.error(GeneralMessages.error);
      return rejectWithValue(err);
    }
  }
);

export const setPageTrackingThunk = createAsyncThunk(
  'set-page-tracking',
  async (data: any, thunkAPI) => {
    const { builderUI } = thunkAPI.getState() as { builderUI: BuilderState };
    const shouldSave = shouldSaveOrShowMessage(builderUI.shouldSaveFunnel);

    if (!shouldSave) return {};

    try {
      await setPageTracking(data);
      return;
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const setPageNameThunk = createAsyncThunk(
  'set-page-name',
  async (data: SetPageNameAPIInput, thunkAPI) => {
    const { builderUI } = thunkAPI.getState() as { builderUI: BuilderState };
    const shouldSave = shouldSaveOrShowMessage(builderUI.shouldSaveFunnel);

    if (!shouldSave) return {};

    try {
      await setPageNameAPI(data);
      return;
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const setPageCustomJs = createAsyncThunk(
  'set-page-custom-js',
  async (data: any, thunkAPI) => {
    const { builderUI } = thunkAPI.getState() as { builderUI: BuilderState };
    const shouldSave = shouldSaveOrShowMessage(builderUI.shouldSaveFunnel);

    if (!shouldSave) return {};

    try {
      await setPageCustomJsAPI(data);
      return;
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const updateFunnelPageThunk = createAsyncThunk(
  'update-funnel-page',
  async (data: any, thunkAPI) => {
    const { builderUI } = thunkAPI.getState() as { builderUI: BuilderState };
    const shouldSave = shouldSaveOrShowMessage(builderUI.shouldSaveFunnel);

    if (!shouldSave) return {};

    try {
      trackInteraction({
        type: 'REDUX_THUNK',
        customEventName: 'UPDATE_FUNNEL_PAGE_THUNK_IN_BUILDER_SLICE',
        additionalData: {
          data
        }
      });

      await updateFunnelPageAPI(data);
      return;
    } catch (err) {
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const getCompleteFunnel = createAsyncThunk(
  'get-complete-funnel',
  async (_, { dispatch }): Promise<any> => {
    try {
      const { data }: any = await fetchCompleteFunnel();
      const mTData = await fetchMessageTemplates();

      dispatch(
        setMobileView({
          payload: true
        })
      );

      let calenderFound = false;
      let contactFormFound = false;
      let fileUploadFound = false;
      data.getFunnelPages?.forEach((page: BuilderPageDataType) => {
        const craftState = JSON.parse(lz.decompress(lz.decodeBase64(page.craftState)));
        Object.keys(craftState).forEach(key => {
          switch (craftState[key].displayName) {
            case CRAFT_ELEMENTS.CALENDAR:
              calenderFound = true;
              break;
            case CRAFT_ELEMENTS.UPLOAD_FILE:
              fileUploadFound = true;
              break;
            case CRAFT_ELEMENTS.CONTACT_FORM:
              contactFormFound = true;
              break;
          }
        });
      });
      let sortedPages = JSON.parse(JSON.stringify(data.getFunnelPages)).sort(
        (a: BuilderPageDataType, b: BuilderPageDataType) => a.pageOrder - b.pageOrder
      );

      sortedPages = sortedPages.map((page: BuilderPageDataType) => {
        let cState = craftToJSON(page.craftState);
        return { ...page, craftState: JSONtoCraft(cState) };
      });

      // Note: Funnel will be properly fixed only if page gets a chance to be save after it. It could be done by changing page or just closing a funnel.
      if (localStorage.getItem('fixJSONCraftStateOfAllPages')) {
        sortedPages = removeDuplicateNodeIdsFromAllPages(sortedPages);
      }

      reportToSentryIfThereAreAnyDuplicateNodeIdsInAllPages(sortedPages);

      sortedPages.push(
        sortedPages.splice(
          sortedPages.findIndex(
            (element: BuilderPageDataType) => element.type === thankyouPageType
          ),
          1
        )[0]
      );

      // SEPRATING HEADER AND FOOTER
      const commonElements = extractCommonElementsFromState(data.getFunnelPages[0].craftState);

      trackInteraction({
        type: 'REDUX_THUNK',
        customEventName: 'GET_COMPLETE_FUNNEL_THUNK_IN_BUILDER_SLICE',
        additionalData: {
          comporessedPages: data.getFunnelPages
        }
      });

      const generalSettings = {
        uniqueIdentifier: data.getFunnel.uniqueIdentifier,
        title: data.getFunnel.title,
        language: data.getFunnel.language,
        brandingEnabled: data.getFunnel.brandingEnabled,
        cookieBanner: data.getFunnel.cookieBanner
      };

      const metaData = {
        metaTitle: data.getFunnel.metaTitle,
        description: data.getFunnel.description,
        customMetaDataEnabled: data.getFunnel.customMetaDataEnabled,
        iconURL: data.getFunnel.iconURL,
        previewImageURL: data.getFunnel.previewImageURL
      };

      const funnel = {
        id: data.getFunnel.id,
        uniqueIdentifier: data.getFunnel.uniqueIdentifier,
        builderVersion: data.getFunnel.builderVersion,
        previewImage: data.getFunnel.previewImageV2URL,
        domain: data.getFunnel.domain,
        isEditing: data.getFunnel.isEditing,
        jobTitle: data.getFunnel.jobTitle,
        companyName: data.getFunnel.companyName,
        category: data.getFunnel.category,
        employmentType: data.getFunnel.employmentType,
        education: data.getFunnel.education,
        baseSalary: data.getFunnel.baseSalary,
        experience: data.getFunnel.experience,
        experienceDuration: data.getFunnel.experienceDuration,
        jobAddress: data.getFunnel.jobAddress,
        salaryPlan: data.getFunnel.salaryPlan,
        workplaceModel: data.getFunnel.workplaceModel
      };

      const otherEmailAddressesToSend = getOtherEmailAddressesToSendForInternalConfirmation({
        emailTemplates: data.getEmailTemplates
      });
      const { data: initialFunnelPresets } = await getFunnelPresets();
      const funnelPresets = initialFunnelPresets.getFunnelPresets.map((pre: any) => ({
        ...pre,
        preset: JSON.parse(pre?.preset)
      }));

      const checksums: BuilderStateChecksums = {
        builderPages: await getHashFromObject(sortedPages),
        generalSettings: await getHashFromObject(generalSettings),
        metaData: await getHashFromObject(metaData),
        funnel: await getHashFromObject(funnel),
        funnelTheme: await getHashFromObject(data.getFunnelColorTheme),
        otherEmailAddressesToSend: await getHashFromObject({ otherEmailAddressesToSend }),
        calendarSettings: null,
        funnelPresets: await getHashFromObject(funnelPresets)
      };
      const selectedPageId = getUrlQueryParamValue('selectedPageId');
      const emailTemplates = orderedEmailTemplates(data.getEmailTemplates);

      const channelData = emailTemplates?.map((emailTemp: EmailTemplateType) => {
        const messagingTemplateData = mTData.find(
          (messagingTemp: MessagingTemplateTabType) => messagingTemp.type === emailTemp.type
        );
        return {
          sendingChannel:
            emailTemp.enabled || !messagingTemplateData || !messagingTemplateData?.enabled
              ? CHANNEL_TYPE.EMAIL
              : CHANNEL_TYPE.MESSAGE,
          emailTemplate: {
            ...emailTemp,
            html: EditorState.createWithContent(customHtmlToDraft(emailTemp.html))
          },
          messagingTemplate: { ...messagingTemplateData },
          channelEnabled: emailTemp.enabled || messagingTemplateData?.enabled
        };
      });

      const MessageTemplateData = {
        senderName: channelData[0].messagingTemplate.companyName,
        messageNumber: channelData[0].messagingTemplate.contactNumber,
        phoneNumber: channelData[0].messagingTemplate.contactPersonCallNumber,
        sender: channelData[0].emailTemplate.emailSenderName,
        brandingEnabled: channelData[0].emailTemplate.brandingEnabled,
        activeTab: '0',
        templates: channelData
      };
      return {
        isCalendarEnabled: calenderFound,
        isContactFormEnabled: contactFormFound,
        isFileUploaderEnabled: fileUploadFound,
        pages: sortedPages,
        commonElements,
        generalSettings,
        funnelTheme: data.getFunnelColorTheme,
        metaData,
        funnel,
        channelTemplates: MessageTemplateData,
        oldLeadQualifier: data.getLeadQualifier,
        checksums,
        userColorThemes: data.getUserColorThemes,
        selectedPageId: selectedPageId ? +selectedPageId : undefined,
        funnelPresets: funnelPresets
      };
    } catch (err) {
      message.error(GeneralMessages.error);
      location.replace('/dashboard/meine-funnels');
      throw new Error(GeneralMessages.error);
    }
  }
);

export const reOrderBuilderPagesThunk = createAsyncThunk(
  'reorder-funnel-pages',
  async ({ oldIndex, newIndex }: any, { getState, rejectWithValue }) => {
    try {
      const { builderUI } = getState() as { builderUI: BuilderState };
      const { shouldSaveFunnel, builderPages: bp } = builderUI;

      if (!shouldSaveFunnel) return;

      const builderPages = JSON.parse(JSON.stringify(bp)) as BuilderPageDataType[];

      let pages = builderPages.filter(_ => _.type !== thankyouPageType);
      pages = arrayMove(pages, oldIndex, newIndex).map((_, i) => ({ ..._, pageOrder: i }));

      const sortedPages = pages.map((page, index) => ({ id: page.id, pageOrder: index }));
      const thankyouPage = builderPages.find(_ => _.type === thankyouPageType);

      trackInteraction({
        type: 'REDUX_THUNK',
        customEventName: 'REORDER_BUILDER_PAGES_THUNK_IN_BUILDER_SLICE',
        additionalData: {
          sortedPages,
          thankyouPage
        }
      });

      await changeFunnelPageOrder(sortedPages);

      return [...pages, thankyouPage];
    } catch (error) {
      message.error(GeneralMessages.error);
      return rejectWithValue(error);
    }
  }
);

export const saveCompleteBuilderDataThunk = createAsyncThunk(
  'save-complete-data',
  async (
    data: undefined | SaveCompleteBuilderThunkPayloadType,
    { getState, rejectWithValue, dispatch }
  ) => {
    try {
      const { shouldOnlySaveEmailTemplates, callbackOnSucces } = data || {};
      const { builderUI: state } = getState() as {
        builderUI: BuilderState;
      };

      const shouldSaveFunnel = shouldSaveOrShowMessage(state.shouldSaveFunnel);

      if (!shouldSaveFunnel) return;

      const {
        currentEmailTemplatesGeneralSettingChecksum,
        currentEmailTemplateActiveTabContentChecksum,
        currentOtherEmailAddressesToSendChecksum,
        currentMessagingTemplatesGeneralSettingChecksum,
        currentMessagingTemplatesChecksum
      } = await saveChannelTemplatesTabContent({ state });

      // we need this in case the builder is being initialised on email tab. Otherwise it will cause an issue because craft is not initialise
      if (shouldOnlySaveEmailTemplates) {
        const newChecksums: ChannelStateChecksums = {
          emailTemplatesGeneralSettings: currentEmailTemplatesGeneralSettingChecksum,
          emailTemplateActiveTabContent: currentEmailTemplateActiveTabContentChecksum,
          otherEmailAddressesToSend: currentOtherEmailAddressesToSendChecksum,
          messagingTemplates: currentMessagingTemplatesChecksum,
          messagingTemplatesGeneralSettings: currentMessagingTemplatesGeneralSettingChecksum
        };

        if (callbackOnSucces) callbackOnSucces();

        return {
          checksums: newChecksums
        };
      }

      const {
        builderPages,
        calendarSettings
      } = getEncodedBuilderPagesCalendarSettingsAndCommonElements({
        builderPages: state.builderPages,
        selectedPageId: state.selectedPageId,
        oldTheme: state.funnelTheme,
        newTheme: state.currentlyEditingTheme,
        isEditingColorTheme: state.isEditingColorTheme
      });

      const currentBuilderPagesChecksum = await getHashFromObject(builderPages);
      const currentGeneralSettingsChecksum = await getHashFromObject(state.generalSettings);
      const currentMetaDataChecksum = await getHashFromObject(state.metaData);
      const currentFunnelChecksum = await getHashFromObject(state.funnel);
      const currentCalendarSettingsChecksum = await getHashFromObject(calendarSettings);
      const currentFunnelThemeChecksum = await getHashFromObject(state.currentlyEditingTheme);
      const currentPresetChecksum = await getHashFromObject(state.funnelPresets);

      const shouldUpdateBuilderPages = !!(
        state.checksums.builderPages !== currentBuilderPagesChecksum || !currentBuilderPagesChecksum
      );
      const shouldUpdateGeneralSettings = !!(
        state.checksums.generalSettings !== currentGeneralSettingsChecksum ||
        !currentGeneralSettingsChecksum
      );
      const shouldUpdateMetaData = !!(
        state.checksums.metaData !== currentMetaDataChecksum || !currentMetaDataChecksum
      );
      const shouldUpdateFunnel = !!(
        state.checksums.funnel !== currentFunnelChecksum || !currentFunnelChecksum
      );
      const shouldUpdateFunnelTheme = !!(
        state.checksums.funnelTheme !== currentFunnelThemeChecksum || !currentFunnelThemeChecksum
      );
      const shouldUpdateFunnelPresets = !!(
        state.checksums.funnelPresets !== currentPresetChecksum || !currentPresetChecksum
      );

      // in these two cases we dont only need to check the checksum because it can happen that calendarSettings or ad is not provided
      const shouldUpdateCalendarSettings = !!(
        (state.checksums.calendarSettings !== currentCalendarSettingsChecksum ||
          !currentCalendarSettingsChecksum) &&
        calendarSettings
      );

      if (calendarSettings && shouldUpdateCalendarSettings) {
        const {
          bookingRangeInHours,
          durationInMinutes,
          maxBookingsPerDay,
          shifts,
          slotBufferInMinutes,
          specialClosures
        } = calendarSettings;

        await setCalendarSettingsAPI({
          shifts,
          specialClosures,
          bookingSettings: {
            bookingRangeInHours,
            maxBookingsPerDay,
            slotBufferInMinutes,
            durationInMinutes
          }
        });
      }

      const shouldUpdateFunnelAndPages =
        shouldUpdateBuilderPages ||
        shouldUpdateGeneralSettings ||
        shouldUpdateMetaData ||
        shouldUpdateFunnel ||
        shouldUpdateCalendarSettings;

      if (shouldUpdateFunnelPresets) {
        await setFunnelPresets(
          state.funnelPresets?.map((pre: any) => ({
            presetType: pre?.presetType,
            preset: JSON.stringify(pre.preset)
          }))
        );
      }

      if (shouldUpdateFunnelAndPages) {
        let jobSettings: any = {};
        Object.values(JobAttributesIdentifier).forEach((key: string) => {
          jobSettings[key] = JSON.stringify((state.funnel as any)[key as keyof Funnel]);
        });
        dispatch(
          handleUpdateFunnelInSlice({
            title: state.generalSettings.title as string,
            funnelUniqueIdentifier: state.generalSettings.uniqueIdentifier as string,
            funnelId: state.funnel.id
          })
        );
        await setFunnelAndPagesAPI({
          pages: builderPages,
          funnelData: {
            generalSettings: state.generalSettings,
            metaData: state.metaData,
            calendarSettings: calendarSettings
              ? {
                  calendarId: calendarSettings?.calendarId,
                  calendarBlockMode: (calendarSettings?.busyStatus && 'opaque') || 'all'
                }
              : {}
          },
          jobSettings
        });
      }

      if (shouldUpdateFunnelTheme) {
        await handleSaveTheme({
          oldTheme: state.funnelTheme,
          newTheme: state.currentlyEditingTheme,
          dispatch
        });
      }

      const newChecksums: BuilderStateChecksums = {
        builderPages: currentBuilderPagesChecksum,
        generalSettings: currentGeneralSettingsChecksum,
        metaData: currentMetaDataChecksum,
        funnel: currentFunnelChecksum,
        calendarSettings: currentCalendarSettingsChecksum,
        funnelTheme: currentFunnelThemeChecksum,
        emailTemplatesGeneralSettings: currentEmailTemplatesGeneralSettingChecksum,
        emailTemplateActiveTabContent: currentEmailTemplateActiveTabContentChecksum,
        otherEmailAddressesToSend: currentOtherEmailAddressesToSendChecksum,
        messagingTemplates: currentMessagingTemplatesChecksum,
        funnelPresets: currentPresetChecksum,
        messagingTemplatesGeneralSettings: currentMessagingTemplatesGeneralSettingChecksum
      };

      if (callbackOnSucces) callbackOnSucces();

      trackInteraction({
        type: 'REDUX_THUNK',
        customEventName: 'SAVE_COMPLETE_BUILDER_DATA_THUNK',
        additionalData: {
          shouldSaveData: {
            shouldUpdateBuilderPages,
            shouldUpdateGeneralSettings,
            shouldUpdateMetaData,
            shouldUpdateFunnel,
            shouldUpdateFunnelTheme,
            shouldUpdateCalendarSettings,
            shouldUpdateFunnelAndPages
          },
          builderPages
        }
      });

      return {
        checksums: newChecksums
      };
    } catch (error) {
      const errorMessage: string = (error as any)?.message?.replace('GraphQL error: ', '');
      const thisError = FunnelMessages.apiErrors[errorMessage];
      if (thisError) message.error(thisError, 8);
      else message.error(GeneralMessages.error);
      return rejectWithValue(error);
    }
  }
);

export const deleteUserFunnelThemeThunk = createAsyncThunk(
  'deletes-user-funnel-theme',
  async (_, { rejectWithValue, getState }) => {
    try {
      const {
        builderUI: { funnelTheme, currentlyEditingTheme, shouldSaveFunnel }
      } = getState() as { builderUI: BuilderState; adBuilderUI: AdBuilderStateType };

      if (!shouldSaveOrShowMessage(shouldSaveFunnel)) return;

      await deleteUserFunnelThemeApi(currentlyEditingTheme.id);
      message.success(BuilderSettingsColorMessages.saveSuccessfull);
      return currentlyEditingTheme;
    } catch (error) {
      message.error(BuilderSettingsColorMessages.couldNotSsave);
      return rejectWithValue(error);
    }
  }
);

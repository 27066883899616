import React, { useEffect, useRef, useState } from 'react';
import { FileDrop } from 'react-file-drop';
import gql from 'graphql-tag';
import { Alert, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import {
  IconProvider
  // @ts-ignore
} from 'meetovo-frontend-booking';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Button, message } from 'antd';
import { acceptedFormats, uploadFileOptions as options } from '../../helper/uploadFile';
import { uploadFileMessage } from '../../../config/messages';
import { useSubscriptionInfo } from '../../../UI/redux/userSlice';

export const UPLOAD_FILE = gql`
  mutation($files: [FileData!]!, $areSignedUrlsRequired: Boolean) {
    uploadFile(input: $files, areSignedUrlsRequired: $areSignedUrlsRequired) {
      id
      name
      fileType
      fileLink
      expirationDate
      awsKey
      signedUrl
    }
  }
`;

export const defaultExpiry = new Date().setMonth(new Date().getMonth() + 3); // 3 months

const UploadFile = ({
  accept = acceptedFormats,
  multiple = true,
  selectedEvent,
  innerText,
  refetch
}: any) => {
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = useRef(null);

  const [uploadFiles] = useMutation(UPLOAD_FILE);

  const subscriptionInfo = useSubscriptionInfo();

  useEffect(() => {}, [subscriptionInfo, selectedEvent]);

  const labels = accept.map((mimeType: any) => {
    const type = options.find((t: any) => t.value === mimeType);
    return type ? type.label : 'unknown';
  });

  const text = innerText || labels.join(', ');

  const maxSize = subscriptionInfo?.fileSizeLimitPerUploadInMB;

  const onFileInputChange = (inputFiles: FileList | null): void => {
    if (inputFiles) {
      const tempFileList: File[] = [];
      Array.from(inputFiles).forEach(file => {
        if (file.size >= maxSize * 1024 * 1024) {
          message.error(uploadFileMessage.uploadFileSizeErrorMessage(maxSize));
        } else if (!accept.includes(file.type)) {
          message.error(uploadFileMessage.uploadFileTypeErrorMessage(text ?? ''));
        } else {
          tempFileList.push(file);
        }
      });

      if (!tempFileList.length) {
        return;
      }

      if (!multiple) {
        setFiles([tempFileList[0]]);
        return;
      }
      setFiles([...files, ...tempFileList]);
    }
  };

  const handleSubmit = async (files: File[], language = 'EN'): Promise<void> => {
    if (files.length === 0) {
      message.error(uploadFileMessage.uploadFileErrorMessage);
      return;
    }

    try {
      setLoading(true);

      const filesData = files.map(file => ({
        file,
        name: file.name,
        expirationDate: new Date(defaultExpiry),
        bookingId: selectedEvent?.event?.id
      }));

      await uploadFiles({
        variables: {
          files: filesData,
          areSignedUrlsRequired: true
        }
      });
      setLoading(false);
      message.success(uploadFileMessage.uploadSuccessfulMessage);
      refetch();
      setFiles([]);
    } catch (error) {
      console.error(error);
      message.error(uploadFileMessage.uploadFileSizeGeneralMessage);
      setLoading(false);
    }
  };

  function shortenFileName(fileName: string, maxLength: number): string {
    const lastDotIndex = fileName.lastIndexOf('.');
    const name = fileName.substring(0, lastDotIndex);
    const extension = fileName.substring(lastDotIndex);
    const nameLength = name.length;

    if (nameLength + extension.length <= maxLength) {
      return fileName;
    }

    const portionToKeep = Math.floor((maxLength - 4 - extension.length) / 2);
    return name.substr(0, portionToKeep) + ' ... ' + name.substr(-portionToKeep) + extension;
  }

  const handleRemove = (index: number): void => {
    setFiles(files.filter((_, i) => i !== index));
  };

  const onTargetClick = (): void => {
    // @ts-expect-error
    fileInputRef?.current?.click();
  };
  return (
    <div className=" upload-file__wrapper">
      <div className=" d-flex align-items-center flex-column">
        <FileDrop
          onTargetClick={onTargetClick}
          onDrop={(files: any, event: any) => {
            onFileInputChange(files);
          }}
        >
          <div className="upload-container" style={{ borderColor: '#1e266d' }}>
            <div className="uploader" style={{ borderColor: '#1e266d', color: 'grey' }}>
              <IconProvider
                name={'FaCloudUploadAlt'}
                style={{ fontSize: `3em`, color: '#1e266d' }}
              />
              <span>{uploadFileMessage.uploadMessage}</span>
              <span>
                max. {maxSize} MB, {text}
              </span>
            </div>
          </div>
          <input
            onChange={e => {
              onFileInputChange(e.target.files);
              e.target.value = '';
            }}
            ref={fileInputRef}
            type="file"
            accept={accept.join(', ')}
            className="d-none"
            multiple={!!multiple}
            max={maxSize * 1024 * 1024 || 5242880} // 5MB
          />
        </FileDrop>

        <div className="d-flex justify-content-between flex-wrap mt-2">
          {files.map((file, index) => (
            <Card
              className="rounded p-1"
              key={file.name}
              style={{ background: 'white', color: '#1e266d', border: '1px solid #1e266d' }}
            >
              <Card.Body className="p-0 d-flex justify-content-between align-items-center">
                <span className="text-truncate ps-1">{shortenFileName(file.name, 20)}</span>
                <OverlayTrigger
                  placement="top"
                  overlay={<Tooltip id={`remove-tooltip-${index}`}>Löschen</Tooltip>}
                >
                  <Button className="p-0 m-1" type="link" onClick={() => handleRemove(index)}>
                    <IconProvider name="MdCancel" color={'red'} style={{ color: 'red' }} />
                  </Button>
                </OverlayTrigger>
              </Card.Body>
            </Card>
          ))}
        </div>

        {!!files.length && (
          <Button
            className="m-3 rounded"
            type="primary"
            onClick={() => {
              void (async () => {
                await handleSubmit(files);
              })();
            }}
            loading={loading}
          >
            Hochladen
          </Button>
        )}
      </div>
    </div>
  );
};
export default UploadFile;

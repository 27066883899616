import React, { useEffect } from 'react';
import { replaceTitleAndIcon } from '../../CustomerLogin/helper/replaceTitleAndIcon';
import useAgencyDashboard from '../../hooks/useAgencyDashboard';

const AgencyDashboardBrandedMetaData = () => {
  const { data: agencyDashboardData } = useAgencyDashboard();
  const { logoURL, title, faviconURL } = agencyDashboardData?.getAgencyDashboard || {};

  useEffect(() => {
    replaceTitleAndIcon({ title, faviconURL });
  }, [logoURL, title]);

  return null;
};

export default AgencyDashboardBrandedMetaData;

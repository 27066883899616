import React, { useEffect, useState } from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { getAuthData } from '../../Login/container/Login';
import { useAppSelector } from '../../redux/hooks';
import { selectUserData, useSubscriptionInfo, useSubscriptionInfoLoading } from '../../UI/redux/userSlice';
import {
  getMixPanelEventExistingTrackingData,
  mixpanelEvents,
  saveMixpanelEventOccurrence
} from '../../UI/utils/mixpanel';
import mixpanel from 'mixpanel-browser';

declare global {
  interface Window {
    $crisp: any;
    CRISP_WEBSITE_ID: string;
    CRISP_TOKEN_ID: string;
    CRISP_RUNTIME_CONFIG: {};
    CRISP_READY_TRIGGER(): any;
  }
}

export const GET_CHAT_INFORMATION = gql`
  query {
    getUser {
      id
      name
      email
      crispTokenId
    }
  }
`;

export const handleOpenSupportChat = () => {
  if (window.$crisp) {
    window.$crisp.push(['do', 'chat:open']);
    window.$crisp.push(['do', 'chat:show']);
  }
};

function SupportChat({
  anonymAllowed,
  onlyScript,
  onReady
}: {
  anonymAllowed?: boolean;
  onlyScript?: boolean;
  onReady?(): any;
}) {
  const localUserData = useAppSelector(selectUserData);
  const isAgencyCustomer = localUserData?.role === 'AGENCY_CUSTOMER';

  const [getChatInformation, { data, loading: chatInfoLoading, error }] = useLazyQuery(GET_CHAT_INFORMATION);

  const isAdmin = !!getAuthData('isAdmin');

  const { getUser: user } = data || {};

  const subscriptionInfo = useSubscriptionInfo();
  const subscriptionInfoLoading = useSubscriptionInfoLoading()

  const loading = chatInfoLoading || subscriptionInfoLoading;

  if (!anonymAllowed && user) window.CRISP_TOKEN_ID = user.crispTokenId;

  const initChat = () => {
    window.$crisp = [];
    window.CRISP_WEBSITE_ID = '564fa468-dea4-4238-987d-cc5d96d7eccc';
    window.CRISP_RUNTIME_CONFIG = {
      // session_merge: true,
      locale: 'de'
    };

    (function() {
      const d = document;
      const s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';
      s.async = true;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  };

  useEffect(() => {
    if (!anonymAllowed) getChatInformation();
  }, []);

  useEffect(() => {
    if ((!user && !anonymAllowed) || isAdmin || isAgencyCustomer) return;

    const crispInitialized = document.querySelector('.crisp-client');

    if (!crispInitialized) initChat();

    window.CRISP_READY_TRIGGER = function() {
      window.$crisp.push(['do', 'chat:hide']);

      if (!anonymAllowed) {
        const crispDataWithValues = [
          ['user__coach-id', user.id],
          ['subscription-info__status', subscriptionInfo.status],
          ['subscription-info__paddle-unit-price', subscriptionInfo.paddle_unit_price],
          [
            'subscription-info__paddle-subscription-plan-id',
            subscriptionInfo.paddle_subscription_plan_id
          ],
          ['subscription-info__max-funnels', subscriptionInfo.maxFunnels],
          ['subscription-info__remaining-funnels', subscriptionInfo.remainingFunnels],
          ['subscription-info__max-visits', subscriptionInfo.maxVisits],
          ['subscription-info__remaining-visits', subscriptionInfo.remainingVisits],
          ['subscription-info__max-domains', subscriptionInfo.maxDomains],
          ['subscription-info__remaining-domains', subscriptionInfo.remainingDomains]
        ].reduce((acc, data) => (data[1] ? [...acc, data] : acc), []);

        window.$crisp.push(['set', 'user:nickname', [user.name]]);
        window.$crisp.push(['set', 'user:email', [user.email]]);
        window.$crisp.push(['set', 'session:data', [crispDataWithValues]]);
        window.$crisp.push([
          'on',
          'message:sent',
          () => {
            if (!getMixPanelEventExistingTrackingData(mixpanelEvents.SEND_MESSAGE_TO_SUPPORT)) {
              mixpanel.track(mixpanelEvents.SEND_MESSAGE_TO_SUPPORT);
              saveMixpanelEventOccurrence(mixpanelEvents.SEND_MESSAGE_TO_SUPPORT);
            }
          }
        ]);
      }

      onReady && onReady();
    };
  }, [user, subscriptionInfo]);

  if (onlyScript || isAgencyCustomer) return null;

  return (
    <div
      className="support-info"
      onClick={(loading || error) && !anonymAllowed ? () => null : handleOpenSupportChat}
    >
      <div className="support-info__label">
        <InfoCircleOutlined /> <b>Support?</b>{' '}
      </div>
      <span className="text-light text-secondary">Mo.-Fr. 10:30 - 18:30 Uhr</span>
    </div>
  );
}

export default SupportChat;

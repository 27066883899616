import React from 'react';
import moment from 'moment';
import { Button, DatePicker, Modal } from 'antd';
import SMSPreview from '../../../Builder/components/ui/SMSPreview';
import dateFormats from '../../../config/dateFormats';

interface PropTypes {
  isOpen: boolean;
  handleClose: () => void;
  item: {
    payload: string;
    processName: string;
    executionTime?: string;
  };
  onCancelMessage: (item: any) => void;
  onRescheduleMessage: (item: any, date: string) => void;
}

const CommunicationDetailModal: React.FC<PropTypes> = ({ isOpen, item, handleClose, onCancelMessage, onRescheduleMessage }) => {
  const parseMessage = (payload: string) => JSON.parse(payload);
  const isFutureDate = (date?: string) => date && moment(date).isAfter(moment());

  const disabledDate = (current: any) => current && current < moment().startOf('day');

  const datePickerComponent = (executionTime?: string) => (
    <DatePicker
      format={dateFormats.dateTime}
      defaultValue={moment(executionTime, 'YYYY-MM-DD hh:mm A') as any}
      onChange={date => onRescheduleMessage(item, date?.format("YYYY-MM-DDTHH:mm:ss.SSS[Z]") || '')}
      showTime={{ use12Hours: true }}
      disabledDate={disabledDate}
    />
  );

  const cancelButton = () => (
    <Button type="default" danger onClick={() => onCancelMessage(item)}>
      Nachricht löschen
    </Button>
  );

  const previewEmail = () => {
    let message = parseMessage(item?.payload);
    return (
      <div className="modalContainer">
        <div className="detailFlexContainer">
          <div>
            <div>Von: {message?.from}</div>
            <div>An: {message?.to}</div>
            <div>Betreff: {message?.subject}</div>
          </div>
          <div className="detailSection">
            {isFutureDate(item?.executionTime) ? (
              <>
                {datePickerComponent(item?.executionTime)}
                <div className="topSpacing">{cancelButton()}</div>
              </>
            ) : (
              item?.executionTime && (
                <div className="sentDateDisplay">
                  {'Gesendet am: ' + moment(item?.executionTime).format(dateFormats.dateTime)}
                </div>)
            )
            }
          </div>
        </div>
        <div className="previewContent" dangerouslySetInnerHTML={{ __html: message?.html }} />
      </div>
    );
  };

  const previewMessage = () => {
    let message = parseMessage(item?.payload);
    return (
      <div className="modalContainer">
        <div className="detailFlexContainer">
          <div>
            {
              message?.messageProps?.customerName ? (
                <>
                  <div>Von: Sender-ID {'<Bewerbung>'}</div>
                  <div>Name: {message?.messageProps?.customerName}</div>
                  <div>Nummer: {message?.messageProps?.contactPersonCallNumber}</div>
                </>
              ) : null
            }
          </div>
          <div className="detailSection">
            {isFutureDate(item?.executionTime) ? (
              <>
                {datePickerComponent(item?.executionTime)}
                <div className="topSpacing">{cancelButton()}</div>
              </>
            ) : <div className="sentDateDisplay">
              {item?.executionTime ? ('Gesendet am: ' + moment(item?.executionTime).format(dateFormats.dateTime)) : ''}
            </div>}
          </div>
        </div>
        <SMSPreview template={message?.html || message?.content} time={item?.executionTime} name={message?.messageProps?.customerName} />
      </div>
    );
  };

  return (
    <Modal
      title="Nachricht"
      visible={isOpen}
      onOk={handleClose}
      onCancel={handleClose}
      footer={null}
      width={800}
    >
      {['sendEmailWithRetry', 'EMAIL'].includes(item.processName) ? previewEmail() : null}
      {['sendMessageWithRetry', 'SMS'].includes(item.processName) ? previewMessage() : null}
    </Modal>
  );
};

export default CommunicationDetailModal;
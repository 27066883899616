import React from 'react';
import { useLocation } from 'react-router';
import routes from '../routes';
import { availableLayouts, layout } from '../utils/routeTypes';

const UIController = () => {
  const { pathname } = useLocation();
  let route = routes.find(({ path }) => pathname.includes(path));
  const Tag = layout[route ? route.layout : availableLayouts.UI];
  return <Tag />;
};

export default UIController;

import React from 'react';
import { Button } from 'antd';
import { FaRedoAlt, FaUndoAlt } from 'react-icons/fa';
import useFunnelUndoRedo from '../../helper/useFunnelUndoRedo';
import { trackInteraction } from '../../DebugTracking/utils/helper';
import { TRACKINGS } from '../../DebugTracking/container/DebugTracker';

const UndoRedoButtons = () => {
  const { canUndo, canRedo, actions, canUndoAndRedo } = useFunnelUndoRedo();

  const handleUndo = () => {
    trackInteraction({
      type: 'FUNCTION_CALL',
      customEventName: TRACKINGS.HANDLE_UNDO_IN_UNDO_REDO_BUTTONS
    });

    actions.history.undo();
  };
  const handleRedo = () => {
    trackInteraction({
      type: 'FUNCTION_CALL',
      customEventName: TRACKINGS.HANDLE_REDO_IN_UNDO_REDO_BUTTONS
    });

    actions.history.redo();
  };

  return (
    <>
      {canUndoAndRedo && (
        <div className="undo-redo-buttons pe-2">
          <Button
            onClick={handleUndo}
            shape="circle"
            type="link"
            disabled={!canUndo}
            icon={<FaUndoAlt />}
          />
          <Button
            shape="circle"
            type="link"
            disabled={!canRedo}
            icon={<FaRedoAlt />}
            onClick={handleRedo}
          />
        </div>
      )}
    </>
  );
};

export default UndoRedoButtons;

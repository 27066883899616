import { createAsyncThunk } from '@reduxjs/toolkit';
import { getHashFromObject } from '../../helper/sharedFunctions';
import { message } from 'antd';
import { GeneralMessages } from '../../../config/messages';
import { useInternalEditorReturnType } from '@craftjs/core/lib/editor/useInternalEditor';
import { JSONtoCraft, useCraftSerializedState } from '../../helper/craftJs';
import { Delete } from 'craftjs-utils-meetovo';
import {
  WidgetBuilderStateChecksums,
  WidgetBuilderStateType
} from '../interfaces/widgetSliceTypes';
import { fetchCompleteWidget } from '../hooks/useGetCompleteWidget';
import setWidgetHub from '../graphql/setWidgetHub';
import {
  setCurrentlyEditingTheme,
  setFunnelTheme,
  setMobileView,
  setUserColorTheme
} from '../../redux/builderSlice';
import { BuilderState } from '../../interfaces/builderSliceTypes';
import { mutateUserColorThemeAPI } from '../../graphql/mutateUserColorTheme';
import { getFunnelByIdForJobInfo } from '../../hooks/useGetAllFunnels';
import { WidgetMessages } from '../config/messages';

export const getCompleteWidgetBuilder = createAsyncThunk(
  'get-complete-widget-builder',
  async (widgetId: undefined | number, { getState, dispatch, rejectWithValue }) => {
    try {
      dispatch(
        setMobileView({
          payload: false
        })
      );
      const { data } = await fetchCompleteWidget(Number(widgetId));
      const { getWidgetHubById, getUserColorThemes } = data;

      const colorThemes = getWidgetHubById.funnelColorTheme;
      delete getWidgetHubById.funnelColorTheme;

      const checksums: WidgetBuilderStateChecksums = {
        WidgetTheme: await getHashFromObject(getWidgetHubById),
        widget: await getHashFromObject(getWidgetHubById)
      };

      dispatch(setUserColorTheme(getUserColorThemes));
      dispatch(setFunnelTheme(colorThemes));
      dispatch(setCurrentlyEditingTheme(colorThemes));

      return {
        checksums,
        widget: getWidgetHubById
      };
    } catch (err) {
      message.error(GeneralMessages.error);
      return rejectWithValue(err);
    }
  }
);

export const saveWidgetBuilderStateThunk = createAsyncThunk(
  'save-widget-builder-state',
  async (
    args:
      | undefined
      | {
          query?: Delete<useInternalEditorReturnType<any>['query'], 'deserialize'>;
          callbackOnSucces?(): void;
        },
    thunkAPI
  ) => {
    let { query, callbackOnSucces } = args || {};
    const { widgetBuilderUI: state, builderUI } = thunkAPI.getState() as {
      widgetBuilderUI: WidgetBuilderStateType;
      builderUI: BuilderState;
    };

    try {
      query =
        query ||
        (window.craftJsAdBuilderQuery as Delete<
          useInternalEditorReturnType<any>['query'],
          'deserialize'
        >);

      const json = useCraftSerializedState(query);

      const funnelIds: any = Object.values(json)
        .filter((el: any) => {
          return el?.displayName === 'WidgetTiles';
        })
        .map((el: any) => el?.props?.funnelId)
        ?.filter((value: any, index: number, array: any) => array.indexOf(value) === index);

      const encodedJson = JSONtoCraft(json);

      const newWidgetState = { ...state.widget, craftState: encodedJson };
      const currentWidgetThemeChecksum = await getHashFromObject(builderUI.currentlyEditingTheme);
      const currentWidgetChecksums = await getHashFromObject(newWidgetState);

      const shouldUpdateWidgetThem = state.checksums.WidgetTheme != currentWidgetThemeChecksum;
      const shouldUpdateWidget = currentWidgetChecksums !== state.checksums.widget;

      const checksums: WidgetBuilderStateChecksums = {
        WidgetTheme: await getHashFromObject(builderUI.currentlyEditingTheme),
        widget: await getHashFromObject(newWidgetState)
      };

      if (shouldUpdateWidget) {
        await setWidgetHub(newWidgetState, funnelIds);
      }

      if (shouldUpdateWidgetThem) {
        await mutateUserColorThemeAPI(builderUI.currentlyEditingTheme);
      }

      if (callbackOnSucces) callbackOnSucces();

      return {
        craftState: encodedJson,
        checksums: checksums
      };
    } catch (err) {
      const error: any = err;
      if (error?.message.includes('duplicate')) {
        message.error(WidgetMessages.createWidgetError);
        return thunkAPI.rejectWithValue(err);
      }
      message.error(GeneralMessages.error);
      return thunkAPI.rejectWithValue(err);
    }
  }
);

export const linkFunnelToWidget = createAsyncThunk(
  'link-funnels-to-widget',
  async ({ funnelId }: { widgetId?: number; funnelId?: number }, { rejectWithValue }) => {
    try {
      const { data } = await getFunnelByIdForJobInfo(funnelId as number);
      const funnelData = data.getFunnelById;
      return {
        funnelData
      };
    } catch (err) {
      message.error(GeneralMessages.error);
      return rejectWithValue(err);
    }
  }
);

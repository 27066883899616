import { Col, Collapse, Form, Input, Row } from 'antd';
import React, { useEffect } from 'react';
import BuilderSettingsTitle from '../../sharedUI/BuilderSettingsTitle';
import AddressPicker from './components/AddressPicker';
import { JobAttributesIdentifier } from '../../../AdJobBuilder/helper/AdJobHelper';
import OptionsPicker from './components/OptionsPicker';
import {
  editFunnelSavedIndicator,
  setJobGeneralSettings,
  useFunnel
} from '../../../redux/builderSlice';
import { useAppDispatch } from '../../../../redux/hooks';
import {
  JobAttributeOption,
  getJobOptions,
  getOptionsValuesInList
} from '../../../AdJobBuilder/helper/AdJobHelper';
import ChoicePicker from './components/ChoicePicker';
import RangeSlider from './components/RangeSlider';
import CategoryPicker from './components/CategoryPicker';
import BuilderValidator from '../../../container/BuilderValidator';
import { defaultStringFieldValidation } from '../../../../UI/utils/formValidation';
import { formatSalary } from '../../../AdJobBuilder/helper/validateFunnelJobPresets';

const { Panel } = Collapse;

export default function BuilderJobGeneralSettings() {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const {
    jobTitle,
    companyName,
    jobAddress,
    employmentType,
    category,
    education,
    baseSalary,
    experience,
    experienceDuration,
    salaryPlan,
    workplaceModel
  } = useFunnel() || {};

  const handleChange = (value: any, identifier: JobAttributesIdentifier) => {
    if (!identifier || value === undefined) return;
    dispatch(editFunnelSavedIndicator(false));
    dispatch(setJobGeneralSettings({ value, identifier }));
  };

  const areFormFieldsValueDifferent =
    form.getFieldValue('jobTitle') !== jobTitle ||
    form.getFieldValue('category') !== category ||
    form.getFieldValue('companyName') !== companyName;

  useEffect(() => {
    if (areFormFieldsValueDifferent) {
      form.setFieldsValue({
        jobTitle,
        category,
        companyName
      });
    }
  }, [jobTitle, category, companyName]);

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Row className="builder__settings-sidebar__row">
        <Col span={24} className="px-4">
          <BuilderValidator form={form}>
            <Form
              name="general_settings_form"
              layout="vertical"
              form={form}
              scrollToFirstError={true}
              initialValues={{
                jobTitle,
                category
              }}
            >
              <Form.Item name="jobTitle" rules={[defaultStringFieldValidation[0]]}>
                <BuilderSettingsTitle classNames='settings-title__required' title="Formaler Titel" />
                <Input
                  placeholder="z.B. Software-Entwickler (m/w/d) für Schnittstellen und Datenbank"
                  value={jobTitle}
                  maxLength={60}
                  onChange={e => {
                    handleChange(e.target.value, JobAttributesIdentifier.JOB_TITLE);
                  }}
                />
              </Form.Item>
              <BuilderSettingsTitle title="Firmenname" classNames='settings-title__required' />
              <Form.Item name="companyName" rules={[defaultStringFieldValidation[0]]}>
                <Input
                  placeholder="z.B. Muster GmbH"
                  value={companyName}
                  maxLength={60}
                  onChange={e => {
                    handleChange(e.target.value, JobAttributesIdentifier.COMPANY_NAME);
                  }}
                />
              </Form.Item>
              <Collapse
                className="mt-4 w-100"
                accordion
                expandIconPosition="right"
                bordered={false}
              >
                <Panel
                  header={
                    <div>
                      <h6 className='settings-title__required'>Kategorie</h6>
                      <p className="m-0">
                        {getOptionsValuesInList(JobAttributesIdentifier.CATEGORY, category)}
                      </p>
                    </div>
                  }
                  key={1}
                >
                  <Form.Item
                    name="category"
                    rules={[{ required: true, message: 'Kategorie ist erforderlich.' }]}
                    getValueFromEvent={value => {
                      handleChange(value, JobAttributesIdentifier.CATEGORY);
                      return value;
                    }}
                  >
                    <CategoryPicker
                      value={category}
                      options={getJobOptions(JobAttributesIdentifier.CATEGORY).options}
                    />
                  </Form.Item>
                </Panel>

                <Panel
                  header={
                    <div>
                      <h6 className='settings-title__required'>Beschäftigungsart</h6>
                      <p className="m-0">
                        {getOptionsValuesInList(
                          JobAttributesIdentifier.EMPLOYMENT_TYPE,
                          employmentType
                        )}
                      </p>
                    </div>
                  }
                  key={2}
                >
                  <OptionsPicker
                    options={getJobOptions(JobAttributesIdentifier.EMPLOYMENT_TYPE).options}
                    handleChange={handleChange}
                    identifier={JobAttributesIdentifier.EMPLOYMENT_TYPE}
                    value={employmentType}
                  />
                </Panel>

                <Panel
                  header={
                    <div>
                      <h6 className='settings-title__required'>Bildungsgrad</h6>
                      <p className="m-0">
                        {getOptionsValuesInList(JobAttributesIdentifier.EDUCATION, education)}
                      </p>
                    </div>
                  }
                  key={3}
                >
                  <OptionsPicker
                    options={getJobOptions(JobAttributesIdentifier.EDUCATION).options}
                    handleChange={handleChange}
                    identifier={JobAttributesIdentifier.EDUCATION}
                    value={education}
                  />
                </Panel>
                <Panel
                  header={
                    <div>
                      <h6 className='settings-title__required'>Gehalt</h6>
                      <p className="m-0">
                        {`Gehalt ${formatSalary(baseSalary[0])}€ - ${formatSalary(
                          baseSalary[1]
                        )}€ ${salaryPlan}`}
                      </p>
                    </div>
                  }
                  key={4}
                >
                  <RangeSlider
                    title={'Gehalt'}
                    identifier={JobAttributesIdentifier.SALARY}
                    handleChange={handleChange}
                    value={baseSalary}
                    max={baseSalary[1] + 100}
                    range
                    step={50}
                  />
                  <ChoicePicker
                    title='Einheit'
                    handleChange={handleChange}
                    value={salaryPlan}
                    options={['Monatlich', 'Jährlich']}
                    identifier={JobAttributesIdentifier.SALARY_PLAN}
                  />
                </Panel>

                <Panel
                  header={
                    <div>
                      <h6 className='settings-title__required'>Erfahrung</h6>
                      <p className="m-0">{`Erfahrung ${experience} ${experienceDuration}`}</p>
                    </div>
                  }
                  key={5}
                >
                  <RangeSlider
                    title={'Erfahrung'}
                    identifier={JobAttributesIdentifier.EXPERIENCE}
                    handleChange={handleChange}
                    value={experience}
                    max={30}
                  />
                  <ChoicePicker
                    title='Einheit'
                    handleChange={handleChange}
                    value={experienceDuration}
                    options={['Monate', 'Jahre']}
                    identifier={JobAttributesIdentifier.EXPERIENCE_DURATION}
                  />
                </Panel>
                <Panel
                  header={
                    <div>
                      <h6 className='settings-title__required'>Arbeitsplatzmodell</h6>
                      <p className="m-0">
                        {getOptionsValuesInList(JobAttributesIdentifier.WORKPLACE_MODEL, [
                          workplaceModel
                        ])}
                      </p>
                    </div>
                  }
                  key={6}
                >
                  <OptionsPicker
                    options={getJobOptions(JobAttributesIdentifier.WORKPLACE_MODEL).options}
                    handleChange={handleChange}
                    identifier={JobAttributesIdentifier.WORKPLACE_MODEL}
                    value={workplaceModel}
                  />
                </Panel>
                <Panel
                  header={
                    <div>
                      <h6 className='settings-title__required'>Ort</h6>
                      <p className="m-0">{jobAddress.place || 'Wähle einen Standort'}</p>
                    </div>
                  }
                  key={7}
                >
                  <AddressPicker
                    handleChange={handleChange}
                    identifier={JobAttributesIdentifier.JOB_ADDRESS}
                    value={jobAddress}
                  />
                </Panel>
              </Collapse>
            </Form>
          </BuilderValidator>
        </Col>
      </Row>
      <Row className="builder__settings-sidebar__row m-0">
        <Col span={24}>
          <>
            <p className="px-4 my-3">
              Um Job-Informationen an verschiedenen Orten wie z.B. Google Jobs & Widgets automatsch
              einzusetzen, lege diese hier fest.
            </p>
            <div className="px-4 mt-2 mb-4">
              <img
                src="/dashboard/funnel-builder/job-attributes-mockup.jpg"
                className="decent-border w-100"
              />
            </div>
          </>
        </Col>
      </Row>
    </Col>
  );
}

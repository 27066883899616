import { Col, Row } from 'antd';
import React from 'react';
import { JobAttributesIdentifier } from '../../../../AdJobBuilder/helper/AdJobHelper';
interface OptionsPickerProps {
  handleChange: (value: any, identifier: JobAttributesIdentifier) => void;
  value: any;
  options: any[];
  identifier: JobAttributesIdentifier;
}

export default function OptionsPicker({
  options,
  handleChange,
  identifier,
  value
}: OptionsPickerProps) {
  const handleClick = (itemValue: string) => {
    let newValue;
    if (Array.isArray(value)) {
      if (value.includes(itemValue)) {
        if (value.length <= 1) return;
        newValue = value.filter(val => val !== itemValue);
      } else {
        newValue = [...value, itemValue];
      }
    } else {
      newValue = itemValue;
    }
    handleChange(newValue, identifier);
  };

  return (
    <Row className="me-3">
      {options?.map((item: any) => {
        return (
          <Col span={7} offset={1} onClick={() => handleClick(item.value)} className="mb-2">
            <div className={`job-options ${value.includes(item.value) && 'selected-category'}`}>
              {item.icon && <div className="category-icon">{item.icon}</div>}
              {item.label}
            </div>
          </Col>
        );
      })}
    </Row>
  );
}

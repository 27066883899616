import gql from 'graphql-tag';
import { useLazyQuery, useQuery } from '@apollo/react-hooks';

export const GET_SINGLE_BOOKINGS = gql`
  query getSingleBooking($filter: SingleBookingInput!) {
    getSingleBooking(filter: $filter) {
      id
      name
      firstName
      lastName
      email
      phone
      generalPurpose
      eventStart
      eventEnd
      createdAt
      funnelId
      utmParameter
      version
      choices {
        choiceId
        leadQualifierId
        stringValue
        numberValue
        voiceMessageAWSKey
      }
      bookingStatus {
        color
        status
        value
        id
      }
      file {
        fileLink
        name
        expirationDate
      }
      bookingActionEvent {
        id
        actionEventType
        eventTime
      }
    }
  }
`;

export default function useSingleBooking() {
  return useLazyQuery(GET_SINGLE_BOOKINGS);
}

export function getSingleBooking(id: number) {
  return window.apolloClient.query({
    query: GET_SINGLE_BOOKINGS,
    fetchPolicy: 'no-cache',
    variables: {
      filter: {
        id
      }
    }
  });
}

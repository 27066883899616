import { Alert, Card, Col, Row, Slider } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';
import { FaTextHeight } from 'react-icons/fa';
import EmojiPicker from '../../../GeneralComponents/EmojiPicker';
import BuilderColorPickerButton from '../sharedUI/BuilderColorPickerButton';
import FontStyleButtons from '../../../SharedUI/components/FontStyleButtons';
import TextAlignmentButtons from '../../../SharedUI/components/TextAlignmentButtons';
import { getKeyByValue, getValueByKey } from '../../helper/craftJs';
import { insertCharacterToEditor } from '../../helper/sharedFunctions';
import { FontAvailableSizes, defaultFontSize } from '../../interfaces/FontSizeInterface';
import { useEditor, useNode } from '@craftjs/core';
import { useCurrentlyEditingBuilderTheme } from '../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { EditorState, RichUtils } from 'draft-js';
import { RichTextComponentMessages } from '../../../config/messages';


const RichTextSetting = () => {
  const {
    actions: { setProp },
    props,
    nodeId
  } = useNode(node => ({
    nodeId: node.id,
    props: node.data.props
  }));
  const { actions } = useEditor();
  const marks = {
    1: 'S',
    2: 'M',
    3: 'L',
    4: 'XL'
  };
  const themeColors = useCurrentlyEditingBuilderTheme();
  const [emojiPickerCollapsed, setEmojiPickerCollapsedState] = useState(true);

  const { editorState, styles } = props;

  const onChange = (editorState: EditorState) => {
    actions.history.throttle().setProp(nodeId, (props: any) => (props.editorState = editorState));
  };

  function _toggleInlineStyle(inlineStyle: string) {
    onChange(RichUtils.toggleInlineStyle(editorState, inlineStyle));
  }

  function _toggleBlockType(blockType: string) {
    onChange(RichUtils.toggleBlockType(editorState, blockType));
  }
  const selection = editorState.getSelection();
  const currentStyle = editorState.getCurrentInlineStyle();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const editorStateRef = useRef(editorState);

  useEffect(() => {
    editorStateRef.current = editorState;
  }, [editorState]);
  return (
    <>
      <Col span={24} className="builder__settings-sidebar__container">
        <Card className="settings-card" title="Text" bordered={false}>
          <Row className="builder__settings-sidebar__row">
            <Col span={24}>
              <BuilderSettingsTitle title="Schriftgröße" icon={<FaTextHeight />} />
              <Slider
                className="builder-slider-style"
                marks={marks}
                min={1}
                max={4}
                step={1}
                tooltipVisible={false}
                defaultValue={defaultFontSize.key}
                value={
                  getKeyByValue(
                    styles?.fontSize?.current(editorState)?.split('em')[0] || '1',
                    FontAvailableSizes
                  ).key
                }
                onChange={(value: any) => {
                  let newEditorState = styles.fontSize.toggle(
                    editorState,
                    `${getValueByKey(value, FontAvailableSizes).value}em`
                  );
                  onChange(newEditorState);
                }}
              />
            </Col>
          </Row>
          <Row className="builder__settings-sidebar__row">
            <Col span={11}>
              <BuilderSettingsTitle title="Ausrichtung" />
              <TextAlignmentButtons align={blockType} disabled={props.alignmentDisabled} onChange={_toggleBlockType} />
            </Col>
            <Col span={11} offset={2}>
              <BuilderSettingsTitle title="Style" />
              <FontStyleButtons
                hasStyle={style => currentStyle.has(style)}
                onChange={_toggleInlineStyle}
              />
            </Col>
          </Row>
          <Row className="builder__settings-sidebar__row">
            <Col span={11}>
              <BuilderSettingsTitle title="Farbe" />
              <BuilderColorPickerButton
                color={styles.color.current(editorState) || themeColors.textColor}
                onChange={(textColor: any) => {
                  const newEditorState = styles.color.toggle(editorState, textColor);
                  onChange(newEditorState);
                }}
              />
            </Col>
            <Col span={11} offset={2}></Col>
          </Row>
          <Row className="builder__settings-sidebar__row">
            <Col span={24}>
              <BuilderSettingsTitle
                title="😊 Emoji"
                onCollapseToggleClick={value => setEmojiPickerCollapsedState(value)}
                collapsed={emojiPickerCollapsed}
              />
              {!emojiPickerCollapsed && (
                <EmojiPicker
                  height={'500px'}
                  emojiSize={30}
                  onChange={value => {
                    setProp(
                      (props: any) =>
                        (props.editorState = insertCharacterToEditor(value, editorStateRef.current))
                    );
                  }}
                />
              )}
            </Col>
          </Row>
          <Row className="builder__settings-sidebar__row">
            <Col span={24}>
              <Alert
                message={RichTextComponentMessages.changeFontFamilyInDesignSettings}
                type="info"
                showIcon
              />
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
};

export default RichTextSetting;

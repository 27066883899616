import { useNode } from '@craftjs/core';
import { Collapse } from 'antd';
import { ContentState, EditorState } from 'draft-js';
import React, { useState } from 'react';
import { TemplateContent, TemplateType, getTextTemplateById } from '../../../../AdBuilder/data';
import SelectTemplateCard from './SelectTemplateCard';
import SwitchTemplate from './SwitchTemplate';
import { useFunnel } from '../../../../redux/builderSlice';

import { replaceJobPlaceHoldersWithValues } from '../../../../AdJobBuilder/helper/AdJobHelper';
const { Panel } = Collapse;

export interface TextSuggestionTypes {
  userTemplate: TemplateContent[];
  content: TemplateType;
  handleDelete?: any;
}

const TextSuggestionSidebar = ({ userTemplate, content, handleDelete }: TextSuggestionTypes) => {
  const funnel = useFunnel();
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));

  const { elementType, templateId } = props;
  const [makeVisible, setMakeVisible] = useState(false);
  const [template, setTemplate] = useState<any>();

  const handleSelectTemplate = (temp: { id: number; text: string; name?: string }) => {
    let template;

    template = userTemplate.find(_ => _.id === props.templateId);
    if (!template) {
      template = getTextTemplateById(props.templateId, content);
    }
    const replacedPlaceHoldersText = replaceJobPlaceHoldersWithValues(temp, funnel);
    if (
      template &&
      props.isEdited
    ) {
      setMakeVisible(true);
      setTemplate(replacedPlaceHoldersText);
    } else {
      setProp((props: any) => {
        const newState = EditorState.createWithContent(
          ContentState.createFromText(replacedPlaceHoldersText.text)
        );
        props.editorState = newState;
        props.templateId = temp.id;
      });
    }
  };

  return (
    <div className="ad-suggestion__container builder__settings-sidebar__container">
      <SwitchTemplate
        template={template}
        direction="NEXT"
        makeModalOpen={makeVisible}
        userTemplate={userTemplate}
        content={content}
        onChange={() => {
          setMakeVisible(false);
        }}
      />
      <label className="builder__settings-sidebar__container__simple-title">{content.title}</label>
      <div className="builder__settings-sidebar__row">
        <Collapse accordion>
          {content.data.map(({ category, content }) => {
            return (
              <Panel header={category} key={category}>
                {content.map((template, i) => {
                  return (
                    <SelectTemplateCard
                      key={template.text}
                      handelSelect={() => handleSelectTemplate(template)}
                      template={template}
                      currentTemplateId={templateId}
                    />
                  );
                })}
              </Panel>
            );
          })}
          <Panel header="Eigene Vorlagen" key={'custom'}>
            {userTemplate.map((template, i) => {
              return (
                <SelectTemplateCard
                  key={template.id}
                  handelSelect={() => handleSelectTemplate(template)}
                  template={template}
                  currentTemplateId={templateId}
                  deleteLoading={false}
                  handleDelete={() => handleDelete(template.id)}
                />
              );
            })}
            {userTemplate.length === 0 && (
              <p className="p-3">
                Es sind keine eigenen Templates vorhanden. Klicke auf die Diskette über einem Text
                um ihn zu Speichern.
              </p>
            )}
          </Panel>
        </Collapse>
      </div>
    </div>
  );
};

export default TextSuggestionSidebar;

import React from 'react';
import { Button, Card, Alert } from 'antd';
import { LoadingOutlined, UnlockOutlined } from '@ant-design/icons';

import LoginForm from '../components/LoginForm';
import { makeStyles } from '../utils/makeStyles';
import { logoURL as defaultLogoURL } from '../../UI/utils/imageConstants';
import useAgencyDashboard from '../../hooks/useAgencyDashboard';
import { useNavigate } from 'react-router';

const CustomerLogin = ({ onLogin }: { onLogin: Function }) => {
  const navigate = useNavigate();
  const { data, loading, error } = useAgencyDashboard();
  const { logoURL, primaryColor, secondaryColor } = data?.getAgencyDashboard || {};

  if (error) {
    return (
      <Alert
        message="Ooops, da ist etwas schief gelaufen 😬"
        description={
          <>
            Dieses Agency-Dashboard ist nicht verfügbar. Bitte wende dich an deine Agentur.
            <Button
              onClick={() => {
                navigate('/login');
                onLogin();
              }}
              style={{ display: 'block', marginTop: '1em' }}
            >
              <UnlockOutlined />
              Zum Login
            </Button>
          </>
        }
        type="warning"
      />
    );
  }

  if (loading) {
    return <LoadingOutlined className="centered" spin />;
  }

  return (
    <>
      <style>{makeStyles(primaryColor || '#1e266d', secondaryColor || '#23d3d3')}</style>
      <div className="customer-login--wrapper">
        <Card>
          <img className="agency-logo" src={logoURL || defaultLogoURL} />
          <LoginForm onLogin={onLogin} />
        </Card>
      </div>
    </>
  );
};

export default CustomerLogin;

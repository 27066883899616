import React from 'react';
import { Row, Col, Tabs, Popover, message } from 'antd';
import { cardsWithCategory, CardTypes } from '../config/builderUi';
import {
  editDraggingElement,
  setSelectedElementTab,
  useBuilderSelectedPageData
} from '../redux/builderSlice';
import { useAppDispatch } from '../../redux/hooks';

import BuilderCardThumb from '../components/sharedUI/BuilderCardThumb';
import { SettingsPanel } from '../components/sharedUI/SettingsPanel';
import { useNavigate } from 'react-router';
import { FaChevronLeft } from 'react-icons/fa';
import { isElementExistInFunnel } from '../helper/elementTemplate';
import { useEditor, useNode } from '@craftjs/core';
import { ElementTemplateMessages, UserInteractingComponentsMessages } from '../../config/messages';
import { StopOutlined } from '@ant-design/icons';
import { disabledElementsOnThankYouPage } from '../config/craftElements';
import { DraggingFrom } from '../interfaces/builderSliceTypes';
import { useSelectedElementTab } from '../hooks/useSelectedElementTab';
import { thankyouPageType } from '../helper/defaultValues';

const { TabPane } = Tabs;

const ElementTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectedElementTab = useSelectedElementTab();
  const { checkElementExistInFunnel } = useEditor((_, query) => {
    return { checkElementExistInFunnel: (key: string) => isElementExistInFunnel(query, key) };
  });

  const selectedPage = useBuilderSelectedPageData();

  const callback = (key: string) => {
    if (key === '0') return;

    dispatch(
      setSelectedElementTab({
        payload: key
      })
    );
  };

  const IsDisabledInCaseOfThankYouPage = (cardInfo: CardTypes) =>
    selectedPage?.type === thankyouPageType &&
    disabledElementsOnThankYouPage.includes(cardInfo.key || '');
  return (
    <Tabs
      className="tab-container-style builder__settings-sidebar__container"
      activeKey={selectedElementTab}
      onChange={callback}
    >
      <TabPane
        tab={
          <button
            className="icon-btn default-opacity-hover"
            onClick={() => {
              navigate('/builder/bearbeiten');
            }}
          >
            <FaChevronLeft />
          </button>
        }
        className="single-tab-container"
        key="0"
      />
      <TabPane tab="Elemente" className="single-tab-container" key="1">
        {cardsWithCategory.map(({ category, elements }, index) => (
          <div className="element-card__wrapper" key={index}>
            <label className="small-label">{category}</label>
            <Row>
              {elements.map((cardInfo, index) => {
                const disabled =
                  (cardInfo.key && checkElementExistInFunnel(cardInfo.key)) ||
                  IsDisabledInCaseOfThankYouPage(cardInfo);
                return (
                  <Col
                    className={`element-card ${disabled && 'element-card__disabled'}`}
                    key={index}
                    span={12}
                    onClick={() => !disabled && message.info(ElementTemplateMessages.youNeedToDrag)}
                    onDragStart={e => {
                      dispatch(
                        editDraggingElement({
                          name: cardInfo.key || null,
                          draggingFrom: DraggingFrom.ACTION_CONTROLLER,
                          displayName: ''
                        })
                      );
                    }}
                    onDragEnd={e => {
                      dispatch(editDraggingElement({ name: null }));
                    }}
                  >
                    <Popover
                      {...(!disabled && { visible: false })}
                      content={
                        <div className="default-popover__content">
                          <p>{UserInteractingComponentsMessages.singleUsage}</p>
                        </div>
                      }
                      title={() => (
                        <p className="default-popover__title">
                          <StopOutlined style={{ color: 'red' }} />{' '}
                          {UserInteractingComponentsMessages.popoverTitle}
                        </p>
                      )}
                    >
                      <div>
                        <BuilderCardThumb cardInfo={cardInfo} />
                      </div>
                    </Popover>
                  </Col>
                );
              })}
            </Row>
          </div>
        ))}
      </TabPane>
      <TabPane tab="Einstellungen" className="single-tab-container" key="2">
        <SettingsPanel />
      </TabPane>
    </Tabs>
  );
};

export default ElementTemplate;

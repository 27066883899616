import { Col, Row } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { MdMyLocation } from 'react-icons/md';
import Autocomplete from 'react-google-autocomplete';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { JobAddressType } from '../../../../interfaces/builderSliceTypes';
import { JobAttributesIdentifier } from '../../../../AdJobBuilder/helper/AdJobHelper';

const GoogleMapComponent = withScriptjs(
  withGoogleMap((props: any) => {
    const [markerPosition, setMarkerPosition] = useState({
      lat: 39.1731621,
      lng: -77.2716502
    });

    useEffect(() => {
      if (props.mapSet) {
        const { lat, lng } = props.mapSet;
        setMarkerPosition({ lat: lat, lng: lng });
      }
    }, [props.mapSet]);

    return (
      <GoogleMap
        defaultZoom={8}
        defaultCenter={{ lat: markerPosition.lat, lng: markerPosition.lng }}
        center={{ lat: markerPosition.lat, lng: markerPosition.lng }}
        zoom={14}
      >
        <Marker position={markerPosition} />
      </GoogleMap>
    );
  })
);

interface AddressPickerProps {
  handleChange: (value: any, identifier: JobAttributesIdentifier) => void;
  identifier: JobAttributesIdentifier;
  value: JobAddressType;
}

export default function AddressPicker({ handleChange, identifier, value }: AddressPickerProps) {
  const getAddressWithoutPostalCode = (place: any) => {
    let newAddress = place.formatted_address;
    place?.address_components &&
      place.address_components.forEach((element: any) => {
        if (element.types.includes('postal_code')) {
          newAddress = newAddress.replace(element.long_name, ' ');
        }
      });
    return newAddress;
  };

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Row className="builder__settings-sidebar__row">
        <Col span={24} data-testid="craftjs-button-component-size-slider">
          <Row className="builder__settings-sidebar__row">
            <Col span={23}>
              <Autocomplete
                placeholder="z.B. Hamburg-Altona"
                onPlaceSelected={place => {
                  const location = {
                    place: getAddressWithoutPostalCode(place),
                    lat: place?.geometry?.location?.lat(),
                    lng: place?.geometry?.location?.lng()
                  };
                  if (!location.place) return;
                  handleChange(
                    {
                      ...location,
                      zoom: 12
                    },
                    identifier
                  );
                }}
                className="address-picker-input"
                data-testid="addressInput"
                options={{
                  fields: [
                    'formatted_address',
                    'geometry',
                    'name',
                    'adr_address',
                    'address_component'
                  ],
                  types: ['geocode']
                }}
              />
            </Col>
            <Col>
              <MdMyLocation className="address-picker-icon" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="builder__settings-sidebar__row">
        <Col span={24}>
          <GoogleMapComponent
            {...{ mapSet: value }}
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyBs9iJzThW-gVXM1m9RwYD328Mb34HVL4c&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={<div style={{ height: `400px` }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </Col>
      </Row>
    </Col>
  );
}

import React, { useEffect, useState } from 'react';
import { Menu, Dropdown, Button, Space } from 'antd';
import { dropdowns, DropDownType, icons } from '../utils/dropdowns_and_icons';
import SupportChat, { handleOpenSupportChat } from '../../SupportChat/container/SupportChat';
import { useAppSelector } from '../../redux/hooks';
import { selectUserData } from '../../UI/redux/userSlice';
import { Role } from '../../UI/utils/roleTypes';
import DebugTrackerFeedbackFormButton from '../../Builder/DebugTracking/container/DebugTrackerFeedbackFormButton';
import { selectGlobalSettingsFlags } from '../../UI/redux/uiSlice';
import { getValueFromKeyValueArrayOfObjects } from '../../helper/array';

function HelpButton({ anonymAllowed }: { anonymAllowed?: boolean }) {
  const localUserData = useAppSelector(selectUserData);
  const globalSettingsFlags = useAppSelector(selectGlobalSettingsFlags);
  const isAgencyCustomer = localUserData?.role === Role.AGENCY_CUSTOMER;

  const [visible, setVisibility] = useState(true);
  const [productFruits, setProductFruits] = useState(window.productFruits);

  useEffect(() => {
    let getProductFruits: any;
    if (!productFruits) {
      getProductFruits = setInterval(() => {
        if (window.productFruits) {
          setProductFruits(window.productFruits);
          clearInterval(getProductFruits);
        }
      }, 1000);
    }

    return () => {
      clearInterval(getProductFruits);
    };
  }, []);

  const handleSupportChatOnReady = () => {
    window.$crisp?.push([
      'on',
      'chat:opened',
      () => {
        setVisibility(false);
      }
    ]);

    window.$crisp?.push([
      'on',
      'chat:closed',
      () => {
        setVisibility(true);
        window.$crisp.push(['do', 'chat:hide']);
      }
    ]);

    window.$crisp?.push([
      'on',
      'message:received',
      () => {
        setVisibility(false);
        window.$crisp.push(['do', 'chat:show']);
      }
    ]);

    window.$crisp?.push([
      'on',
      'message:compose:received',
      //@ts-ignore
      event => {
        if (Object.keys(event).length) {
          setVisibility(false);
          window.$crisp.push(['do', 'chat:show']);
        }
      }
    ]);
  };

  const menuItemRender = (dropdown: DropDownType, index: number) => {
    let reactElement;
    if (dropdown.link === 'live-chat') {
      reactElement = (
        <Menu.Item
          key={index}
          onClick={handleOpenSupportChat}
          className="menu-item"
          icon={React.cloneElement(dropdown.icon, { className: 'menu-item-icon' })}
        >
          <span className="menu-item-link">{dropdown.title}</span>
        </Menu.Item>
      );
    } else if (dropdown.link === 'feedback') {
      reactElement = (
        <Menu.Item
          key={index}
          onClick={()=> window.open('https://www.meetovo-funnel.de/feedback', '_blank')}
          className="menu-item"
          icon={React.cloneElement(dropdown.icon, { className: 'menu-item-icon' })}
        >
          <span className="menu-item-link">{dropdown.title}</span>
        </Menu.Item>
      );
    } else if (dropdown.link === 'debug-tracking-feedback') {
      reactElement = (
        <Menu.Item
          key={index}
          className="menu-item"
          icon={React.cloneElement(dropdown.icon, { className: 'menu-item-icon' })}
        >
          <span className="menu-item-link">
            <DebugTrackerFeedbackFormButton>{dropdown.title}</DebugTrackerFeedbackFormButton>
          </span>
        </Menu.Item>
      );
    } else {
      reactElement = (
        <Menu.Item
          key={index}
          className="menu-item"
          onClick={() => window.open(dropdown.link)}
          icon={React.cloneElement(dropdown.icon, { className: 'menu-item-icon' })}
        >
          <span className="menu-item-link">{dropdown.title}</span>
        </Menu.Item>
      );
    }

    if (!productFruits && dropdown.link === 'feedback') return null;

    if (dropdown.link === 'debug-tracking-feedback') {
      const enabledCoachIds = getValueFromKeyValueArrayOfObjects(
        globalSettingsFlags,
        'debugTrackingEnabledCoachIds'
      );

      const enabledForFeedbackCoachIds = getValueFromKeyValueArrayOfObjects(
        globalSettingsFlags,
        'debugTrackingEnabledFeedbackCoachIds'
      );

      if (
        (!enabledCoachIds?.includes(localUserData?.id) && enabledCoachIds !== 'ALL') ||
        (!enabledForFeedbackCoachIds?.includes(localUserData?.id) &&
          enabledForFeedbackCoachIds !== 'ALL')
      )
        return null;
    }

    return reactElement;
  };

  const menu = (
    <Menu className="menu-conatiner">
      {dropdowns.map((dropdown, index) => menuItemRender(dropdown, index))}
    </Menu>
  );

  if (!visible || isAgencyCustomer) return null;

  return (
    <div className="help-button-container">
      <SupportChat anonymAllowed={anonymAllowed} onlyScript onReady={handleSupportChatOnReady} />
      <Space direction="vertical">
        <Dropdown
          className="dropdown-container"
          overlay={menu}
          placement="topRight"
          trigger={['click']}
        >
          <Button className="circle-button">{React.cloneElement(icons.question)}</Button>
        </Dropdown>
      </Space>
    </div>
  );
}

export default HelpButton;

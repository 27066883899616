import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import { Button } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { message, Row, Col } from 'antd';
import { AnalyticsMessages } from '../../config/messages';
import { SummaryComponent } from '../components/SummaryComponent';
import PageHitComponentV2 from '../components/PageHitComponentV2';
import { PageHitComponent } from '../components/PageHitComponent';
import { PageHitComponentMobileV2 } from '../components/PageHitComponentMobileV2';
import { SourceComponent } from '../components/SourceComponent';
import { LoadingOutlined } from '@ant-design/icons';
import PageHeaderWithRangePicker from '../../SharedUI/components/PageHeaderWithRangePicker';
import { useNavigate } from 'react-router';
import DefaultLoader from '../../SharedUI/components/DefaultLoader';

const DELETE_USER_FUNNEL_DATA = gql`
  mutation deleteTracking($funnelId: Int!) {
    deleteTrackingsByFunnelId(funnelId: $funnelId)
  }
`;

export const GET_PLOT_DATA = gql`
  query getPlotData($filter: TrackingFilter!) {
    getSummaryPlot(filter: $filter) {
      pageView
      leads
      conversionRate
      averageTimeInSeconds
    }
    getPageHitPlot(filter: $filter) {
      stepIndex
      stepName
      hits
      averageTimeInSeconds
      leadQualifierId
      dropOfCount
      choiceArray {
        choiceId
        count
      }
    }
    getFunnel {
      id
    }
    getSourcePlot(filter: $filter) {
      sourceType
      sourceTypeClean
      count
    }
    getLeadQualifier {
      id
      question
      messageTitle
      type
      choices {
        id
        title
      }
    }
    getFunnelPages {
      id
      name
      type
    }
    getWelcomePage {
      id
      serviceName
    }
  }
`;

interface StateInterface {
  trackingFilter: TrackingFilterInterface;
}

interface TrackingFilterInterface {
  start: moment.Moment;
  end: moment.Moment;
}

export const analyticsMockDate = {
  start: moment('2020-03-25T00:08:44.588Z'),
  end: moment('2020-03-25T00:08:44.588Z').add(3, 'weeks')
};

function Analytics() {
  const [shouldDelete, setShouldDelete] = useState(false);
  const navigate = useNavigate();

  const useMock = !!localStorage.useMock;
  const [state, setState] = useState<StateInterface>({
    trackingFilter: useMock
      ? analyticsMockDate
      : {
        start: moment()
          .subtract(2, 'weeks')
          .startOf('day'),
        end: moment()
          .add(0, 'weeks')
          .endOf('day')
      }
  });

  const variables = {
    filter: {
      start: state.trackingFilter.start.toISOString(),
      end: state.trackingFilter.end.toISOString()
    }
  };

  const [deleteStats, { loading: deleteStatsLoading }] = useMutation(DELETE_USER_FUNNEL_DATA);
  // TODO: Update react hooks package apollo for `nextFetchPolicy`
  const { loading, error, data, refetch } = useQuery(GET_PLOT_DATA, {
    variables,
    fetchPolicy: 'cache-and-network'
  });
  const {
    getSummaryPlot: summaryPlotData,
    getPageHitPlot: pageHitData,
    getSourcePlot: sourcePlotData,
    getLeadQualifier: leadQualifierData,
    getWelcomePage: welcomePageData,
    getFunnel: funnelData,
    getFunnelPages: funnelPages
  } = data || {};

  useEffect(() => {
    if (funnelData?.id) {
      navigate('.', {
        state: {
          funnelIds: [funnelData.id],
          ...variables.filter
        },
        replace: true
      });
    }
  }, [funnelData?.id]);

  if (error) {
    message.error(AnalyticsMessages.loadingError);
    return null;
  }

  const getLeadCount = () => {
    try {
      if (!funnelPages?.length) return 0;

      const currentFunnelPage = funnelPages.find(({ type }: any) => type === 'THANK_YOU');

      const thisPageHitData = pageHitData.find(({ stepName }: any) => {
        const thisPageId = parseInt(stepName.split('craft-page_')[1] || 0);
        return currentFunnelPage.id === thisPageId;
      });

      return thisPageHitData?.hits || 0;
    } catch (error) {
      return 0;
    }
  };

  const getConversationRate = () => {
    try {
      if (!funnelPages?.length) return;

      const leads = getLeadCount();
      console.log(leads, summaryPlotData.pageView);
      const rawConversionRate = (leads / (summaryPlotData.pageView || 0)) * 100;

      if (rawConversionRate > 0) return rawConversionRate.toFixed(2);

      return '0.00';
    } catch (error) {
      return '0.00';
    }
  };

  const handleOk = () => {
    deleteStats({ variables: { funnelId: funnelData.id } })
      .then(result => {
        return refetch();
      })
      .then(() => {
        message.success(AnalyticsMessages.analyticsDeleted);
      })
      .catch(e => {
        console.log(e);
        message.error(AnalyticsMessages.analyticsErrorDuringDeletion);
      })
      .finally(() => {
        setShouldDelete(false);
      });
  };
  const handleDateChange = (dates: any) => {
    setState({
      ...state,
      trackingFilter: {
        start: moment(dates[0]).startOf('day'),
        end: moment(dates[1]).endOf('day')
      }
    });
    navigate('/analyse', {
      state: {
        start: moment(dates[0])
          .startOf('day')
          .toISOString(),
        end: moment(dates[1])
          .endOf('day')
          .toISOString(),
        funnelIds: [funnelData.id]
      },
      replace: true
    });
  };

  return (
    <div className="analytics">
      <DefaultLoader loading={loading} />
      <PageHeaderWithRangePicker
        loading={loading}
        refetch={refetch}
        rangePickerProps={{
          defaultValue: [state.trackingFilter.start, state.trackingFilter.end],
          onChange: handleDateChange
        }}
      />
      <Row>
        <Col span={24}>
          <SummaryComponent
            customCount={getLeadCount()}
            customConversionRate={getConversationRate()}
            summaryData={summaryPlotData}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <PageHitComponent
            leads={summaryPlotData?.leads}
            pageHitData={pageHitData}
            leadQualifierData={leadQualifierData}
            welcomePageData={welcomePageData}
            funnelPages={funnelPages}
          />
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          {window.innerWidth < 768 ? (
            <PageHitComponentMobileV2
              leads={summaryPlotData?.leads}
              pageHitData={pageHitData}
              leadQualifierData={leadQualifierData}
              welcomePageData={welcomePageData}
              funnelPages={funnelPages}
            />
          ) : (
            <PageHitComponentV2
              leads={summaryPlotData?.leads}
              pageHitData={pageHitData}
              leadQualifierData={leadQualifierData}
              welcomePageData={welcomePageData}
              funnelPages={funnelPages}
            />
          )}
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <SourceComponent sourceData={sourcePlotData} />
        </Col>
      </Row>

      <Row justify="center" align="middle">
        <Col>
          <Button
            disabled={deleteStatsLoading}
            danger
            type="link"
            onClick={() => (shouldDelete ? handleOk() : setShouldDelete(true))}
          >
            {deleteStatsLoading ? <LoadingOutlined spin /> : <DeleteOutlined />}
            {shouldDelete ? `Bestätigen` : `Statisiken zurücksetzen`}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default Analytics;

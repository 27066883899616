import React, { useEffect } from 'react';
import { getUrlParam } from '../../helper/url';
import { LoadingOutlined } from '@ant-design/icons';

import { useMutation } from '@apollo/react-hooks';
import { REGISTRATION } from '../components/EmailLoginAndRegistration';
import { getCookie } from '../../helper/cookies';
import { populateLocalStorageWithAuth } from './Login';
import { factorsAiTrackEvent } from '../../helper/factors-ai';

interface AutoRegistrationProps {
  onFinish: (data?: any) => void;
}

const AutoRegistration = ({ onFinish }: AutoRegistrationProps) => {
  const [registration] = useMutation(REGISTRATION);
  const fullname = getUrlParam('name');
  const firstName = (fullname.split(' ')[0] || '')?.trim();
  const lastName = (fullname.split(' ')[1] || '')?.trim();
  const email = getUrlParam('email')?.trim();
  const partnerId = getUrlParam('pid') || getCookie('meetovoPartnerId');
  const source = getCookie('meetovoFirstUserReferrer') || document.referrer || '';

  const handleAfterLogin = (loginData: any) => {
    const { expirationDate, token, activated, lastLogin, isAdmin, isEmployee } = loginData;
    
    factorsAiTrackEvent('REGISTRATION');
    
    populateLocalStorageWithAuth({
      expirationDate,
      token,
      activated,
      lastLogin,
      isEmployee,
      isAdmin
    });

    onFinish(true);
  };

  useEffect(() => {
    if (!fullname || !email) {
      onFinish(false);
      return;
    }

    registration({
      variables: { input: { firstName, lastName, email, source, partnerId } }
    })
      .then(res => {
        handleAfterLogin(res.data.registerViaEmailAndPassword);
      })
      .catch(e => {
        onFinish(false);
      });
  }, []);

  return <LoadingOutlined className="centered" spin />;
};

export default AutoRegistration;

import { saveCompleteAgencyOSBuilderDataThunk } from '../../AgencyOSBuilder/redux/thunk';
import { saveCompleteBuilderDataThunk } from '../../redux/thunk';
import { useIsAgencyOSBuilderPath } from '../useIsAgencyOSBuilderPath';

export const saveCompleteBuilderData = () => {
  const isAgencyOSBuilderPath = useIsAgencyOSBuilderPath();
  if (isAgencyOSBuilderPath) {
    return saveCompleteAgencyOSBuilderDataThunk;
  } else {
    return saveCompleteBuilderDataThunk;
  }
};

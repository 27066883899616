import React from 'react';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import BuilderSettingsTitle from '../../../sharedUI/BuilderSettingsTitle';
import BuilderColorPickerButton from '../../../sharedUI/BuilderColorPickerButton';
import { useNode } from '@craftjs/core';
import { getPaddingClassBySliderValue, getSliderValueByPaddingClass } from '../../Container/helper';
import { Card, Col, Row, Slider, Switch } from 'antd';
import { distanceBelowMarks } from '../../Container/config';
import BuilderSettingsTogglePro from '../../../sharedUI/BuilderSettingsTogglePro';
import { getElementColor } from '../../../../helper/craftJs';
import { useCurrentlyEditingBuilderTheme } from '../../../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';

export const DividerSettings = () => {
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));
  const theme = useCurrentlyEditingBuilderTheme();

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card" title="Abstandhalter" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Abstand oben" icon={<FaArrowUp />} />
            <Slider
              className="builder-slider-style"
              marks={distanceBelowMarks}
              step={50}
              tooltipVisible={false}
              defaultValue={50}
              value={getSliderValueByPaddingClass(props.paddingTopClass, 'top')}
              onChange={value => {
                setProp(
                  (props: any) =>
                    (props.paddingTopClass = getPaddingClassBySliderValue(value, 'top'))
                );
              }}
            />
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Abstand unten" icon={<FaArrowDown />} />
            <Slider
              className="builder-slider-style"
              marks={distanceBelowMarks}
              step={50}
              tooltipVisible={false}
              defaultValue={50}
              value={getSliderValueByPaddingClass(props.paddingBottomClass, 'bottom')}
              onChange={value => {
                setProp(
                  (props: any) =>
                    (props.paddingBottomClass = getPaddingClassBySliderValue(value, 'bottom'))
                );
              }}
            />
          </Col>
        </Row>

        <Row className="builder__settings-sidebar__row">
          <Col span={11}>
            <BuilderSettingsTitle title="Hintergrundfarbe" />
            <BuilderColorPickerButton
              color={getElementColor(props.backgroundColor, theme)}
              onChange={(backgroundColor, colorKey) => {
                setProp((props: any) => {
                  props.backgroundColor = colorKey || backgroundColor;
                });
              }}
            />
          </Col>
          <Col span={11} offset={2}>
            {props.showLine && (
              <>
                <BuilderSettingsTitle title="Trennerfarbe" />
                <BuilderColorPickerButton
                  color={getElementColor(props.dividerColor, theme)}
                  onChange={(color, colorKey) => {
                    setProp((props: any) => {
                      props.dividerColor = colorKey || color;
                    });
                  }}
                />
              </>
            )}
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTogglePro
              title="Linie anziegen"
              classNames="without-border"
              rightContent={
                <Switch
                  size="small"
                  checked={props.showLine}
                  onChange={(checked: boolean) => {
                    setProp((props: any) => (props.showLine = checked));
                  }}
                />
              }
            />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

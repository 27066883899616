import React, { ReactElement } from 'react';
import { useEditor } from '@craftjs/core';
import { Card } from 'antd';
import { useAppDispatch } from '../../../redux/hooks';
import { CardTypes } from '../../config/builderUi';
import { setSelectedCard } from '../../redux/builderSlice';

interface Props {
  cardInfo: CardTypes;
}

function BuilderCardThumb({ cardInfo }: Props): ReactElement {
  const dispatch = useAppDispatch();
  const { connectors } = useEditor();

  return (
    <div ref={(ref: any) => connectors.create(ref, cardInfo.element)} data-cy={cardInfo.data}>
      <Card
        className="element-card-preview"
        onClick={() => {
          dispatch(
            setSelectedCard({
              payload: {
                title: cardInfo.title
              }
            })
          );
        }}
      >
        {cardInfo.icon && React.cloneElement(cardInfo.icon, { className: 'element-card__icon' })}
        <p className="card-text">{cardInfo.title}</p>
      </Card>
    </div>
  );
}

export default BuilderCardThumb;

import React, { useState } from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { Card, Col, Row, Input } from 'antd';
import BuilderSettingsTitle from '../../../components/sharedUI/BuilderSettingsTitle';
import { ActionsController } from '../../../components/sharedUI/ActionsController';
import { CRAFT_ELEMENTS } from '../../config/craftElements';
import { CraftElementBaseProps } from '../../../helper/craftJs';
import {
  JobAttributeOption,
  JobAttributesIdentifier,
  getJobOptions
} from '../../helper/AdJobHelper';
import { SearchOutlined } from '@ant-design/icons';

interface Props extends CraftElementBaseProps {
  name: string;
  title: string;
  isSearchable?: boolean;
  value: string | string[] | undefined;
  multiSelect?: boolean;
}

export const CraftOptionPicker = ({ name, value }: Props) => {
  const { icon, options } = getJobOptions(name as JobAttributeOption);
  return (
    <ActionsController className="cursor-pointer" data-testid="optionPicker">
      {options?.map((item: any) => {
        if (value?.includes(item.value)) {
          return (
            <div>
              {//@ts-ignore
              item?.icon || icon}
              {item.value}
            </div>
          );
        }
      })}
    </ActionsController>
  );
};

export const CraftOptionPickerDefaultProps = {
  name: JobAttributesIdentifier.CATEGORY,
  isSearchable: false,
  title: 'Category',
  value: ['Engineering'],
  multiSelect: false
};

const CraftOptionsPickerSettings = () => {
  const { nodeId, props } = useNode(node => ({
    nodeId: node.id,
    props: node.data.props
  }));
  const { actions } = useEditor();
  const options = getJobOptions(props.name);

  const selectedValues = [...props.value];

  const handleOptionClick = (item: any) => {
    let selectedOptions = [...props.value];
    if (props.multiSelect) {
      selectedOptions.includes(item.value)
        ? selectedOptions.length > 1 &&
          (selectedOptions = selectedOptions.filter((value: any) => value !== item.value))
        : (selectedOptions = [...selectedOptions, item.value]);
    } else {
      selectedOptions = [item.value];
    }
    actions.setProp(nodeId, (state: any) => {
      state.value = selectedOptions;
    });
  };
  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card" title={props.title} bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={23} offset={1}>
            {props.isSearchable && (
              <Input
                className="input-search"
                placeholder="Search"
                prefix={<SearchOutlined className="search-icon" />}
              />
            )}
          </Col>
        </Row>

        <Row className="builder__settings-sidebar__row">
          <Col span={24} offset={1}>
            <BuilderSettingsTitle title={props.title} />
          </Col>
        </Row>

        <Row className="builder__settings-sidebar__row job-options-wrapper">
          {options.options?.map((item: any) => {
            return (
              <Col
                span={7}
                offset={1}
                onClick={() => {
                  handleOptionClick(item);
                }}
              >
                <div
                  className={`job-options ${selectedValues.includes(item.value) &&
                    'selected-category'}`}
                >
                  {item.icon && <div className="category-icon">{item.icon}</div>}
                  {item.value}
                </div>
              </Col>
            );
          })}
        </Row>
      </Card>
    </Col>
  );
};

CraftOptionPicker.craft = {
  name: CRAFT_ELEMENTS.CRAFT_OPTION_PICKER,
  props: CraftOptionPickerDefaultProps,
  related: {
    settings: CraftOptionsPickerSettings
  }
};

import { CRAFT_ELEMENTS } from '../config/craftElements';

export enum POSITION_OFFSET {
  LEFT = 'LEFT',
  TOP = 'TOP'
}

export const getActionControllerPositionOffset = (
  position: string,
  name: string,
  offset: string
) => {
  const positionString = position.split('p')[0];
  if (!positionString) return;
  switch (name) {
    case CRAFT_ELEMENTS.CHOICE:
      switch (offset) {
        case POSITION_OFFSET.LEFT:
          return `${parseFloat(positionString)}px`;
        case POSITION_OFFSET.TOP:
          return `${parseFloat(positionString)}px`;
      }
      break;
    default:
      return position;
  }
};

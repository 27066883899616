import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { getMeetovoAuthData } from '../../Login/container/Login';

export enum mixpanelEvents {
  LOGIN = 'Login',
  DELETE_ACCOUNT = 'Delete Account',
  CREATE_FUNNEL = 'Create Funnel',
  AD_DOWNLOAD = 'Ad Download',
  FUNNEL_DOMAIN_CONNECTED = 'Funnel Domain Connected',
  AGENCY_DASHBOARD_DOMAIN_CONNECTED = 'Agency Dashboard Domain Connected',
  SUBSCRIPTION_UPGRADE = 'Subscription Upgrade',
  CREATE_AD = 'Create Ad',
  SEND_MESSAGE_TO_SUPPORT = 'Send Message to Support',
  ONBOARDING_CHECKLIST_VIEWED = 'Onboarding Checklist Viewed',
  ADDED_LOGO_INSIDE_FUNNEL = 'Added Logo Inside Funnel',
  ADDED_SENDER_INSIDE_EMAIL_SETTINGS = 'Added Sender Inside Email Settings',
  ADDED_SENDER_INSIDE_MESSAGING_SETTINGS = 'Added Sender Inside Message Settings',
  ADDED_MESSAGING_NUMBER_INSIDE_MESSAGING_SETTINGS = 'Added Message Number Inside Message Settings',
  ADDED_PHONE_NUMBER_INSIDE_MESSAGING_SETTINGS = 'Added Phone Number Inside Message Settings',
  SELECTED_THEME_FOR_FUNNEL = 'Selected Theme For Funnel',
  TIME_SPEND_INSIDE_BUILDER = 'Time Spend Inside Builder',
  CREATED_AGENCY_DASHBOARD_USER = 'Created Agency Dashboard User',
  ADDED_USER_TO_SHARE_ACCOUNT = 'Added User To Share Account',
  THIRD_PARTY_TRACKING = 'Third Party Tracking',
  WEBHOOK_CONNECTED = 'Connected WebHook',
  REDIRECT_VIDEO_EDITING = "Redirect Video Editing"
}

export const getFunnelIdFromToken = ():number => {
  const decodedToken: {
    funnelId: number;
  } = jwt_decode(JSON.parse(getMeetovoAuthData() || '{}')?.token);
  return decodedToken?.funnelId || 0;
};

export const getMixPanelEventExistingTrackingData = (
  eventName: string,
  isFunnelIdCheckReq: boolean = true
) => {
  const eventData = JSON.parse(localStorage.getItem('mixpanelEventsTrack') || '{}')[eventName];
  if (eventData && moment().diff(moment(eventData.trackTime), 'hours') <= 24) {
    if (isFunnelIdCheckReq) {
      const decodedToken: {
        funnelId: string;
      } = jwt_decode(JSON.parse(getMeetovoAuthData() || '{}')?.token);
      if (eventData.funnelId === decodedToken.funnelId) return eventData;
      else return undefined;
    }
    return eventData;
  }
  return undefined;
};

export const saveMixpanelEventOccurrence = (
  eventName: string,
  isFunnelIdReq: boolean = true,
  trackTime: Date = new Date()
) => {
  const options = { funnelId: '' };

  if (isFunnelIdReq) {
    const decodedToken: {
      funnelId: string;
    } = jwt_decode(JSON.parse(getMeetovoAuthData() || '{}')?.token);
    options.funnelId = decodedToken.funnelId;
  }
  return localStorage.setItem(
    'mixpanelEventsTrack',
    JSON.stringify({
      ...JSON.parse(localStorage.getItem('mixpanelEventsTrack') || '{}'),
      [eventName]: {
        ...options,
        trackTime
      }
    })
  );
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../redux/store';
import { useAppSelector } from '../../redux/hooks';
import { SubscriptionInfo } from '../utils/userTypes';
import { getCompleteSubscriptionInfo } from './userThunk';

export interface UserState {
  data: any;
  isInitializing: boolean;
  userSubscriptionInfo: SubscriptionInfo;
  loaders: {
    subscriptionInfo: boolean;
  };
  errors: {
    subscriptionInfo: string;
  };
}

const INITIAL_STATE: UserState = {
  data: null,
  isInitializing: true,
  userSubscriptionInfo: {
    status: '',
    paddle_cancel_url: '',
    paddle_update_url: '',
    paddle_next_bill_date: '',
    paddle_cancellation_effective_date: '',
    paddle_unit_price: 0,
    paddle_subscription_plan_id: '',
    maxFunnels: 0,
    remainingFunnels: 0,
    maxVisits: 0,
    remainingVisits: 0,
    maxDomains: 0,
    remainingDomains: 0,
    remainingSubdomains: 0,
    agencyDashboardEnabled: false,
    brandingEnabled: false,
    customBrandingEnabled: false,
    maxAgencyCustomers: 0,
    remainingAgencyCustomers: 0,
    fileSizeLimitPerUploadInMB: 0,
    maxSmsCredits: 0,
    remainingSmsCredits: 0,
    maxWidgets: 0,
    remainingWidgets: 0,
    maxGoogleJobs: 0,
    remainingGoogleJobs: 0,
    extraSmsCredits: 0,
    trialEndDate: '',
    paddle_user_id: 0
  },
  loaders: {
    subscriptionInfo: false
  },
  errors: {
    subscriptionInfo: ''
  }
};

export const userSlice = createSlice({
  name: 'user',
  // `createSlice` will infer the state type from the `initialState` argument
  initialState: INITIAL_STATE,
  reducers: {
    resetToInitialUserState: () => {
      return INITIAL_STATE;
    },
    setUserData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.isInitializing = false;
    },
    setUserIsInitializing: (state, action: PayloadAction<boolean>) => {
      state.isInitializing = action.payload;
    }
  },
  extraReducers: {
    [`${getCompleteSubscriptionInfo.pending}`]: (
      state: UserState,
      action: PayloadAction<{
        subscriptionInfo: SubscriptionInfo;
      }>
    ) => {
      state.loaders.subscriptionInfo = true;
    },
    [`${getCompleteSubscriptionInfo.fulfilled}`]: (
      state: UserState,
      action: PayloadAction<{
        subscriptionInfo: SubscriptionInfo;
      }>
    ) => {
      state.userSubscriptionInfo = action.payload.subscriptionInfo;
      state.loaders.subscriptionInfo = false;
    },
    [`${getCompleteSubscriptionInfo.rejected}`]: (state: UserState, action: PayloadAction) => {
      state.loaders.subscriptionInfo = false;
      // @ts-ignore
      state.errors.subscriptionInfo = action?.error?.message;
    }
  }
});

export const { resetToInitialUserState, setUserData, setUserIsInitializing } = userSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectUserData = (state: RootState) => state.user.data;
export const useUserIsInitializing = () =>
  useAppSelector((state: RootState) => state.user.isInitializing);
export const useSubscriptionInfo = () =>
  useAppSelector((state: RootState) => state.user.userSubscriptionInfo);

export const useSubscriptionInfoLoading = () =>
  useAppSelector((state: RootState) => state.user.loaders.subscriptionInfo);

export const useSubscriptionInfoError = () =>
  useAppSelector((state: RootState) => state.user.errors.subscriptionInfo);

export default userSlice.reducer;

import React from 'react';
import { useEditor, useNode } from '@craftjs/core';
import { Card, Col, Row, Slider, Switch, Tabs } from 'antd';
import { ActionsController } from '../sharedUI/ActionsController';
import BuilderSettingsTitle from '../sharedUI/BuilderSettingsTitle';
import BuilderColorPickerButton from '../sharedUI/BuilderColorPickerButton';
import IconPickerGrid from '../../../GeneralComponents/IconPickerGrid/container/IconPickerGrid';
import EmojiPicker from '../../../GeneralComponents/EmojiPicker';
import {
  IconProvider as Icon
  // @ts-ignore
} from 'meetovo-frontend-booking';
import { FaTextHeight } from 'react-icons/fa';
import FrequentlyUsedIconPicker from '../../../GeneralComponents/FrequentlyUsedIconPicker/container/FrequentlyUsedIconPicker';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../config/craftElements';
import TextAlignmentButtons from '../../../SharedUI/components/TextAlignmentButtons';
import { CraftElementBaseProps, getKeyByValue, getParentNodeId } from '../../helper/craftJs';
import { getElementColor } from '../../helper/craftJs';
import { FUNNEL_THEME_KEYS } from '../../interfaces/builderSliceTypes';
import { hexToRGBA } from '../../helper/colorConversion';
import { useCurrentlyEditingBuilderTheme } from '../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';

const { TabPane } = Tabs;

export enum IconType {
  icon = 'icon',
  emoji = 'emoji'
}

export enum SpecialIconProps {
  FONT_SIZE = 'fontSize',
  IS_OUTLINE = 'isOutline'
}
interface IconComponentDefaultPropsInterface extends CraftElementBaseProps {
  fontSize?: number;
  type?: IconType.icon;
  value?: string;
  color?: string;
  align?: string; // TODO: change to enum
  isOutline?: boolean;
  isFirstRender?: boolean;
  isColumnIcon?: boolean;
  className?: string;
}

const marks = {
  2: 'S',
  4: 'M',
  6: 'L'
};

const IconComponent = (props: IconComponentDefaultPropsInterface) => {
  const { currentNode } = useNode(node => ({
    currentNode: node
  }));
  const isPropSizeValid = Object.prototype.hasOwnProperty.call(marks, props.fontSize as number);
  const theme = useCurrentlyEditingBuilderTheme();
  const icon = (
    <Icon
      name={props.value}
      color={getElementColor(props?.color, theme)}
      style={{
        fontSize: `${isPropSizeValid ? props.fontSize : 4}em`,
        color: getElementColor(props?.color, theme)
      }}
    />
  );

  return (
    <ActionsController
      className={`icon__element ${props.className}`}
      style={{ textAlign: props.align }}
      label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}
    >
      {props.isOutline && props.type === IconType.icon ? (
        <div
          style={{
            ...(props?.color && {
              background: hexToRGBA(getElementColor(props?.color, theme) as string, 0.2)
            })
          }}
          className="icon-outline"
          data-testid="icon-test-id"
        >
          {icon}
        </div>
      ) : (
        icon
      )}
    </ActionsController>
  );
};

const IconComponentDefaultProps = {
  fontSize: 4,
  type: IconType.icon,
  value: 'FaSmileWink',
  color: FUNNEL_THEME_KEYS.ACCENT_COLOR,
  isCoumnIcon: false,
  align: 'left',
  isFirstRender: true
};

export const IconComponentSettings = () => {
  const { query, actions } = useEditor();
  const {
    actions: { setProp },
    props,
    id
  } = useNode(node => {
    return {
      props: node.data.props
    };
  });
  const theme = useCurrentlyEditingBuilderTheme();

  const isPropSizeValid = Object.prototype.hasOwnProperty.call(marks, props.fontSize as number);

  const handleSpecialIconProps = (key: string, value: any) => {
    const serializedNodes = query.getSerializedNodes();
    const containerNodeId = getParentNodeId(id, query, CRAFT_ELEMENTS.CONTAINER);
    const allDescendants = query.node(containerNodeId).descendants(true);
    const specialIconNodeIds = allDescendants?.filter((nodeId: any) => {
      const node = serializedNodes[nodeId];
      return node.displayName === CRAFT_ELEMENTS.ICON && node.props.isColumnIcon === true;
    });
    actions.setProp(specialIconNodeIds, (props: any) => {
      props[key] = value;
    });
  };

  return (
    <div className="builder__settings-sidebar__container">
      <Card className="settings-card" title="Icon" bordered={false}>
        <Row className="builder__settings-sidebar__row">
          <Col span={24}>
            <BuilderSettingsTitle title="Schriftgröße" icon={<FaTextHeight />} />
            <Slider
              className="builder-slider-style"
              marks={marks}
              min={2}
              max={6}
              step={2}
              tooltipVisible={false}
              defaultValue={4}
              value={isPropSizeValid ? props.fontSize : 4}
              onChange={value => {
                setProp((props: any) => {
                  props.fontSize = value;
                });
                if (props.isColumnIcon) {
                  handleSpecialIconProps(SpecialIconProps.FONT_SIZE, value);
                }
              }}
            />
          </Col>
        </Row>
        <Row className="builder__settings-sidebar__row mb-4">
          <Col span={24}>
            <BuilderSettingsTitle title="Ausrichtung" />
            <TextAlignmentButtons
              align={props.align}
              disabled={props.isColumnIcon}
              onChange={align => {
                setProp((props: any) => (props.align = align));
              }}
            />
          </Col>
        </Row>
        <Tabs className="tabs__reset-left-padding" defaultActiveKey={props.type}>
          <TabPane tab="Icons" key={IconType.icon}>
            <Row justify="space-between">
              <Col>
                <BuilderSettingsTitle title="Kreis" />
              </Col>
              <Col>
                <Switch
                  size="small"
                  onChange={value => {
                    setProp((props: any) => (props.isOutline = value));
                    if (props.isColumnIcon) {
                      handleSpecialIconProps(SpecialIconProps.IS_OUTLINE, value);
                    }
                  }}
                  checked={props.isOutline}
                />
              </Col>
            </Row>
            <Row className="builder__settings-sidebar__row">
              <Col span={12}>
                <BuilderSettingsTitle title="Farbe" />
                <BuilderColorPickerButton
                  color={getElementColor(props.color, theme)}
                  onChange={(color, colorKey) => {
                    setProp((props: any) => {
                      props.color = colorKey || color;
                    });
                  }}
                />
              </Col>
            </Row>
            <Row className="builder__settings-sidebar__row">
              <Col span={24}>
                <BuilderSettingsTitle title="Häufig verwendet" />
                <FrequentlyUsedIconPicker
                  value={props.value}
                  onChange={(value: string) => {
                    setProp((props: any) => {
                      props.value = value;
                      props.type = IconType.icon;
                    });
                  }}
                />
              </Col>
            </Row>
            <Row className="builder__settings-sidebar__row">
              <Col span={24}>
                <IconPickerGrid
                  onChange={value => {
                    setProp((props: any) => {
                      props.value = value;
                      props.type = IconType.icon;
                    });
                  }}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tab="Emoji's" key={IconType.emoji} style={{ height: 600 }}>
            <EmojiPicker
              emojiSize={40}
              onChange={value => {
                setProp((props: any) => {
                  props.value = value;
                  props.type = IconType.emoji;
                });
              }}
            />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

IconComponent.craft = {
  name: CRAFT_ELEMENTS.ICON,
  props: IconComponentDefaultProps,
  related: {
    settings: IconComponentSettings
  }
};

export default IconComponent;

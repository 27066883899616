import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const GET_COMPLETE_WIDGET = gql`
  query GetWidgetHub($widgetHubId: Int!) {
    getWidgetHubById(input: $widgetHubId) {
      id
      craftState
      title
      uniqueIdentifier
      funnels {
        id
        title
        uniqueIdentifier
        jobTitle
        employmentType
        category
        jobAddress
        salaryPlan
        baseSalary
        workplaceModel
        experienceDuration
      }
      funnelColorTheme {
        id
        headlineColor
        textColor
        accentColor
        backgroundColor
        cardBackgroundColor
        cardIconColor
        cardTextColor
        accentColorContrast
        fontFamily
        fontLink
        isCustom
        name
        defaultGradient
      }
    }
    getUserColorThemes {
      id
      textColor
      accentColor
      backgroundColor
      cardBackgroundColor
      cardIconColor
      cardTextColor
      headlineColor
      accentColorContrast
      isCustom
      name
      defaultGradient
    }
  }
`;

export default function useGetCompleteWidget(widgetHubId: number) {
  return useQuery(GET_COMPLETE_WIDGET, {
    variables: { widgetHubId },
    fetchPolicy: 'cache-and-network'
  });
}

export function fetchCompleteWidget(widgetHubId: number) {
  return window.apolloClient.query({
    variables: { widgetHubId },
    query: GET_COMPLETE_WIDGET,
    fetchPolicy: 'no-cache'
  });
}

import React, { useState } from 'react';
import DebugTrackerFeedbackForm from './DebugTrackerFeedbackForm';
import { Button } from 'antd';

const DebugTrackerFeedbackFormButton = ({ children }: { children: string }) => {
  const [visible, setVisible] = useState(false);

  const handleOpenModal = () => {
    setVisible(true);
  };

  const handleCloseModal = () => {
    setVisible(false);
  };

  return (
    <>
      <span onClick={handleOpenModal}>{children}</span>
      <DebugTrackerFeedbackForm visible={visible} onClose={handleCloseModal} />
    </>
  );
};

export default DebugTrackerFeedbackFormButton;

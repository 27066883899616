import React from 'react';
import { FaCalendar, FaMapMarkerAlt, FaMoneyCheckAlt } from 'react-icons/fa';
import { Funnel } from '../../../interfaces/builderSliceTypes';
import {
  JobAttributeOption,
  JobAttributesIdentifier,
  getJobOptions
} from '../../helper/AdJobHelper';
import { formatSalary } from '../../helper/validateFunnelJobPresets';
import { useCurrentlyEditingTheme } from '../../../redux/builderSlice';

type RenderContentProps = {
  funnel: Funnel;
  identifier: JobAttributesIdentifier;
};

const RenderContent = ({ funnel, identifier }: RenderContentProps) => {
  const { icon, options } = getJobOptions(identifier as JobAttributeOption);

  const noValueExists = 'Keine Angabe';
  const value = funnel?.[identifier as keyof Funnel] as any;
  const { cardTextColor, accentColor } = useCurrentlyEditingTheme();

  switch (identifier) {
    case JobAttributesIdentifier.SALARY:
      return (
         value && funnel.jobTitle || funnel.companyName ? (
          <div className="job-attribute">
            <FaMoneyCheckAlt style={{ color: accentColor }} />{' '}
            {Array.isArray(value) ? `${formatSalary(value[0])}€ - ${formatSalary(value[1])}€` : ''}{' '}
            {funnel.salaryPlan}
          </div>
        ): noValueExists
      );
    case JobAttributesIdentifier.EXPERIENCE:
      return (
        <div className="job-attribute">
          <FaCalendar style={{ color: accentColor }} /> {value} {funnel.experienceDuration}
        </div>
      );
    case JobAttributesIdentifier.JOB_TITLE:
    case JobAttributesIdentifier.COMPANY_NAME:
      return <h5 style={{ color: cardTextColor }}>{value || noValueExists}</h5>;
    case JobAttributesIdentifier.JOB_ADDRESS:
      return (
        value &&
        value.place && (
          <div className="job-attribute">
            <FaMapMarkerAlt style={{ color: accentColor }} />
            {funnel?.jobAddress?.place}
          </div>
        )
      );
    case JobAttributesIdentifier.WORKPLACE_MODEL:
      const iconOption: any = options.find((option: any) => option.value === value);
      return (
        iconOption && (
          <div className="job-attribute">
            <span style={{ color: accentColor }}>{iconOption.icon || icon}</span>
            {iconOption.label}
          </div>
        )
      );
    case JobAttributesIdentifier.CATEGORY:
    default:
      return (
        Array.isArray(value) &&
        value.map((item: any, index: number) => {
          const iconOption: any = options.find((option: any) => option.value === item);
          return (
            iconOption && (
              <div
                className="job-attribute"
                style={index !== value.length - 1 ? { marginRight: '0px' } : {}}
                key={index}
              >
                <span style={{ color: accentColor }}>
                  {index === 0 ? iconOption?.icon || icon : <></>}
                </span>
                {value.length - 1 === index ? iconOption?.label : `${iconOption?.label},`}
              </div>
            )
          );
        })
      );
  }
};

export default RenderContent;

import React from 'react';
import { Tabs } from 'antd';
import BuilderMetaDataSettings from '../components/generalSettings/BuilderMetaDataSettings';
import BuilderGeneralSettings from '../components/generalSettings/BuilderGeneralSettings';
import BuilderJobGeneralSettings from '../components/generalSettings/builderJobSettings/BuilderJobGeneralSettings';
import { useGeneralSettingsTabView } from '../redux/builderSlice';
import { GeneralSettingsTabView } from '../interfaces/builderSliceTypes';

const { TabPane } = Tabs;

const GeneralSettings = () => {
  const tabView = useGeneralSettingsTabView();
  
  return (
    <Tabs className="general-settings-tabs overflow-auto" defaultActiveKey={tabView}>
      <TabPane tab="Allgemein" key={GeneralSettingsTabView.GENERAL_SETTINGS}>
        <BuilderGeneralSettings />
      </TabPane>
      <TabPane tab="Meta-Daten" key={GeneralSettingsTabView.META_DATA}>
        <BuilderMetaDataSettings />
      </TabPane>
      <TabPane tab="Job-Informationen" key={GeneralSettingsTabView.JOB_INFORMATION}>
        <BuilderJobGeneralSettings />
      </TabPane>
    </Tabs>
  );
};

export default GeneralSettings;

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const GET_ALL_FUNNELS_TITLE_ONLY = gql`
  query getAllFunnels {
    getAllFunnels {
      id
      title
    }
  }
`;
export const GET_FUNNEL_BY_ID = gql`
  query getFunnelById($funnelId: Int) {
    getFunnelById(funnelId: $funnelId) {
      id
      title
      domain {
        name
        status
        fullLink
      }
    }
  }
`;

export const GET_FUNNEL_BY_ID_WITH_JOB_INFO = gql`
  query getFunnelById($funnelId: Int) {
    getFunnelById(funnelId: $funnelId) {
      id
      uniqueIdentifier
      jobTitle
      employmentType
      category
      jobAddress
      salaryPlan
      experienceDuration
      workplaceModel
      baseSalary
    }
  }
`;

export const GET_ALL_FUNNELS = gql`
  query getAllFunnels {
    getAllFunnels {
      id
      title
      uniqueIdentifier
      previewImageV2URL
      primaryBrandColor
      type
      builderVersion
      secondaryBrandColor
      funnelFolderId
      enabled
      updatedAt
    }
  }
`;

export default function useGetAllFunnels() {
  return useQuery(GET_ALL_FUNNELS, {
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        'meetovo-cache-disabled': 'true'
      }
    }
  });
}

export function useGetAllFunnelsTitleOnly() {
  return useQuery(GET_ALL_FUNNELS_TITLE_ONLY, {
    fetchPolicy: 'no-cache'
  });
}

export function getAllFunnels() {
  return window.apolloClient.query({
    query: GET_ALL_FUNNELS,
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        'meetovo-cache-disabled': 'true'
      }
    }
  });
}
export function getFunnelById(funnelId: number) {
  return window.apolloClient.query({
    query: GET_FUNNEL_BY_ID,
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        'meetovo-cache-disabled': 'true'
      }
    },
    variables: {
      funnelId: funnelId
    }
  });
}

export function getFunnelByIdForJobInfo(funnelId: number) {
  return window.apolloClient.query({
    query: GET_FUNNEL_BY_ID_WITH_JOB_INFO,
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        'meetovo-cache-disabled': 'true'
      }
    },
    variables: {
      funnelId: funnelId
    }
  });
}

import {
  BuilderState,
  DesignSettingsTabView,
  EmailType,
  FunnelThemeType,
  GeneralSettingsTabView
} from '../../Builder/interfaces/builderSliceTypes';

export const defaultFunnel = {
  id: 0,
  uniqueIdentifier: '',
  builderVersion: 'V2',
  domain: {
    fullLink: '',
    status: '',
    name: ''
  },
  isEditing: false,
  jobTitle: '',
  companyName: '',
  category: [],
  employmentType: [],
  education: [],
  baseSalary: [],
  experience: 0,
  jobAddress: {
    place: '',
    zoom: 0,
    lat: 0,
    lng: 0
  },
  salaryPlan: '',
  experienceDuration: '',
  workplaceModel: ''
};

export const defaultCraftState =
  'ewogICJST09UIjogxAwgICJ0eXBlxA4gInJlc29sdmVkTmFtxBIiQm9keSIgfSzGKGlzQ2FudmFzIjogdHJ1ZccWcHJvcMQTe8gnZGlzcGxhec1ExxtjdXN0b23MLWhpZGRlbiI6IGZhbHPIU25vZGXEU1siY0lqMDdPVE9YdiIsICJXRkJZYkdQQXd0xA5mTlZyeHZCUmh3Il3HOWxpbmtlZE7HP3t9CiDmAL3MSP8A9+QAs0xvZ2/1APfsAKXpAPjFDyAgImJhY2tncm91bmRDb2xvcsRJ0BPGOyAgImFsaWfkAPYiY2VudGXLGXVybMQXL2Rhc2hib2FyZC9mdW5uZWwtYnVpbGRlci9kZWZhdWx0LWxvZ28ucG5nyjtpc0PlAV/IfuUArcUd9wGa5QDn+QGacGFyZW50xCjlAi7/AbDmAbD/AYjtAcL/AYjEeU1haW5Db250YWluZXL/AojqAZAgImRhdGEtY3nEQ3Jvb3QtY9JE7wELzmr/ART/ARTwAsR2MlVVeUxsaGJE/wKoIO0C1P8BIOQAhUZvb+QCPf8CquwBGv8CpMQT+AEjx23/ARz/ARz/AjDxARDrATz/ARDEeUFkZP4CL/cBFmlkxD4xNjYyOTY0OTgyMzgx+QEHzWT/AQ3EMOwEtP8BE/8BEyB9Cn0=';
export const builderPages = [
  {
    name: 'Leere Seite',
    craftState: defaultCraftState,
    id: -1,
    pageOrder: 0,
    facebookPixelEvent: '',
    tikTokPixelEvent: '',
    linkedInEvent: '',
    googleTagTrigger: '',
    redirectURL: '',
    customJs: ''
  },
  {
    name: 'Dankesseite',
    craftState: defaultCraftState,
    id: -2,
    pageOrder: -1,
    type: 'THANK_YOU',
    facebookPixelEvent: '',
    tikTokPixelEvent: '',
    linkedInEvent: '',
    googleTagTrigger: '',
    customJs: '',
    redirectURL: ''
  }
];
export const funnelTheme: FunnelThemeType = {
  id: 2877,
  headlineColor: '#333333',
  textColor: '#555555',
  accentColor: '#23d3d3',
  backgroundColor: '#f3f3f3',
  cardBackgroundColor: 'rgba(251, 254, 254, 1)',
  cardIconColor: '#ffffff',
  cardTextColor: 'rgba(85, 85, 85, 1)',
  accentColorContrast: '#ffffff',
  fontFamily: 'Open Sans',
  fontLink:
    'https://fonts.googleapis.com/css?family=Open Sans%3Aregular%2Citalic%2C300%2C700&subset=latin&font-display=swap',
  isCustom: false,
  name: 'defautl-Theme',
  defaultGradient:
    'linear-gradient(90deg, RGBA(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%)'
};

export const defaultFunnelTheme: FunnelThemeType = {
  headlineColor: '#333333',
  textColor: '#555555',
  accentColor: '#23d3d3',
  backgroundColor: '#f3f3f3',
  cardBackgroundColor: '#0d265d',
  cardIconColor: '#ffffff',
  cardTextColor: '#ffffff',
  accentColorContrast: '#ffffff',
  fontFamily: 'Open Sans',
  name: '',
  id: 1,
  isCustom: false,
  defaultGradient: 'linear-gradient(90deg, #23d3d3 0%, #23d3d3 100%)'
};
export const calendarSettings = {
  bookingRangeInHours: [1, 360],
  busyStatus: true,
  durationInMinutes: 15,
  id: '37a956dbdd',
  maxBookingsPerDay: 5,
  onlySettingsReq: true,
  shifts: [
    {
      dow: [1, 2, 3, 4, 5],
      end: '16:00',
      start: '08:00'
    }
  ],
  slotBufferInMinutes: 15,
  specialClosures: []
};

export const generalSettings = {
  brandingEnabled: true,
  cookieBanner: true,
  language: 'DE',
  title: 'title1',
  uniqueIdentifier: 'uniqueIdentifier'
};
export const metaData = {
  customMetaDataEnabled: false,
  description: '',
  iconURL: '',
  metaTitle: '',
  previewImageURL: ''
};

export const templates = [
  {
    brandingEnabled: true,
    emailSenderName: '',
    enabled: true,
    html: '',
    id: 7574,
    otherEmailAddressesToSend: '',
    subject: 'Deine Bewerbung ist eingegangen 🎉',
    type: EmailType.CONFIRMATION
  },
  {
    brandingEnabled: true,
    emailSenderName: '',
    enabled: true,
    html: '',
    id: 7573,
    otherEmailAddressesToSend: '',
    subject: 'Neue Bewerbung 🎉',
    type: EmailType.INTERNAL_CONFIRMATION
  },
  {
    brandingEnabled: true,
    emailSenderName: '',
    enabled: true,
    html: '',
    id: 7575,
    otherEmailAddressesToSend: '',
    subject: 'Absage ❌',
    type: EmailType.CANCELATION
  },
  {
    brandingEnabled: true,
    emailSenderName: '',
    enabled: true,
    html: '',
    id: 7576,
    otherEmailAddressesToSend: '',
    subject: 'Terminerinnerung 🕑',
    type: EmailType.REMINDER
  }
];

export const INITIAL_STATE: BuilderState = {
  builderPages,
  currentlyEditingTheme: defaultFunnelTheme,
  isMobileView: true,
  isEditingColorTheme: false,
  selectedCard: null,
  selectedElementTab: '1',
  selectedPageId: null,
  checksums: {
    funnelTheme: null,
    funnel: null,
    otherEmailAddressesToSend: null,
    emailTemplatesGeneralSettings: null,
    emailTemplateActiveTabContent: null,
    calendarSettings: null,
    builderPages: null,
    generalSettings: null,
    metaData: null,
    funnelPresets: null
  },
  ui: {
    funnelIsEditingModalVisibility: false,
    completeFunnelSavingByUserInteractionDisabled: true,
    funnelSuggestionModalVisibility: false
  },
  generalSettings,
  funnel: defaultFunnel,
  loaders: {
    mainFunnelLoader: false,
    isFunnelSaved: true
  },
  shouldSaveFunnel: true,
  lastSaveSuccessful: true,
  funnelTheme,
  metaData,
  commonElements: {
    header: '',
    footer: ''
  },
  draggingElement: {
    name: null,
    nodeId: undefined,
    draggingFrom: undefined,
    parentNodeId: undefined,
    nodeIndex: undefined,
    displayName: undefined
  },
  oldLeadQualifier: {
    leadQualifier: [],
    leadQualifierIdsToDelete: [],
    choiceIdsToDelete: []
  },
  selectedBlockNodeIds: [],
  userColorThemes: [],
  funnelSuggestion: '',
  channelTemplates: {
    sender: '',
    brandingEnabled: false,
    activeTab: '',
    templates: []
  },
  funnelPresets: [],
  generalSettingsTabView: GeneralSettingsTabView.GENERAL_SETTINGS,
  designSettingsTabView: DesignSettingsTabView.COLORS,
  builderValidationErrors: []
};

export function apolloClientMock() {
  const mockApolloClient = {
    mutate: jest.fn()
  };
  window.apolloClient = mockApolloClient;
}

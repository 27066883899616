import { Col, Row, Select } from 'antd';
import React from 'react';
import BuilderSettingsTitle from '../../../sharedUI/BuilderSettingsTitle';
import { JobAttributesIdentifier } from '../../../../AdJobBuilder/helper/AdJobHelper';

interface ChoicePickerProps {
  title: string;
  handleChange: (value: any, identifier: JobAttributesIdentifier) => void;
  value: any;
  options: string[];
  identifier: JobAttributesIdentifier;
}

export default function ChoicePicker({
  title,
  handleChange,
  value,
  options,
  identifier
}: ChoicePickerProps) {
  return (
    <Row className="builder__settings-sidebar__row">
      <Col span={24}>
        <BuilderSettingsTitle title={title} />
        <Select
          className="w-100"
          onChange={value => {
            handleChange(value, identifier);
          }}
          value={value}
        >
          {options.map((option: string) => (
            <Select.Option value={option}>{option}</Select.Option>
          ))}
        </Select>
      </Col>
    </Row>
  );
}

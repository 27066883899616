import { useNode } from '@craftjs/core';
import { Col, Card, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { ActionsController } from '../../sharedUI/ActionsController';
import { CRAFT_ELEMENTS, CRAFT_ELEMENTS_LABEL } from '../../../config/craftElements';
import { HTMLWidgetDefaultProps } from './config';
import Editor from 'react-simple-code-editor';
//@ts-ignore
import { highlight, languages } from 'prismjs/components/prism-core';
import { FaCode } from 'react-icons/fa';
import { CraftElementBaseProps } from '../../../helper/craftJs';

interface Props extends CraftElementBaseProps {
  html?: string;
}

const HTMLWidget = ({ html }: Props) => {
  const { nodeId , currentNode } = useNode(state => ({
    nodeId: state.id,
    currentNode:state
  }));
  const htmlWidgetInnerWrapperId = `HTMLWidget-inner-${nodeId}`;
  const [currentContentHeight, setCurrentContentHeight] = useState(0);

  useEffect(() => {
    const targetElement = document.getElementById(htmlWidgetInnerWrapperId);
    if (html) {
      const fragment = document.createRange().createContextualFragment(html);
      if (targetElement) {
        targetElement.innerHTML = '';
        targetElement.appendChild(fragment);
      }
      setCurrentContentHeight(targetElement?.offsetHeight || 0);
    } else {
      if (targetElement) {
        targetElement.innerHTML = '';
      }
      setCurrentContentHeight(0);
    }
  }, [html]);

  return (
    <ActionsController className="html-widget" label={CRAFT_ELEMENTS_LABEL[currentNode.data.displayName]}>
      <div id={htmlWidgetInnerWrapperId}></div>
      {!currentContentHeight && (
        <div className="d-flex justify-content-center html-widget__icon-on-empty-view p-3">
          <FaCode className="mx-2" />
          <h3 className="remove-margin-bottom">HTML</h3>
        </div>
      )}
    </ActionsController>
  );
};

export default HTMLWidget;

const HTMLWidgetSettings = () => {
  const {
    actions: { setProp },
    props
  } = useNode(node => ({
    props: node.data.props
  }));

  return (
    <Col span={24} className="builder__settings-sidebar__container">
      <Card className="settings-card" title="HTML" bordered={false}>
        <Row className="builder__settings-sidebar__row remove-margin-bottom">
          <Col span={24}>
            <Editor
              placeholder='<script>console.log("Hello World!")</sctipt>'
              value={props.html || ''}
              onValueChange={value => {
                setProp((props: any) => (props.html = value));
              }}
              highlight={code => highlight(code, languages.js)}
              padding={10}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12
              }}
              className="code-editor"
            />
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

HTMLWidget.craft = {
  name: CRAFT_ELEMENTS.HTML_WIDGET,
  props: HTMLWidgetDefaultProps,

  related: {
    settings: HTMLWidgetSettings
  }
};

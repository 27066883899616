import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

export const GET_COMPLETE_FUNNEL = gql`
  query getFunnelPages {
    getFunnelPages {
      name
      craftState
      redirectURL
      id
      type
      pageOrder
      facebookPixelEvent
      tikTokPixelEvent
      linkedInEvent
      googleTagTrigger
      customJs
    }
    getFunnel {
      id
      uniqueIdentifier
      previewImageV2URL
      description
      calendarId
      title
      serviceId
      calendarBlockMode
      logoURL
      type
      enabled
      metaTitle
      customMetaDataEnabled
      iconURL
      previewImageURL
      fontFamily
      funnelThemeId
      language
      brandingEnabled
      cookieBanner
      domain {
        name
        status
        fullLink
      }
      isEditing
      jobTitle
      companyName
      category
      employmentType
      experience
      experienceDuration
      education
      baseSalary
      jobAddress
      salaryPlan
      workplaceModel
    }
    getFunnelColorTheme {
      id
      headlineColor
      textColor
      accentColor
      backgroundColor
      cardBackgroundColor
      cardIconColor
      cardTextColor
      accentColorContrast
      fontFamily
      fontLink
      isCustom
      name
      defaultGradient
    }
    getEmailTemplates {
      id
      subject
      html
      type
      emailSenderName
      enabled
      brandingEnabled
      otherEmailAddressesToSend
      scheduledTime
    }
    getLeadQualifier {
      id
      question
      type
      craftJsNodeId
      choices {
        id
        title
        unit
        craftJsNodeId
      }
    }
    getUserColorThemes {
      id
      textColor
      accentColor
      backgroundColor
      cardBackgroundColor
      cardIconColor
      cardTextColor
      headlineColor
      accentColorContrast
      isCustom
      name
      defaultGradient
    }
  }
`;

export default function useGetCompleteFunnel() {
  return useQuery(GET_COMPLETE_FUNNEL, {
    fetchPolicy: 'cache-and-network'
  });
}

export function fetchCompleteFunnel() {
  return window.apolloClient.query({
    query: GET_COMPLETE_FUNNEL,
    fetchPolicy: 'no-cache'
  });
}

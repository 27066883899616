import React, { useEffect, useState } from 'react';
import { Comment, Tooltip, List, Avatar as AntAvatar, Typography, message } from 'antd';
import { useMutation, useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import moment from 'moment';
import Avatar from 'react-avatar';
import NoteEditor from './NoteEditor';
import { scrollToTop } from '../utils/scrollToTop';
import { BookingNoteResponseType, BookingNoteType } from '../utils/responseTypes';
import InlineConfirmDelete from '../../GeneralComponents/InlineConfirmDelete';
import { useCurrentUser } from '../../hooks/useCurrentUser';
import { GeneralMessages } from '../../config/messages';

const ADD_NEW_BOOKING_NOTE = gql`
  mutation($input: BookingNoteData!) {
    createBookingNote(input: $input) {
      id
      coachId
      note
      createdAt
    }
  }
`;

const GET_BOOKING_NOTES = gql`
  query($id: Int!) {
    getBookingNotes(input: $id) {
      id
      coachId
      note
      createdAt
      partialUser {
        firstName
        lastName
        imageURL
      }
    }
  }
`;

const REMOVE_BOOKING_NOTE = gql`
  mutation($id: Int!) {
    removeBookingNote(input: $id)
  }
`;
interface NotesPropTypes {
  eventId: number;
}
const Notes: React.FC<NotesPropTypes> = ({ eventId }) => {
  const { data: bookingNoteData, error, refetch: refetchNotes } = useQuery<BookingNoteResponseType>(
    GET_BOOKING_NOTES,
    {
      variables: {
        id: eventId
      }
    }
  );
  const [addNewNote, { loading: addNoteLoading }] = useMutation(ADD_NEW_BOOKING_NOTE);
  const [removeBookingNote, { loading: removeNoteLoading }] = useMutation(REMOVE_BOOKING_NOTE);
  const { firstName, lastName, imageURL, id } = useCurrentUser();
  const isProfileImagePlaceholder = imageURL.includes('placeholder');

  const [notes, setNotes] = useState<BookingNoteType[]>([]);
  const [noteToBeDeleted, setNoteToBeDeleted] = useState<number>();

  useEffect(() => {
    refetchNotes();
    setNotes(bookingNoteData?.getBookingNotes || []);
  }, [eventId, bookingNoteData?.getBookingNotes.length]);

  const handleAddNote = async (note: string) => {
    try {
      const { data } = await addNewNote({
        variables: {
          input: {
            bookingId: eventId,
            note
          }
        }
      });
      data.createBookingNote.partialUser = {
        firstName,
        lastName,
        imageURL
      };

      setNotes([data.createBookingNote, ...notes]);
    } catch (error) {}

    if (bookingNoteData?.getBookingNotes.length) {
      scrollToTop('#notes-list > div.ant-spin-nested-loading > div > ul');
    }
  };

  const handleDeleteNote = (id: number) => {
    removeBookingNote({
      variables: {
        id
      }
    })
      .then(data => {
        setNotes(notes.filter(single => single.id !== id));
        setNoteToBeDeleted(-1);
        message.success(GeneralMessages.success);
      })
      .catch(() => {
        message.error(GeneralMessages.error);
      });
  };

  return (
    <div className="notes">
      <Typography.Title level={4}>Notizen</Typography.Title>
      <List
        locale={{
          emptyText: <div style={{ display: 'none' }}></div>
        }}
        className="notes__list"
        id="notes-list"
        itemLayout="horizontal"
        dataSource={notes}
        renderItem={item => {
          const isOwner = item.coachId === id;
          const isProfileImagePlaceholder = item.partialUser.imageURL.includes('placeholder');

          return (
            <li>
              <Comment
                author={`${item.partialUser.firstName} ${item.partialUser.lastName}`}
                avatar={
                  isProfileImagePlaceholder ? (
                    <Avatar
                      name={`${item.partialUser.firstName} ${item.partialUser.lastName}`}
                      size="30"
                      textSizeRatio={2}
                      round={true}
                    />
                  ) : (
                    item.partialUser.imageURL
                  )
                }
                content={
                  <div className="booking-note">
                    <p>{item.note}</p>
                    {isOwner && (
                      <div className="delete-note">
                        <InlineConfirmDelete
                          onConfirm={() => {
                            setNoteToBeDeleted(item.coachId);
                            handleDeleteNote(item.id);
                          }}
                          loading={noteToBeDeleted === item.coachId && removeNoteLoading}
                        />
                      </div>
                    )}
                  </div>
                }
                datetime={
                  <Tooltip
                    title={moment(item.createdAt)
                      .subtract(0, 'days')
                      .format('YYYY-MM-DD HH:mm')}
                  >
                    <span>
                      {moment(item.createdAt)
                        .subtract(0, 'days')
                        .fromNow()}
                    </span>
                  </Tooltip>
                }
              />
            </li>
          );
        }}
      />

      <Comment
        className="notes__add"
        avatar={
          isProfileImagePlaceholder ? (
            <div>
              <Avatar name={`${firstName} ${lastName}`} size="30" textSizeRatio={2} round={true} />
            </div>
          ) : (
            <AntAvatar src={imageURL} />
          )
        }
        content={
          <NoteEditor
            onSubmit={({ note }) => {
              handleAddNote(note);
            }}
            submitting={addNoteLoading}
          />
        }
      />
    </div>
  );
};

export default Notes;

import Form, { FormInstance } from 'antd/lib/form';
import { Button, Input, Modal, Alert } from 'antd';
import React from 'react';
import { editGeneralSettings } from '../../Builder/redux/builderSlice';
import { useAppDispatch } from '../../redux/hooks';

interface FunnelSuggestionModalProps {
  openSuggestionModal: boolean;
  uniqueIdentifierSuggestion: string;
  handleSuggestionModalClose: () => void;
  domain: string;
  form: FormInstance;
  isGeneralSettings?: boolean;
}

export default function FunnelSuggestionModal({
  openSuggestionModal,
  uniqueIdentifierSuggestion,
  handleSuggestionModalClose,
  domain,
  form,
  isGeneralSettings
}: FunnelSuggestionModalProps) {
  const dispatch = useAppDispatch();
  return (
    <Modal
      title="Willst du den Vorschlag übernehmen"
      width={500}
      visible={openSuggestionModal}
      destroyOnClose
      centered
      footer={[
        <Button
          type="primary"
          onClick={() => {
            if (!uniqueIdentifierSuggestion) return;
            if (isGeneralSettings) {
              dispatch(editGeneralSettings({ uniqueIdentifier: uniqueIdentifierSuggestion }));
            }
            form.setFieldsValue({ uniqueIdentifier: uniqueIdentifierSuggestion });
            handleSuggestionModalClose();
          }}
        >
          Ja
        </Button>,
        <Button onClick={handleSuggestionModalClose}>Nein</Button>
      ]}
      closeIcon={<></>}
      className="funnel-suggestion-modal"
    >
      <Form form={form}>
        <Form.Item name="uniqueIdentifier">
          <Input addonBefore={domain} disabled />
        </Form.Item>
        <Form.Item>
          <Input value={uniqueIdentifierSuggestion} addonBefore={domain} disabled />
        </Form.Item>
      </Form>
    </Modal>
  );
}

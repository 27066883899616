import React, { useEffect, useState } from 'react';
import { Element, useEditor, useNode } from '@craftjs/core';

import { CRAFT_ELEMENTS } from '../../../../config/craftElements';
import { getUniqueId, handleBringSlideToFront } from '../../../../helper/craftJs';
import CarouselComponentSettings from '../Components/CarouselComponentSettings';
import CarouselSlide, { defaultCarouselSlideImage } from './CarouselSlide';
import { ImageAvailableBorderRadius } from '../../../../interfaces/ImageSizeInterface';

export interface BuilderCarouselProps {
  children?: any;
}

const BuilderCarousel = ({ children }: BuilderCarouselProps) => {
  const { currentNode, slideNodes, id, props } = useNode(node => ({
    currentNode: node,
    slideNodes: node.data.nodes,
    props: node.data.props
  }));

  const [alreadyBroughtToFront, setAlreadyBroughtToFront] = useState(false);

  const { actions, query } = useEditor();

  useEffect(() => {
    if (!children && currentNode.data.nodes.length === 0) {
      const actionCreator = actions.history.ignore();
      [
        {
          image: defaultCarouselSlideImage,
          label: 'Slide # 1',
          description: '',
          borderRadius: '15px'
        },
        {
          image: defaultCarouselSlideImage,
          label: 'Slide # 2',
          description: '',
          borderRadius: '15px'
        }
      ].map((single, i) => {
        const node = query
          .parseReactElement(
            <Element
              key={i}
              id={getUniqueId()}
              is={CarouselSlide}
              {...single}
              canvas
              canDrag={false}
              activeActionControllerClassNotRequired
              borderRadius={props.borderRadius}
            />
          )
          .toNodeTree();
        actionCreator.addNodeTree(node, currentNode.id);
      });
    }
  }, []);

  useEffect(() => {
    if (slideNodes.length > 0 && !alreadyBroughtToFront) {
      handleBringSlideToFront(id, slideNodes[0]);
      setAlreadyBroughtToFront(true);
    }
  }, [slideNodes]);

  return (
    <div
      id={`Carousel-${currentNode.id}`}
      className="carousel slide"
      data-bs-interval={false}
      data-bs-ride="carousel"
    >
      <div className="carousel-inner">{children}</div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target={`#Carousel-${currentNode.id}`}
        data-bs-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Zurück</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target={`#Carousel-${currentNode.id}`}
        data-bs-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Weiter</span>
      </button>
    </div>
  );
};

export default BuilderCarousel;

BuilderCarousel.craft = {
  name: CRAFT_ELEMENTS.CAROUSEL,
  props: {
    isPresetOn: true,
    borderRadius: ImageAvailableBorderRadius[1].pixelValue
  },
  related: {
    settings: CarouselComponentSettings
  },
  rules: {
    canMoveIn: (selectedNode: any) => {
      return selectedNode[0].data.displayName === CRAFT_ELEMENTS.CAROUSEL_SLIDE;
    },
    canMoveOut: () => false
  }
};

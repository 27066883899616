import moment from 'moment';
import {
  PLAN_INTERVAL_TYPES,
  formatLocalPrice,
  getPlanIntervalById,
  getPlanIntervalLabelById
} from '../../helper/subscriptionHelper';
import { ADD_ON_KEY, AddOn } from './types';

export const addOns: AddOn[] = [
  {
    id: 1,
    key: ADD_ON_KEY.GOOGLE_JOBS,
    title: 'Google Jobs',
    description:
      'Maximiere deine Reichweite - un das ohne Werbebudget. Erreiche potenzielle Kandidaten, die dich auf Social-Media gesehen haben oder aktiv nach neuen Jobs suchen.',
    image: `${process.env.PUBLIC_URL}/marketplace/google.svg`,
    detailedDescription:
      'Mit dem Google Jobs Add-on kannst du deine Google-Jobs-Anzeigen direkt über MEETOVO indexieren lassen - und das ohne Werbebudget. So erreichst du genau die Personen, die beispielsweise nach "Handwerker Job Hamburg" oder "Muster GmbH Job" suchen. Deine Anzeigen werden von Google bei verschiedenen Suchbegriffen angezeigt, um potenzielle Bewerber direkt anzusprechen.',
    carouselLinks: [
      `${process.env.PUBLIC_URL}/marketplace/Google-Jobs-Cover.jpg`,
      `${process.env.PUBLIC_URL}/marketplace/Google-Jobs-Cover2.jpg`
    ],
    price: {
      unit: { [PLAN_INTERVAL_TYPES.YEARLY]: 77, [PLAN_INTERVAL_TYPES.MONTHLY]: 8 },
      addOn: { [PLAN_INTERVAL_TYPES.YEARLY]: 421, [PLAN_INTERVAL_TYPES.MONTHLY]: 39 }
    },
    unit: {
      label: { singular: 'Job', plural: 'Jobs' },
      included: 5,
      step: 5,
      subscriptionInfoMaxKey: 'maxGoogleJobs',
      subscriptionInfoRemainingKey: 'remainingGoogleJobs'
    },
    benefits: [
      'Erste Anlaufstelle für Jobsuchende: Starte mit einem Vorsprung – 75% aller Jobsuchen beginnen direkt bei Google.',
      'Zielgerichtete Sichtbarkeit: Deine Anzeigen sind gezielt auffindbar und erreichen aktiv suchende Kandidaten sowie solche, die explizit nach deinem Unternehmen suchen, weil sie dich auf Social-Media gesehen haben.',
      'Kostenfreier Traffic: Erreiche deine Zielgruppe ohne zusätzliches Werbebudget und maximiere deine Reichweite.',
      'Direkte Schnittstelle zu Google: Indexiere deine Anzeigen über das MEETOVO-Dashboard für eine nahtlose und effiziente Verwaltung. Eine Indexierung kann ausschließlich über direkte Schnittstellen erfolgen.',
      'Blitzschnelle Erstellung: Setze deine Anzeigen in wenigen Minuten auf und erziele sofortige Sichtbarkeit bei potenziellen Bewerbern.'
    ],
    permission: {
      valid: true,
      validUntil: new Date()
    }
  },
  {
    id: 2,
    key: ADD_ON_KEY.SMS,
    title: 'SMS',
    description:
      'Versende SMS-Benachrichtigungen, um automatisch mit Bewerbern in Kontakt zu treten. Steigere die Erreichbarkeit auf bis zu 98% und sorge somit für mehr Einstellungen.',
    image: `${process.env.PUBLIC_URL}/marketplace/message-icon.svg`,
    detailedDescription:
      'Versende alle Nachrichten auch per SMS. Damit steigerst du die Erreichbarkeit der Kandidaten auf bis zu 98% und sorgst somit für mehr Einstellungen. Mit einer festlegbaren Antwort-Nummer, haben die Personen den direkten Draht zum Unternehmen.',
    carouselLinks: [
      `${process.env.PUBLIC_URL}/marketplace/SMS-Cover.jpg`,
      `${process.env.PUBLIC_URL}/marketplace/SMS-Cover2.jpg`
    ],
    price: {
      unit: { [PLAN_INTERVAL_TYPES.YEARLY]: 0.12, [PLAN_INTERVAL_TYPES.MONTHLY]: 0.12 },
      addOn: { [PLAN_INTERVAL_TYPES.YEARLY]: 182, [PLAN_INTERVAL_TYPES.MONTHLY]: 19 }
    },
    unit: {
      label: { singular: 'SMS', plural: 'SMS' },
      included: 100,
      step: 100,
      subscriptionInfoMaxKey: 'maxSmsCredits',
      subscriptionInfoRemainingKey: 'remainingSmsCredits'
    },
    benefits: [
      'Höhere Öffnungsraten: Erreiche Kandidaten zuverlässig mit einer Öffnungsrate von bis zu 98%, im Gegensatz zu durchschnittlich 25% bei E-Mails.',
      'Effektive Automationen nutzen: Profitiere von diversen Automatisierungsmöglichkeiten, die dir bereits von E-Mails bekannt sind, und optimiere deine Kommunikation.',
      'Persönliche Bindung: Sei präsent auf dem Display deiner Kandidaten und baue eine direkte Verbindung auf.',
      'Einfacher Kontakt: Biete Kandidaten die Möglichkeit, unkompliziert auf deine SMS zu antworten, um eine direkte und persönlichere Interaktion zu ermöglichen.'
    ],
    permission: {
      valid: true,
      validUntil: new Date()
    }
  },
  {
    id: 3,
    key: ADD_ON_KEY.CAREER_WIDGET,
    title: 'Karriere-Widget',
    description:
      'Erstelle Karriere-Widgets, die offene Positionen direkt in vorhandene Karriereseiten einbindet und Besucher nahtlos zurück in die Funnels führt.',
    image: `${process.env.PUBLIC_URL}/marketplace/list-career.svg`,
    detailedDescription:
      'Mit dem Karriere-Widget Add-on erhältst du Zugriff auf den "Widget-Builder". Dieses Tool ermöglicht es dir, verschiedene Funnels in einer Ansicht zu bündeln, um sie nahtlos in deine bestehenden Karriereseiten zu integrieren. Somit hast du einen zentralen Ort, um offene Positionen zu bearbeiten. Auf diese Weise können Besucher immer wieder zurück in die Funnels geleitet werden.',
    carouselLinks: [
      `${process.env.PUBLIC_URL}/marketplace/Widget-Cover.jpg`,
      `${process.env.PUBLIC_URL}/marketplace/Widget-Cover2.jpg`
    ],
    price: {
      unit: { [PLAN_INTERVAL_TYPES.YEARLY]: 58, [PLAN_INTERVAL_TYPES.MONTHLY]: 6 },
      addOn: { [PLAN_INTERVAL_TYPES.YEARLY]: 278, [PLAN_INTERVAL_TYPES.MONTHLY]: 29 }
    },
    unit: {
      label: { singular: 'Widget', plural: 'Widgets' },
      included: 5,
      step: 5,
      subscriptionInfoMaxKey: 'maxWidgets',
      subscriptionInfoRemainingKey: 'remainingWidgets'
    },
    benefits: [
      'Keine doppelten Systeme, führe immer zurück in den Funnel: Hole sowohl aktiv suchende als auch passiv interessierte Kandidaten in deine Funnels - ohne zusätzliche Software-Lösungen.',
      'Bessere Kontrolle der offenen Positionen: Kontrolliere alle offenen Positionen von einem zentralen Ort aus. Aktualisiere neue Positionen mit einem Klick, ohne auf Webentwickler angewiesen zu sein.',
      'Einfache Auswertung: Erhalte Einblicke in den organischen Traffic und identifiziere, welche Positionen Nachfrage erzeugen oder optimiert werden müssen.',
      'Agenturleistungen erweitern: Biete Verwaltungsservice an, indem du das Karriere-Widget als Teil deiner Dienstleistungen anbietest und als Retainer verkaufst.'
    ],
    permission: {
      valid: true,
      validUntil: new Date()
    }
  }
];

export const getStatusTagColor = ({ isActive }: { isActive: boolean }) => {
  // using these colors because these are supported by antd Tag, using hex code will not support
  if (isActive) {
    return 'green';
  } else {
    return 'default';
  }
};

export const getStatusTagText = ({ isActive }: { isActive: boolean }) => {
  if (isActive) {
    return 'Aktiviert';
  } else {
    return 'Deaktiviert';
  }
};

export const getAddOnPrice = (id: number, planId: string) => {
  const planIntervalType = getPlanIntervalById(planId);
  const planIntervalLabel = getPlanIntervalLabelById(planId);
  const { price } = addOns.find(({ id: addOnId }) => id === addOnId) || {};

  return {
    planIntervalType,
    planIntervalLabel,
    unit: formatLocalPrice(price?.unit[planIntervalType]),
    addOn: formatLocalPrice(price?.addOn[planIntervalType])
  };
};

export const getAddOnDueTodaySum = (id: number, planId: string, nextBillDate: string) => {
  if (!planId) return '0,00€';

  const diffDays = moment(nextBillDate).diff(moment(), 'days');
  const planIntervalType = getPlanIntervalById(planId);
  const { price } = addOns.find(({ id: addOnId }) => id === addOnId) || {};
  const unitPrice = price?.addOn[planIntervalType] || 0;
  const pricePerDay =
    planIntervalType === PLAN_INTERVAL_TYPES.MONTHLY ? (unitPrice * 12) / 365 : unitPrice / 365;

  return formatLocalPrice(diffDays * pricePerDay);
};

import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';

export const GET_FUNNEL_BOOKING_COUNT = gql`
  query getBookingCount($funnelIds: [Int]!) {
    getBookingCountByFunnelsId(funnelIds: $funnelIds) {
      funnelId
      bookingCount
    }
  }
`;

export default function useGetFunnelBookingCount() {
  return useQuery(GET_FUNNEL_BOOKING_COUNT, {
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        'meetovo-cache-disabled': 'true'
      }
    }
  });
}

export function getFunnelsBookingCount(funnelIds: number[]) {
  return window.apolloClient.query({
    query: GET_FUNNEL_BOOKING_COUNT,
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        'meetovo-cache-disabled': 'true'
      }
    },
    variables: { funnelIds }
  });
}

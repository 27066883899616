export const makeStyles = (primaryClr: string | undefined, secondaryClr: string | undefined) => {
  return `
  .customer-login--wrapper {
    background-color:${primaryClr} !important;
    background-image: linear-gradient(to bottom right, ${primaryClr}, ${secondaryClr}) !important;
  }

  .customer-login--wrapper .ant-btn-primary {
    background-color: ${primaryClr} !important;
    border-color: ${primaryClr} !important;
  }

  .ant-input-prefix {
   color: ${primaryClr} !important;
  }
`;
};

export const replaceTitleAndIcon = ({
  faviconURL,
  title
}: {
  faviconURL?: string;
  title?: string;
}) => {
  if (title) document.title = title;

  if (faviconURL) {
    document.getElementById('favicon-default')?.setAttribute('href', faviconURL);
    document.getElementById('favicon-apple')?.setAttribute('href', faviconURL);
  }
};

import React, { ReactElement } from 'react';
import { FaFacebook, FaComments, FaLifeRing, FaMagic, FaQuestion, FaSave } from 'react-icons/fa';

export interface DropDownType {
  icon: ReactElement;
  title: string;
  link: string;
}

export const dropdowns: DropDownType[] = [
  {
    icon: <FaSave />,
    title: 'Speicherproblem melden',
    link: 'debug-tracking-feedback'
  },
  {
    icon: <FaComments />,
    title: 'Live-Chat',
    link: 'live-chat'
  },
  {
    icon: <FaLifeRing />,
    title: 'Hilfe-Center',
    link: 'https://hilfe.meetovo.de'
  },
  {
    icon: <FaMagic />,
    title: 'Feature-Wunsch & Roadmap',
    link: 'feedback'
  },
  {
    icon: <FaFacebook />,
    title: 'Facebook-Gruppe',
    link: 'https://gruppe.meetovo.de'
  }
];

export const icons: any = {
  question: <FaQuestion />
};

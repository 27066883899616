import React from 'react';
import {
  useBuilderPages,
  useBuilderSelectedPageData,
  useBuilderSelectedPageId
} from '../../../redux/builderSlice';
import { BuilderPageDataType } from '../../../interfaces/builderSliceTypes';
import { Col, Form, Input, Row, Select } from 'antd';
import BuilderSettingsTitle from '../../sharedUI/BuilderSettingsTitle';
import { getAllContainersSectionIds } from '../../../helper/craftJs';
import { thankyouPageType } from '../../../helper/defaultValues';
import { urlFormValidator } from '../../../../helper/url';
import { useEditor, useNode } from '@craftjs/core';

enum ACTION_LOGIC {
  LINKING = 'linking',
  PAGE_SECTION_LINK = 'pageSectionLink'
}

const ButtonActionPicker = () => {
  const { nodeId, props } = useNode(node => ({
    nodeId: node.id,
    props: node.data.props
  }));
  const { actions, query } = useEditor();
  const selectedPageId = useBuilderSelectedPageId();
  const selectedPage = useBuilderSelectedPageData();
  const pages = useBuilderPages();
  const sectionIds = getAllContainersSectionIds(query);
  const [form] = Form.useForm();
  return (
    <>
      <Row className="builder__settings-sidebar__row">
        <Col span={24}>
          <BuilderSettingsTitle title="Aktion" />
          <Select
            data-testid="craftjs-button-component-action-selector-select"
            {...(process.env.NODE_ENV === 'test' && { open: true })}
            className="mt-2 mb-3 w-100"
            value={props.actionLogic}
            onChange={e => {
              e === ACTION_LOGIC.LINKING
                ? actions.setProp(nodeId, (props: any) => (props.isLink = true))
                : actions.setProp(nodeId, (props: any) => (props.isLink = false));
              actions.setProp(nodeId, (state: any) => (state.actionLogic = e));
            }}
          >
            {selectedPage?.type !== thankyouPageType && (
              <Select.Option value="next-page">Nächste Seite</Select.Option>
            )}
            {pages
              .filter((page: BuilderPageDataType) => page.id !== selectedPageId)
              .map((page: BuilderPageDataType) => (
                <Select.Option value={page.id} key={page.id}>
                  {page.name}
                </Select.Option>
              ))}
            <Select.Option value={ACTION_LOGIC.LINKING}>Verlinkung</Select.Option>
            <Select.Option value={ACTION_LOGIC.PAGE_SECTION_LINK}>
              Abschnitt verlinken
            </Select.Option>
          </Select>
        </Col>
        {props.actionLogic === ACTION_LOGIC.PAGE_SECTION_LINK && (
          <>
            <BuilderSettingsTitle title="Abschnittstitel" />
            <Select
              placeholder="Wird im Abschnitt festgelegt"
              className="mt-2 mb-1 w-100"
              value={props.selectedSectionId}
              onChange={e => {
                actions.setProp(nodeId, (state: any) => (state.selectedSectionId = e));
              }}
            >
              {sectionIds?.map(sectionId => (
                <Select.Option value={sectionId}>{sectionId}</Select.Option>
              ))}
            </Select>
            <a target="_blank" href="https://hilfe.meetovo.de/de/" className="link mt-1 d-block">
              Wie verlinke ich Abschnitte?
            </a>
          </>
        )}
        {props.isLink && (
          <Col span={24}>
            <BuilderSettingsTitle title="Link" />
            <Form
              form={form}
              initialValues={{ url: props.url || '' }}
              onFinish={values => {
                actions.setProp(nodeId, (props: any) => (props.url = values.url));
              }}
              autoComplete="off"
            >
              <Form.Item
                name="url"
                rules={[
                  {
                    required: true,
                    message: 'URL ist erforderlich'
                  },
                  urlFormValidator
                ]}
              >
                <Input
                  data-testid="craftjs-button-component-action-selector-input"
                  className="mt-2"
                  placeholder="https://deine-seite.de/impressum"
                  onChange={form.submit}
                />
              </Form.Item>
            </Form>
          </Col>
        )}
      </Row>
    </>
  );
};

export default ButtonActionPicker;

import React, { useRef } from 'react';
import { Space } from 'antd';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import FontPicker from 'font-picker-react';
import { useDispatch } from 'react-redux';
import { getFontLink } from '../../helper/sharedFunctions';
import { FunnelThemeType } from '../../interfaces/builderSliceTypes';
import { useCurrentlyEditingBuilderTheme } from '../../hooks/redux/getter/useCurrentlyEditingBuilderTheme';
import { AgencyOSThemeType } from '../../AgencyOSBuilder/interfaces/agencyOSBuilderSliceTypes';
import { setCurrentlyEditingThemeData } from '../../hooks/redux/setCurrentlyEditingThemeData';
import { saveCompleteBuilderData } from '../../hooks/redux/saveCompleteBuilderDataThunk';


const BuilderSettingsFont = () => {
  const dispatch = useDispatch();
  const theme = useCurrentlyEditingBuilderTheme();
  const setCurrentlyEditingTheme = setCurrentlyEditingThemeData();
  const saveCompleteBuilderDataThunk = saveCompleteBuilderData();
  const requestSent = useRef(false);

  const handleSetFont = (font: any) => {
    // workaround, this method is called twise from the font picker hence backend is called twise
    // to avoid that we call the backend only if loading is false
    // then when the backend is being called second call will not be execute.

    if (requestSent.current) {
      requestSent.current = false;
    } else {
      applyColorTheme({
        ...theme,
        fontFamily: font.family,
        fontLink: getFontLink(font.family)
      });

      requestSent.current = true;
    }
  };

  const applyColorTheme = async (colorTheme: FunnelThemeType | AgencyOSThemeType) => {
    //@ts-ignore
    dispatch(setCurrentlyEditingTheme(colorTheme));
    await dispatch(saveCompleteBuilderDataThunk());
  };

  return (
    <Space direction="vertical">
      <Text strong>Schriftarten</Text>
      <div className="font-settings">
        <FontPicker
          apiKey="AIzaSyDVSWeonwMo4hDSM_Payyb1Sn_G7_oCJko"
          activeFontFamily={theme.fontFamily}
          categories={['sans-serif', 'serif']}
          variants={['regular', 'italic', '300', '700']}
          limit={100}
          onChange={handleSetFont}
        />
      </div>
      <div style={{ fontFamily: theme.fontFamily }}>
        <Title className="apply-font" level={4}>
          Quisque nec dolor a lacus urna amet
        </Title>
        <Text className="apply-font">
          Lorem ipsum dolor, sit amet consectetur adipisicing elit. Ab explicabo repellendus
          obcaecati eius odio deserunt facilis impedit omnis, earum tenetur numquam, harum ratione
          quo. Vero dolores vitae exercitationem ipsum recusandae.
        </Text>
      </div>
    </Space>
  );
};

export default BuilderSettingsFont;
